<div class="page">
    <div class="page-header-custom-bg">
        <h1 class="page-title text-white">Lista de Revendas</h1>
        <a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>

    </div>


    <div class="page-padding">
        <div class="row">
            <div class="col-12">
                <div class="page-title mb-2">Projetos</div>
            </div>
            <div class="col-6">
                <div class="project-card">
                    <h3 class="text-primary"><strong>{{amountStandartzation.amountPending}}</strong></h3>
                    <span class="mt-2">Pendente</span>
                </div>
            </div>
            <div class="col-6">
                <div class="project-card">
                    <h3 class="text-primary"><strong>{{amountStandartzation.amountfinished}}</strong></h3>
                    <span class="mt-2">Finalizado</span>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="page-title mb-2">Lista de Revendas</div>
            </div>
            <!--<div class="col-8">
                <div class="input-material input-management">
                    <input id="resselermeasure" class="form-control" type="text" [(ngModel)]="this.filter.keyword"
                           required/>
                    <label for="resselermeasure">Buscar</label>
                </div>
            </div>
            <div class="col-4">
                <a href="javascript:void(0)"  (click)="pageChanged(1)" class="btn btn-outline-primary btn-next-outline">
                    <span>Buscar</span>
                </a>
            </div>-->
            <div class="col-12 col-lg-5">
                <div class="input-material input-management">
                    <input id="resselermeasure" [(ngModel)]="this.filter.keyword" placeholder="CNPJ, Razão Social ou Endereço" class="form-control" type="text"
                           required/>
                    <label for="resselermeasure" class="disabled">Buscar</label>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2">
                <div class="input-material input-management">
                    <select name="resselerMeasureSelect" id="resselerMeasureSelect" class="form-control" [(ngModel)]="filter.status">
                        <option [value]="''">Selecione</option>
                        <option value="pending">Pendente</option>
                        <option value="review">Revisar</option>
                        <option value="received">Em Análise</option>
                        <option value="approved">Aprovado</option>
                    </select>
                    <label for="resselerMeasureSelect" class="disabled">Status</label>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2">
                <div class="input-material input-management">
                    <select name="resselerUf" id="resselerUf" class="form-control" [(ngModel)]="filter.uf"
                            (ngModelChange)="getCities()">
                        <option [value]="''">Selecione</option>
                        <ng-container *ngFor="let x of arrUf; let i = index;">
                            <option [value]="x.value">{{x.value}}</option>
                        </ng-container>
                    </select>
                    <label for="resselerUf" class="disabled">Estado</label>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <div class="input-material input-management">
                    <select name="resselerCity" id="resselerCity" class="form-control" [(ngModel)]="filter.city"
                            [disabled]="arrCity.length === 0">
                        <option [value]="''">Selecione</option>
                        <ng-container *ngFor="let x of arrCity; let i = index;">
                            <option [value]="x.value">{{x.value}}</option>
                        </ng-container>
                    </select>
                    <label for="resselerCity" class="disabled">Cidade</label>
                </div>
            </div>
            <!--<div class="col-12 col-md-6 col-lg-2">
                <div class="input-material input-management">
                    <select name="resselerTeam" id="resselerTeam" class="form-control" [(ngModel)]="filter.team" [disabled]="true">
                        <option [value]="''">Selecione</option>
                        <ng-container *ngFor="let x of searchTeamUserSupplier; let i = index;">
                            <option [value]="x.name">{{x.name}}</option>
                        </ng-container>
                    </select>
                    <label for="resselerTeam" class="disabled">Equipe</label>
                </div>
            </div>-->
            <div class="col-12 text-right">
                <div class="d-flex justify-content-end">
                    <a href="javascript:void(0)" (click)="pageChanged(1)" class="btn btn-primary btn-next-outline px-5">
                        <span>Buscar</span>
                    </a>
                    <a href="javascript:void(0)" (click)="clearFilter()"
                       *ngIf="!isNullOrUndefined(filter.keyword) || !isNullOrUndefined(filter.status)
                                || !isNullOrUndefined(filter.uf) || !isNullOrUndefined(filter.city)"
                       class="btn btn-danger btn-next-outline px-4 ml-3">
                        <span>
                            <svg-icon svgClass="icon-edit" [svgStyle]="{ 'width.px': 20, 'fill': '#ffffff' }" src="assets/svg/trash.svg"></svg-icon>
                        </span>
                    </a>
                </div>
            </div>
        </div>

    </div>

    <div class="page-padding">
        <div class="row">
            <div *ngIf="isNullOrUndefined(userSupplier)" class="col-12 d-flex align-items-center justify-content-center" style="min-height: 100px">
                <span>Nenhum registro encontrado</span>
            </div>
            <div class="col-md-4" *ngFor="let x of userSupplierPage.resales |
                        paginate: {itemsPerPage: userSupplierPage.PageSize,
                        currentPage: userSupplierPage.currentPage,
                        totalItems: userSupplierPage.totalCount }">
                <div class=" reseller-card" [routerLink]="['/resellers/detail/' + x.id]">
                    <div class="reseller-card-top justify-content-between flex-wrap">
                        <span>{{x.companyName}}</span>
                        <span *ngIf="x.fleetStatus === 'received'">Tel: <strong>{{isNullOrUndefined(x.phone) ? 'Não informado' : x.phone}}</strong></span>
                    </div>

                <!--    <div class="reseller-card-top">
                        <span class="w-50">{{x.companyName}}</span>
                        <span *ngIf="x.status == 'received'" >Tel{{x.phone}}</span>
                        <div class="energycup" *ngIf="x.status !== 'received'"><span class="mr-3">Copa Energia  </span><svg-icon class="svgtop" src="assets/svg/paint.svg"  ></svg-icon></div>

                    </div>-->

                    <div class="reseller-card-middle">
                        <svg-icon class="mr-3" src="assets/svg/local.svg"></svg-icon>
                        <span>
                            <strong>{{x.uf}} - {{x.city}}</strong><br>
                            <strong>{{x.cep}} | {{x.district}}</strong><br>
                            <strong>{{x.address}}</strong>
                        </span>
                    </div>
                    <!--<div *ngIf="x.status == 'received'"  class=" reseller-card-bottom">
                        <strong>Adicionar Fotos</strong>
                    </div>-->
                    <div *ngIf="x.frontStatus === 'received'" class=" reseller-card-bottom adesion">
                        <strong>Em análise</strong>
                        <svg-icon class="mr-3 border-svg" src="assets/svg/adesion.svg"></svg-icon>
                    </div>
                    <div *ngIf="x.frontStatus == 'approved' || x.frontStatus == 'finished'" class=" reseller-card-bottom approved">
                        <strong>Aprovada</strong>
                        <svg-icon class="mr-3 border-svg" src="assets/svg/approved.svg"></svg-icon>
                    </div>
                    <div *ngIf="x.frontStatus === 'review'" class=" reseller-card-bottom review">
                        <strong>Revisar</strong>
                        <svg-icon class="mr-3 border-svg" src="assets/svg/review.svg"></svg-icon>
                    </div>
                    <div *ngIf="x.frontStatus === 'pending'" class=" reseller-card-bottom pending">
                        <strong>Pendente</strong>
                        <svg-icon class="mr-3 border-svg" src="assets/svg/pending.svg"></svg-icon>
                    </div>
                </div>
            </div>

        </div>

        <div class="text-right">
            <pagination-controls [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel"
                                 [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                                 [screenReaderPageLabel]="labels.screenReaderPageLabel"
                                 [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                                 (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

    </div>
</div>

