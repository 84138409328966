import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    SearchStandartGQL,
    SearchStandartInput,
    SearchStandartQuery, SearchStandartQueryVariables,
    Standart, StandartDashboard, StandartDashboardGQL,
    StandartPage, UserAdmin
} from "../../../generated/graphql";
import moment from "moment";
import {QueryRef} from "apollo-angular";
import {NgxSpinnerService} from "ngx-spinner";
import {UserService} from "../../service/user.service";
import {environment} from "../../../environments/environment";

declare var $: any;

@Component({
    selector: 'app-participants',
    templateUrl: './participants.component.html',
    styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent extends BaseComponent implements OnInit {
    user: UserAdmin;
    standArtPage: StandartPage = new StandartPage();
    searchStandArtInput: SearchStandartInput = new SearchStandartInput();
    searchStandArtQuery: QueryRef<SearchStandartQuery, SearchStandartQueryVariables>;
    page = 1;

    modelDash: StandartDashboard = new StandartDashboard();

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService,
                public searchStandArtGQL: SearchStandartGQL,
                public standartDashboardGQL: StandartDashboardGQL) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as UserAdmin;
                if (this.user.profile !== 'admin') {
                    this.searchStandArtInput.gve = this.user.gve;
                    this.searchStandArtInput.gme = this.user.gme;
                    this.initDate();
                    this.initList();
                } else {
                    this.initDate();
                    this.initList();
                }
            }, error: err => super.onError(err)
        });
        this.standartDashboardGQL.watch().valueChanges.subscribe(({data}) => {
            this.modelDash = data.standartDashboard;
        }, error => super.onError(error));
    }

    initDate() {
        this.searchStandArtInput.start = moment().startOf('day').subtract(30, 'days')
        this.searchStandArtInput.end = moment().endOf('day');
        this.setDatePicker('picker-period', (start, end, label) => {
            this.searchStandArtInput.start = start.utcOffset(0, true).format();
            this.searchStandArtInput.end = end.utcOffset(0, true).format();
        });
    }

    initList() {
        this.loading.show();
        this.searchStandArtInput.page = 1;
        this.handlerFilterModel();
        this.searchStandArtQuery = this.searchStandArtGQL.watch({input: this.searchStandArtInput});
        this.searchStandArtQuery.valueChanges.subscribe(({data}) => {
            this.standArtPage = data.searchStandart as StandartPage;
            this.loading.hide();
        }, error => super.onError(error));
    }

    actionSearch() {
        this.loading.show();
        super.setFilterLocal('filterParticipants', this.searchStandArtInput);
        this.searchStandArtQuery.refetch({input: this.searchStandArtInput}).finally(() => {
            this.loading.hide();
        });
    }

    handlerFilterModel() {
        const localFilter = super.getFilterLocal('filterParticipants');
        const modelFormat = JSON.stringify(this.searchStandArtInput);
        if (!this.isNullOrUndefined(localFilter) && (modelFormat !== localFilter)) {
            const el = JSON.parse(localFilter);
            $('.picker-period').daterangepicker({
                startDate: moment(el.start),
                endDate: moment(el.end),
                locale: super.getObjectLocaleDatePicker()
            });
            this.searchStandArtInput = JSON.parse(localFilter);
        }
    }

    pageChanged(event) {
        this.searchStandArtInput.page = event;
        this.actionSearch();
    }

    getExcel() {
        this.userService.getExcelStandart(this.searchStandArtInput).subscribe({
            next: data => {
                this.downloadHandlerFile(data, 'Programa');
            }, error: err => super.onError(err)
        });
    }

    actionClearSearch() {
        this.searchStandArtInput = new SearchStandartInput();
        this.searchStandArtInput.start = moment().startOf('day').subtract(30, 'days')
        this.searchStandArtInput.end = moment().endOf('day');
        this.searchStandArtInput.page = 1;
        localStorage.removeItem('filterParticipants');
        this.initDate();
        this.actionSearch();
    }

    openPdf(createdById: string) {
        const token = localStorage.getItem('token') as string;
        let url = `${environment.base_url}/users/pdf?token=${token}&id=${createdById}`;
        window.open(encodeURI(url));
    }
}
