import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
    Banner,
    RemoveDocumentGQL,
    ResaleUserSupplier,
    SearchDocumentsGQL,
    StandartItemImage,
    StandartMessage,
    StandartMessagesGQL, StandartMessagesQuery, StandartMessagesQueryVariables,
    StandartStandardizationByIdUserSupplierGQL, UpdateResaleProjectGQL,
    User,
    UserDocument, UserSupplier,
    UserSupplierStandartDetails
} from "../../../../generated/graphql";
import {IAlbum, Lightbox} from "ngx-lightbox";
import {UserService} from "../../../service/user.service";
import {QueryRef} from "apollo-angular";

@Component({
    selector: 'app-management-detail',
    templateUrl: './management-detail.component.html',
    styleUrls: ['./management-detail.component.scss']
})
export class ManagementDetailComponent extends BaseComponent implements OnInit, OnDestroy {
    standartDetails: UserSupplierStandartDetails = new UserSupplierStandartDetails();
    paramId: string;
    documents: UserDocument[] = [];
    documentsAuthorization: UserDocument[] = [];

    standartMessageQuery: QueryRef<StandartMessagesQuery, StandartMessagesQueryVariables>;
    standartMessage: StandartMessage[] = [];
    fileTerms: any[];
    modelUser: UserSupplier = new UserSupplier();

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public translate: TranslateService,
                public activatedRoute: ActivatedRoute,
                private standartMessagesGQL: StandartMessagesGQL,
                private searchDocumentsGQL: SearchDocumentsGQL,
                public removeDocumentGQL: RemoveDocumentGQL,
                public updateResaleProjectGQL: UpdateResaleProjectGQL,
                public idUserSupplierGQL: StandartStandardizationByIdUserSupplierGQL,
                private lightbox: Lightbox,
    ) {
        super(router, loading, translate)
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    if (data.profile !== 'admin' || data.type !== 'front-wall') {
                        this.router.navigate(['/login']).then();
                    }
                    this.modelUser = data as UserSupplier;
                    if (data.profile !== 'admin') {
                        this.router.navigate(['/']).then();
                    }
                }
            }
        });
        this.activatedRoute.params.subscribe(url => {
            if (!this.isNullOrUndefined(url?.id)) {
                this.paramId = url.id;
                this.getStandart()
            }
        });
    }

    ngOnDestroy(): void {
        super.destroyModal('modalResaleProject');
    }

    private getStandart() {
        this.idUserSupplierGQL.watch({id: this.paramId}).valueChanges.subscribe(({data}) => {
            this.standartDetails = data.standartStandardizationByIdUserSupplier as UserSupplierStandartDetails;
            this.documents = this.standartDetails.document?.filter(x => x.type === 'authorization');
            this.standartDetails.document = this.standartDetails.document?.filter(x => x.type === 'project');
            this.getMessages();
        }, error => super.onError(error));
    }

    getMessages() {
        this.standartMessageQuery = this.standartMessagesGQL.watch({id: this.paramId});
        this.standartMessageQuery.valueChanges.subscribe(({data}) => {
            this.standartMessage = data.standartMessages as StandartMessage[];
        });
    }

    open(p: StandartItemImage): void {
        const iAlbum: IAlbum = {
            caption: p.name,
            src: p.url,
            thumb: p.url,
        };
        this.lightbox.open([iAlbum], 0, {
            wrapAround: false,
            showImageNumberLabel: false,
            disableScrolling: true,
            showZoom: false,
            showRotate: false
        });
    }


    uploadDocument(event: any) {
        this.fileTerms = event.target.files;
        if (this.documents.length >= 1) {
            this.documents.forEach((e) => {
                this.removeDocumentGQL.mutate({id: e.id}).subscribe();
            });
        }
        this.userService.uploadFile(
            this.fileTerms[0],
            'user-supplier-document',
            `${this.standartDetails.resaleId}/project/${this.modelUser.id}`)
            .subscribe(({data}) => {
                this.fileTerms = [];
                this.closeModal('modalResaleProject');
                this.showMessage('Sucesso!', 'Projeto enviado com sucesso!', 'success');
                this.getStandart();
            }, error => super.onError(error)
        );
    }


    onChangeStatusProject(x: ResaleUserSupplier) {
        this.updateResaleProjectGQL.mutate({input: x.resaleId}).subscribe(
            ({data}) => {
                this.showMessage('Sucesso!', `Alteração no projeto realizada com sucesso!`, 'success');
                // this.pageChanged(1);
            }, (err) => super.onError(err)
        );
    }
}
