<div class="container measures">
    <div class="row">
        <!-- Card de Perguntas-->
        <div class="col-xl-7 col-xxl-8">
            <div class="scroll_primary pt-3 pr-3">
                <div class="config_top">
                    <div class="d-flex">
                        <svg-icon routerLink="/participants" class="is-cursor mr-3"
                                  src="assets/svg/back.svg"></svg-icon>
                        <h2 class="page-header__title large">
                            <strong>Auditoria</strong>
                        </h2>
                    </div>
                    <!--Barra de Recebido-->
                    <div class="received text-capitalize bg-{{standArt.status}}">{{getTranslate(standArt.status)}}</div>
                </div>
                <div class="row justify-content-center mt-4">
                    <div class="col-sm-12 col-lg-9">
                        <div class="register-page_status status-{{standArt.status}}">
                            <!--<span (click)="actionGetStandart('adhesion')">
                            </span>
                            <span (click)="actionGetStandart('standardization')">
                            </span>-->
                            <!--<div class="d-flex justify-content-between align-items-center position-relative"
                                 *ngIf="standArt.type === 'adhesion'">
                                <div class="status_item" (click)="actionGetStandart('adhesion')">
                                    <p class="status_text text-status-{{standArt.status}}">Adesão</p>
                                    <svg-icon class="icon_status"
                                              src="assets/svg/{{standArt.status}}-status.svg"></svg-icon>
                                    <p class="status_text text-status-{{standArt.status}} bottom">{{getTranslate(standArt.status)}}</p>
                                </div>
                                <div class="status_item" (click)="actionGetStandart('standardization')">
                                    <ng-container *ngIf="standArt.status !== 'approved'">
                                        <p class="status_text status_text_secondary">Padronização</p>
                                        <svg-icon class="icon_status2" src="assets/svg/editadesion.svg"></svg-icon>
                                        <p class="status_text bottom">
                                            &nbsp;
                                        </p>
                                    </ng-container>
                                    <ng-container *ngIf="standArt.status === 'approved'">
                                        <p class="status_text text-tertiary">Padronização</p>
                                        <div style="width: 31px;height: 31px;border-radius: 100%;border: solid 3px #00BB7F;background-color: white">
                                            &nbsp;
                                        </div>
                                        <p class="status_text bottom">
                                            &nbsp;
                                        </p>
                                    </ng-container>
                                </div>
                                <div class="status_bar">&nbsp;</div>
                            </div>-->
                            <div class="d-flex justify-content-between align-items-center position-relative"
                                 *ngIf="standArt?.type === 'adhesion'">
                                <div class="status_item" (click)="actionGetStandart('adhesion')">
                                    <p class="status_text text-status-{{standArt?.status}}">Adesão</p>
                                    <svg-icon class="icon_status"
                                              src="assets/svg/{{standArt?.status}}-status.svg"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.status}} bottom">{{getTranslate(standArt?.status)}}</p>
                                </div>
                                <div class="status_item" (click)="actionGetStandart('standardization')">
                                    <p class="status_text text-status-{{standArt?.sons?.status}}">Padronização</p>
                                    <svg-icon class="icon_status2"
                                              src="assets/svg/{{isNullOrUndefined(standArt?.sons?.status) ? 'standardization.svg' : standArt?.sons?.status + '-status.svg'}}"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.sons?.status}} bottom">
                                        {{getTranslate(standArt?.sons?.status)}}
                                    </p>
                                </div>
                                <div class="status_bar status_standardization">&nbsp;</div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center position-relative"
                                 *ngIf="standArt.type === 'standardization'">
                                <div class="status_item" (click)="actionGetStandart('adhesion')">
                                    <p class="status_text text-status-{{standArt?.father?.status}}">Adesão</p>
                                    <svg-icon class="icon_status"
                                              src="assets/svg/{{standArt?.father?.status}}-status.svg"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.father?.status}} bottom">{{getTranslate(standArt?.father?.status)}}</p>
                                </div>
                                <div class="status_item" (click)="actionGetStandart('standardization')">
                                    <p class="status_text text-status-{{standArt?.status}}">Padronização</p>
                                    <svg-icon class="icon_status2"
                                              src="assets/svg/{{standArt?.status}}-status.svg"></svg-icon>
                                    <p class="status_text text-capitalize text-status-{{standArt?.status}} bottom">
                                        {{getTranslate(standArt?.status)}}
                                    </p>
                                </div>
                                <div class="status_bar status_standardization">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="config_top mt-3">
                    <div class="d-flex">
                        <h2 class="page-header__title large">
                            <strong class="text-capitalize">{{getTranslate(standArt?.type)}}</strong>
                        </h2>
                    </div>
                </div>
                <div class="top_card">
                    <div class="d-flex flex-column flex-grow-1">
                        <p>CNPJ</p>
                        <b>{{standArt?.createdBy?.cnpj}}</b>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                        <p>Data de Recebimento</p>
                        <b>{{standArt?.createdAt |date:'dd/MM/yyyy'}}</b>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                        <p>Solicitante</p>
                        <b>{{standArt.createdBy?.name}}</b>
                    </div>
                </div>

                <!--                <ng-container *ngIf="standArt.type === 'adhesion'">-->

                <!--                    <div class="config_top mt-5 mb-2"-->
                <!--                         *ngIf="standArt.createdBy?.type === 'glp' || (standArt.createdBy?.type === 'gas' && standArt.createdBy?.hasCage)">-->
                <!--                        <div class="d-flex">-->
                <!--                            <svg-icon class="svg-title" src="assets/svg/ruler.svg"></svg-icon>-->
                <!--                            <h2 class="page-header__title large">-->
                <!--                                <strong>Medidas</strong>-->
                <!--                            </h2>-->
                <!--                        </div>-->
                <!--                    </div>-->

                <!--                    &lt;!&ndash;Perguntas&ndash;&gt;-->
                <!--                    <ng-container *ngFor="let w of standArt.walls let i = index">-->
                <!--                        <div class="question">-->
                <!--                            <div class="d-flex align-items-center">-->
                <!--                                <div class="number">-->
                <!--                                    {{i + 1}}-->
                <!--                                </div>-->
                <!--                                <h2 class="page-header__title">-->
                <!--                                    <span class="page-title ml-3" *ngIf="w.type === 'railing'">-->
                <!--                                        Metragem estimada do muro externo com GRADIL para pintura.-->
                <!--                                    </span>-->
                <!--                                    <span class="page-title ml-3" *ngIf="w.type === 'wall'">-->
                <!--                                        Metragem do muro externo com ALVENARIA para pintura.-->
                <!--                                    </span>-->
                <!--                                </h2>-->
                <!--                            </div>-->
                <!--                            <div class="p-md-3">-->
                <!--                                <h3>Frente</h3>-->
                <!--                                <div class="row">-->
                <!--                                    <div class="col-sm-4 answer">-->
                <!--                                        <b>Altura</b>-->
                <!--                                        <div class="total">-->
                <!--                                            <b class="text-white"> &nbsp; {{w?.frontHeight}}m²</b>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                    <div class="col-sm-4 answer">-->
                <!--                                        <b>Comprimento</b>-->
                <!--                                        <div class="total">-->
                <!--                                            <b class="text-white"> &nbsp; {{w?.frontLenght}}m²</b>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                    <div class="col-sm-4 answer">-->
                <!--                                        <b>Total</b>-->
                <!--                                        <div class="total">-->
                <!--                                            <b class="text-white"> &nbsp; {{w?.frontTotal}}m²</b>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <!--                                <div class="my-4">-->
                <!--                                    <hr />-->
                <!--                                </div>-->
                <!--                                <h3>Lateral</h3>-->
                <!--                                <div class="row">-->
                <!--                                    <div class="col-sm-4 answer">-->
                <!--                                        <b>Altura</b>-->
                <!--                                        <div class="total">-->
                <!--                                            <b class="text-white"> &nbsp; {{w?.sideHeight}}m²</b>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                    <div class="col-sm-4 answer">-->
                <!--                                        <b>Comprimento</b>-->
                <!--                                        <div class="total">-->
                <!--                                            <b class="text-white"> &nbsp; {{w?.sideLenght}}m²</b>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                    <div class="col-sm-4 answer">-->
                <!--                                        <b>Total</b>-->
                <!--                                        <div class="total">-->
                <!--                                            <b class="text-white"> &nbsp; {{w?.sideTotal}}m²</b>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <!--                                <div class="divider"></div>-->
                <!--                                <div class="icon" [ngClass]="{'disabled' : (w.currentStatus ==='approved' || w.currentStatus ==='reproved' || w.currentStatus ==='review')}">-->
                <!--                                    <svg-icon (click)="handlerStatusWalls(i,'approved', w)"-->
                <!--                                              [ngClass]="{'approved':w.status ==='approved'}"-->
                <!--                                              src="assets/svg/accept.svg"></svg-icon>-->
                <!--                                    <svg-icon (click)="handlerStatusWalls(i,'review', w)"-->
                <!--                                              [ngClass]="{'review':w.status ==='review'}"-->
                <!--                                              src="assets/svg/review.svg"></svg-icon>-->
                <!--                                    &lt;!&ndash;<svg-icon (click)="handlerStatusWalls(i,'reproved', w)"-->
                <!--                                              [ngClass]="{'reproved':w.status ==='reproved'}"-->
                <!--                                              src="assets/svg/cancel.svg"></svg-icon>&ndash;&gt;-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="pt-3 p-md-3"-->
                <!--                             *ngIf="(w.status == 'review'|| w.status == 'reproved') && standArt.status === 'received'">-->
                <!--                            <div class="input-material input_adesion">-->
                <!--                                <input id="{{w.id}}" class="form-control js-validate" type="text"-->
                <!--                                       required/>-->
                <!--                                <label for="{{w.id}}">-->
                <!--                                    <span>Justificativa</span>-->
                <!--                                </label>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </ng-container>-->

                <!--                    <ng-container *ngFor="let c of standArt.complement let i = index">-->
                <!--                        <div class="question">-->
                <!--                            <div class="d-flex align-items-center">-->
                <!--                                <div class="number">-->
                <!--                                    {{i + 1}}-->
                <!--                                </div>-->
                <!--                                <h2 class="page-header__title">-->
                <!--                                    <span class="page-title ml-3" *ngIf="c.type == 'storage'">Armazenamento</span>-->
                <!--                                    <span class="page-title ml-3" *ngIf="c.type == 'front-wall'">Fachada</span>-->
                <!--                                    <span class="page-title ml-3" *ngIf="c.type == 'cage'">Gaiola</span>-->
                <!--                                    <span class="page-title ml-3" *ngIf="c.type == 'side-wall'">Muro Lateral</span>-->
                <!--                                    <span class="page-title ml-3"-->
                <!--                                          *ngIf="c.type == 'gas-cylinder-pass'">Passagem Botijão</span>-->
                <!--                                    <span class="page-title ml-3" *ngIf="c.type == 'anp-sign'">Placa ANP</span>-->
                <!--                                    <span class="page-title ml-3" *ngIf="c.type == 'front-sign'">Tamanho da TESTEIRA</span>-->
                <!--                                    <span class="page-title ml-3" *ngIf="c.type == 'gate'">Metragem dos Portões</span>-->
                <!--                                </h2>-->
                <!--                            </div>-->
                <!--                            <div class="p-md-3">-->
                <!--                                <div class="row align-items-end">-->
                <!--                                    &lt;!&ndash;<div *ngIf="c.type !=='cage' && c.type !=='front-sign'" class="col-sm-6 answer">-->
                <!--                                        <div class="total">-->
                <!--                                            Total de <b class="text-white"> &nbsp; {{c?.total}}-->
                <!--                                            m²</b>-->
                <!--                                        </div>-->
                <!--                                    </div>&ndash;&gt;-->
                <!--                                    <div *ngIf="c.type ==='gate'" class="col-xxl answer">-->
                <!--                                        <div class="row">-->
                <!--                                            <div class="col-sm-4">-->
                <!--                                                <b>Altura</b>-->
                <!--                                                <div class="total">-->
                <!--                                                    <b class="text-white">{{c?.height}}m</b>-->
                <!--                                                </div>-->
                <!--                                            </div>-->
                <!--                                            <div class="col-sm-4">-->
                <!--                                                <b>Comprimento</b>-->
                <!--                                                <div class="total">-->
                <!--                                                    <b class="text-white">{{c?.lenght}}m</b>-->
                <!--                                                </div>-->
                <!--                                            </div>-->
                <!--                                            <div class="col-sm-4">-->
                <!--                                                <b>Total</b>-->
                <!--                                                <div class="total">-->
                <!--                                                    <b class="text-white">{{c?.total}}m²</b>-->
                <!--                                                </div>-->
                <!--                                            </div>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                    <div *ngIf="c.type ==='front-sign'" class="col-xxl answer">-->
                <!--                                        <div class="row">-->
                <!--                                            <div class="col-sm-4">-->
                <!--                                                <b>Altura</b>-->
                <!--                                                <div class="total">-->
                <!--                                                    <b class="text-white">{{c?.height}}m</b>-->
                <!--                                                </div>-->
                <!--                                            </div>-->
                <!--                                            <div class="col-sm-4">-->
                <!--                                                <b>Comprimento</b>-->
                <!--                                                <div class="total">-->
                <!--                                                    <b class="text-white">{{c?.lenght}}m</b>-->
                <!--                                                </div>-->
                <!--                                            </div>-->
                <!--                                            <div class="col-sm-4">-->
                <!--                                                <b>Total</b>-->
                <!--                                                <div class="total">-->
                <!--                                                    <b class="text-white">{{c?.total}}m</b>-->
                <!--                                                </div>-->
                <!--                                            </div>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                    <div *ngIf="c.type ==='cage'" class="col-xxl answer">-->
                <!--                                        <p>Possui quantas GAIOLAS</p>-->
                <!--                                        <div class="row">-->
                <!--                                            <div class="col-sm-8">-->
                <!--                                                <div class="total">-->
                <!--                                                    <b class="text-white">{{c?.qty}}</b>-->
                <!--                                                </div>-->
                <!--                                            </div>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                    <div class="col-xxl-auto justify-content-end">-->
                <!--                                        <div class="icon answer" [ngClass]="{'disabled' : (c.currentStatus ==='approved' || c.currentStatus ==='reproved' || c.currentStatus ==='review')}">-->
                <!--                                            <svg-icon (click)="handlerStatusComplement(i,'approved', c)"-->
                <!--                                                      [ngClass]="{'approved':c.status ==='approved'}"-->
                <!--                                                      src="assets/svg/accept.svg"></svg-icon>-->
                <!--                                            <svg-icon (click)="handlerStatusComplement(i,'review', c)"-->
                <!--                                                      [ngClass]="{'review':c.status ==='review'}"-->
                <!--                                                      src="assets/svg/review.svg"></svg-icon>-->
                <!--                                            &lt;!&ndash;<svg-icon (click)="handlerStatusComplement(i,'reproved', c)"-->
                <!--                                                      [ngClass]="{'reproved':c.status ==='reproved'}"-->
                <!--                                                      src="assets/svg/cancel.svg"></svg-icon>&ndash;&gt;-->
                <!--                                        </div>-->
                <!--                                    </div>-->

                <!--                                </div>-->
                <!--                                <div class="divider">&nbsp;</div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="pt-3 p-md-3"-->
                <!--                             *ngIf="(c.status == 'review'|| c.status == 'reproved') && standArt.status === 'received'">-->
                <!--                            <div class="input-material input_adesion">-->
                <!--                                <input id="{{c.id}}" class="form-control js-validate" type="text"-->
                <!--                                       required/>-->
                <!--                                <label for="{{c.id}}">-->
                <!--                                    <span>Justificativa</span>-->
                <!--                                </label>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </ng-container>-->

                <!--                </ng-container>-->

                <div class="config_top mt-5 mb-2">
                    <div class="d-flex">
                        <svg-icon class="svg-title" src="assets/svg/user-blue.svg"></svg-icon>
                        <h2 class="page-header__title large">
                            <strong>Responsáveis</strong>
                        </h2>
                    </div>
                    <button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#userModal">
                        <svg-icon class="svg-white" src="assets/svg/edit.svg"></svg-icon>
                    </button>
                </div>
                <div class="row mt-4">
                    <div class="col-2" *ngFor="let x of[].constructor(4)">
                        <div class="px-4 mb-2">
                            <img src="assets/images/photo.png" style="aspect-ratio: 1;;object-fit: cover"
                                 class="rounded-circle border" alt="images">
                        </div>
                        <h6 class="text-center">Marcelo Caio Moreira</h6>
                    </div>
                </div>
                <div class="config_top mt-5 mb-2">
                    <div class="d-flex">
                        <svg-icon class="svg-title" src="assets/svg/folder.svg"></svg-icon>
                        <h2 class="page-header__title large">
                            <strong>Projeto</strong>
                        </h2>
                    </div>
                </div>

                <div class="my-3">
                    <label for="fileProject" class="btn btn-light">
                        <input id="fileProject" type="file" class="d-none" (change)="uploadProject($event)"/>
                        <svg-icon src="assets/svg/downloads.svg"></svg-icon>
                        <span>Upload</span>
                    </label>
                    <span class="ml-2" *ngIf="!isNullOrUndefined(this.file)">{{this.file[0].name}}</span>
                </div>

                <div class="input-material">
                    <textarea class="form-control js-textarea-contact mt-4" id="description"
                              style="resize: none" rows="4" required></textarea>
                    <label for="description" class="bg-transparent">Obsevações</label>
                </div>
                <a class="btn btn-block btn-primary text-white">Enviar</a>

                <div class="config_top mt-5 mb-2">
                    <div class="d-flex">
                        <svg-icon class="svg-title" src="assets/svg/photo.svg"></svg-icon>
                        <h2 class="page-header__title large">
                            <strong>Fotos</strong>
                        </h2>
                    </div>
                </div>

                <!--Fotos-->
                <ng-container *ngFor="let f of standArt?.item let i = index">
                    <div class="question_photo">
                        <div class="row align-items-center">
                            <div class="w-100 d-flex justify-content-between">
                                <div class="d-flex justify-content-start align-items-center">
                                    <!--<div class="number">
                                        {{i + 1}}
                                    </div>-->
                                    <h2 class="page-header__title">
                                        <span class="page-title ml-3">{{getItemNameByType(f?.type)}}</span>
                                    </h2>
                                </div>
                                <span class="d-flex justify-content-end align-items-center"
                                      *ngIf="f?.type !== 'front-wall'">
                                    <div class="custom-control custom-switch">
                                        <input (change)="actionChangeIsGreen($event, f)"
                                               [checked]="f?.isGreen"
                                               [disabled]="standArt.status !== 'received'"
                                               type="checkbox" class="custom-control-input"
                                               id="check_{{i}}" name="check_{{i}}">
                                        <label class="custom-control-label custom-control-label-small"
                                               for="check_{{i}}">Padronização em Verde</label>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div class="p-3">
                            <div class="photo_row row">
                                <ng-container *ngFor="let p of f.images; let pI = index;">
                                    <div class="photo mb-xl-5 col-xl-6 col-xxl-3">
                                        <img *ngIf="!isNullOrUndefined(p.url)" class="photo_img"
                                             (click)="open(p)"
                                             src="{{p.url}}"
                                             alt="">
                                        <div *ngIf="isNullOrUndefined(p.url)" class="photo_img">
                                            <svg id="add_photo_alternate_black_24dp" xmlns="http://www.w3.org/2000/svg"
                                                 width="46" height="46" viewBox="0 0 46 46">
                                                <path id="Caminho_134" data-name="Caminho 134" d="M0,0H46V46H0Z"
                                                      fill="none"/>
                                                <path id="Caminho_135" data-name="Caminho 135"
                                                      d="M37.538,7.75H33.667V3.878A1.881,1.881,0,0,0,31.788,2h-.058a1.886,1.886,0,0,0-1.9,1.878V7.75H25.981a1.91,1.91,0,0,0-1.9,1.878v.058a1.89,1.89,0,0,0,1.9,1.9h3.852v3.853a1.873,1.873,0,0,0,1.9,1.878h.058a1.881,1.881,0,0,0,1.878-1.878V11.583h3.872A1.881,1.881,0,0,0,39.417,9.7V9.628A1.881,1.881,0,0,0,37.538,7.75Zm-9.622,7.686V13.5H25.981a3.8,3.8,0,0,1-3.814-3.872,3.682,3.682,0,0,1,.518-1.878H6.833A3.845,3.845,0,0,0,3,11.583v23a3.845,3.845,0,0,0,3.833,3.833h23a3.845,3.845,0,0,0,3.833-3.833V18.713a3.926,3.926,0,0,1-1.955.537A3.833,3.833,0,0,1,27.917,15.436ZM27.84,34.583H8.75a.958.958,0,0,1-.767-1.533l3.8-5.041a.969.969,0,0,1,1.572.038l3.067,4.619,5-6.67a.954.954,0,0,1,1.514-.019l5.654,7.053A.955.955,0,0,1,27.84,34.583Z"
                                                      transform="translate(2.75 1.833)" fill="#00bb7f"/>
                                            </svg>
                                        </div>
                                        <p class="photo_text">
                                            <span>{{p.name}}</span>
                                        </p>
                                        <ng-container *ngIf="!isNullOrUndefined(p.url)">
                                            <div class="divider"></div>
                                            <div class="icon_2"
                                                 [ngClass]="{'disabled' : (p.currentStatus ==='approved' || p.currentStatus ==='reproved' || p.currentStatus ==='review')}">
                                                <svg-icon (click)="handlerStatusItem(i,pI,'approved', f)"
                                                          [ngClass]="{'approved':p.status ==='approved'}"
                                                          src="assets/svg/accept.svg"></svg-icon>
                                                <svg-icon (click)="handlerStatusItem(i,pI,'review', f)"
                                                          [ngClass]="{'review':p.status ==='review'}"
                                                          src="assets/svg/review.svg"></svg-icon>
                                                <!--<svg-icon (click)="handlerStatusItem(i,pI,'reproved', f)"
                                                          [ngClass]="{'reproved':p.status ==='reproved'}"
                                                          src="assets/svg/cancel.svg"></svg-icon>-->
                                            </div>
                                        </ng-container>

                                    </div>

                                </ng-container>
                                <div class="pt-3 p-md-3 w-100"
                                     *ngIf="validateJustifyPhoto(f.images) && standArt.status === 'received'">
                                    <div class="input-material input_adesion">
                                        <input id="{{f.id}}" class="form-control js-validate" type="text"
                                               required/>
                                        <label for="{{f.id}}">
                                            <span>Justificativa</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </ng-container>
            </div>
        </div>
        <!--Comentarios-->
        <div class="col-xl-5 col-xxl-4">
            <div class="d-flex flex-column justify-content-between pt-3 h-100">
                <div class="comments_card pt-3">
                    <h2 class="page-header__title large">
                        <strong>Comentários</strong>
                    </h2>
                    <div class="scroll_secondary pr-2" style=" overflow-y: auto;">
                        <div class="comment" *ngFor="let m of standartMessages">
                            <div class="top">
                                <div class="d-flex align-items-center">
                                    <svg-icon class="comment_icon" src="assets/svg/ruler.svg"></svg-icon>
                                    <h4 class="comments_card_title">
                                        <strong>{{m.title}}</strong>
                                    </h4>
                                </div>
                                <p>{{m.createdAt|date:'dd/MM/yyyy'}}</p>
                            </div>
                            <div class="comment_title">{{getItemNameByType(m.type)}}</div>
                            <p>{{m.message}}</p>
                            <div *ngIf="m.reply&&isNullOrUndefined" class="gray_box">
                                Resposta:
                                <b>{{m.reply}}</b>
                            </div>

                        </div>
                    </div>

                    <div class="d-flex flex-grow-1 justify-content-center align-items-center"
                         *ngIf="isNullOrUndefined(standartMessages)">
                        <div>Nenhum comentário registrada</div>
                    </div>
                </div>
                <a *ngIf="standArt.status === 'received'" (click)="onValidate()"
                   class="btn mt-3 btn-block btn-primary btn-lg text-white">Salvar Avaliação</a>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="userModal" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal_header d-flex justify-content-between align-items-center p-3">
                <h3 class="header-title"><b>Lista de responsáveis</b></h3>
                <a href="javascript:void('');"
                   class="btn" type="button" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="px-4 py-3">
                <div class="row align-items-center mt-4">
                    <div class="col">
                        <div class="input-material mb-0">
                            <input id="name" class="form-control" type="text" required/>
                            <label for="name">
                                <svg-icon src="assets/svg/search.svg"></svg-icon>
                                <span>Busca</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-auto">
                        <p class="mb-0"><strong>Show selected (2)</strong></p>
                    </div>
                    <div class="col-auto">
                        <a>Select all</a>
                    </div>
                </div>
            </div>
            <div class="modal-body p-3 flex-column">
                <div class="border-bottom py-3" *ngFor="let x of [].constructor(20)">
                    <label class="row align-items-center">
                        <div class="col-2">
                            <img src="assets/images/photo.png" style="aspect-ratio: 1;;object-fit: cover"
                                 class="rounded-circle border" alt="images">
                        </div>
                        <div class="col">
                            <h5>Marcelo Caio Moreira</h5>
                        </div>
                        <div class="col-auto">
                            <div class="form-check">
                                <input class="form-check-input position-static" type="checkbox" id="blankCheckbox"
                                       value="option1" aria-label="...">
                            </div>
                        </div>
                    </label>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary text-white">Salvar</button>
            </div>
        </div>
    </div>
</div>


