<div class="page">
    <div class="page-header-custom-bg pb-5">
        <div class="d-flex align-items-center mb-3">
            <svg-icon [routerLink]="['/resellersProject']" class="svgBack is-cursor" src="assets/svg/back.svg"></svg-icon>
            <h1 class="page-title text-white m-0 p-0">Revenda</h1>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <span class="badge mr-2 mb-2 text-capitalize"
                      [ngClass]="{'badge-info' : standartDetails.projectStatus === 'pending',
                                          'badge-warning' : standartDetails.projectStatus === 'received',
                                          'badge-danger' : standartDetails.projectStatus === 'review',
                                          'badge-success' : standartDetails.projectStatus === 'approved'}">{{standartDetails.projectStatus | translate}}</span>
                <strong class="text-white">{{standartDetails.companyName}}</strong><br/>
                <span class="text-white">Tel: <strong>{{isNullOrUndefined(standartDetails.phone) ? 'Não informado' : standartDetails.phone}}</strong></span>
                <div>
                    CNPJ: <strong>{{standartDetails.cnpj}}</strong><br/>
                    <strong></strong><br>
                    <strong></strong>
                </div>
            </div>

            <div class="col-12 d-md-none mb-2">
                <hr/>
            </div>

            <div class="col-12 col-md-6 text-md-right">
                    <svg-icon class="mr-3" src="assets/svg/local.svg"></svg-icon>
                    <span>
                        <strong class="text-primary">{{standartDetails.uf}} - {{standartDetails.city}} | </strong>
                        <strong class="text-primary">{{standartDetails.cep}} | {{standartDetails.district}}</strong><br>
                        <strong class="text-primary">{{standartDetails.address}}</strong>
                    </span>
                <!--<h4 class="m-0"><strong>Projeto</strong></h4>
                <div *ngIf="isNullOrUndefined(standartDetails.document) || standartDetails!.document!.length == 0">
                    Não enviado
                </div>
                <div *ngIf="!isNullOrUndefined(standartDetails.document) && standartDetails.document.length > 0">
                    <a href="{{standartDetails.document[standartDetails.document.length - 1]?.url}}" target="_blank"
                       class="text-white d-inline-block mt-2">
                        <svg-icon class="mr-3 svg-white" src="assets/svg/file-double.svg"></svg-icon>
                        <br>
                        <span>Visualizar</span>
                    </a><br/>
                    Projeto Status: <strong
                        class="text-capitalize">{{standartDetails.document[standartDetails.document.length - 1]?.status | translate}}</strong><br/>
                    <span *ngIf="!isNullOrUndefined(standartDetails.document) && !isNullOrUndefined(standartDetails.document[standartDetails.document.length - 1]?.approvedAt)">Data Aprovação: <strong>{{standartDetails.document[standartDetails.document.length - 1]?.approvedAt | date: 'dd/MM/yyyy'}}</strong><br/></span>
                </div>-->
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <div class="page-padding">
                <div *ngIf="step == 1">

                    <div *ngFor="let x of standartDetails.item" class="mb-4">
                        <ng-container *ngIf="x.type === 'projects'">
                            <h1 class="page-title mb-2">{{getItemNameByType(x.type)}}</h1>
                            <p *ngIf="x.type == 'projects'">Adicione até 4 fotos do projeto seguindo as
                                referências fornecidas.</p>
                            <div class="row">
                                <div class="col-sm-6 col-md-3 col-lg-6 col-xxl-3" *ngFor="let img of x.images">
                                    <div class="position-relative">
                                        <div *ngIf="isNullOrUndefined(img.url)" class="addphoto is-cursor"
                                             (click)="actionUploadImage(img)">
                                            <svg-icon src="assets/svg/addimage.svg"></svg-icon>
                                            <strong class="text-primary">{{img.name}}</strong>
                                        </div>
                                        <img (click)="actionUploadImage(img)" *ngIf="!isNullOrUndefined(img.url)"
                                             class="addphoto is-cursor" src="{{img.url}}" alt="{{img.name}}">
                                        <span *ngIf="!isNullOrUndefined(img.url)" style="position:relative; top: -8px;">
                                            <svg-icon src="assets/svg/close.svg" class="svg-danger"
                                                      *ngIf="img.status === 'review'"></svg-icon>
                                            <svg-icon src="assets/svg/check.svg" class="svg-primary"
                                                      *ngIf="img.status === 'approved'"></svg-icon>
                                        </span>
                                        <div class="photo-item-thumb_name mb-0">
                                            <p class="mb-0 photo-item-thumb_name-text">{{img?.name}}</p>
                                            <span class="d-flex align-items-center" *ngIf="isNullOrUndefined(img?.url)">
                                                <a class="photo-item-thumb_edit"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.projectStatus})"
                                                   (click)="actionUploadImage(img)">
                                                    <svg-icon src="assets/svg/plus.svg" class="svg-white"></svg-icon>
                                                </a>
                                            </span>
                                            <span class="d-flex align-items-center" *ngIf="!isNullOrUndefined(img?.url)">
                                                <a class="photo-item-thumb_edit"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.projectStatus})"
                                                   (click)="actionUploadImage(img)">
                                                    <svg-icon src="assets/svg/edit.svg"></svg-icon>
                                                </a>
                                                <a class="photo-item-thumb_edit ml-3"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.projectStatus})"
                                                   (click)="actionRemovePhoto(img)">
                                                    <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <!--<a href="javascript:void('');" (click)="step = 2" class="btn btn-primary btn-next text-white">Próximo</a>-->
                </div>
                <div *ngIf="step == 2" class="row">

                    <div class="col-12">
                        <h1 class="page-title mb-2">Totem</h1>

                        <div class="row">
                            <div class="col-6">
                                <div>Revenda possui totem/projeto especial?</div>
                                <div class="d-flex">
                                    <div class="custom-control custom-radio ml-2">
                                        <input type="radio" id="customRadio10" name="customRadio" class="custom-control-input"
                                               [value]="true"
                                               [(ngModel)]="standartDetails.isHasTotem"
                                               (ngModelChange)="setTotem(true)" />
                                        <label class="custom-control-label" for="customRadio10">Sim</label>
                                    </div>
                                    <div class="custom-control custom-radio mx-3">
                                        <input type="radio" id="customRadio22" name="customRadio" class="custom-control-input"
                                               [value]="false"
                                               [(ngModel)]="standartDetails.isHasTotem"
                                               (ngModelChange)="setTotem(false)"/>
                                        <label class="custom-control-label" for="customRadio22">Não</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" *ngIf="standartDetails.isHasTotem">
                                <div>Totem/projeto especial é luminoso?</div>
                                <div class="d-flex">
                                    <div class="custom-control custom-radio ml-2">
                                        <input type="radio" id="customRadio100" name="customRadioLuminous" class="custom-control-input"
                                               [value]="true"
                                               [(ngModel)]="standartDetails.isTotemLuminous"
                                               (ngModelChange)="setTotemLuminous(true)" />
                                        <label class="custom-control-label" for="customRadio100">Sim</label>
                                    </div>
                                    <div class="custom-control custom-radio mx-3">
                                        <input type="radio" id="customRadio220" name="customRadioLuminous" class="custom-control-input"
                                               [value]="false"
                                               [(ngModel)]="standartDetails.isTotemLuminous"
                                               (ngModelChange)="setTotemLuminous(false)"/>
                                        <label class="custom-control-label" for="customRadio220">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 my-4">
                        <hr />
                    </div>

                    <div class="col-12">
                        <h1 class="page-title mb-2">Medidas</h1>
                        <p>Insira a quantidade em metros quadrados pintados no local.</p>
                    </div>

                    <div class="col-md-12">
                        <div class="row justify-content-center">
                            <div class="col-6">
                                <div class="input-material input-resseler">
                                    <input [(ngModel)]="standartDetails.amountMetersPaint"
                                           [disabled]="standartDetails.projectStatus === 'received' || standartDetails.projectStatus === 'approved' || notPaint"
                                           id="resselermeasure" class="form-control" type="number"/>
                                    <label for="resselermeasure"
                                           [ngClass]="{'disabled' : (standartDetails.projectStatus === 'received' || standartDetails.projectStatus === 'approved'|| notPaint)}"><span>m2</span></label>
                                </div>

                                <!--<div class="custom-control custom-switch">
                                    <input [(ngModel)]="notPaint"
                                           [disabled]="standartDetails.projectStatus === 'received' || standartDetails.projectStatus === 'approved'"
                                           (ngModelChange)="standartDetails.amountMetersPaint = 0"
                                           type="checkbox" class="custom-control-input"
                                           id="check_b" name="check_b">
                                    <label class="custom-control-label" for="check_b">Não houve pintura do
                                        Local.</label>
                                </div>-->

                                <div class="custom-control custom-switch">
                                    <input (change)="handlerSwapPlate()" [(ngModel)]="this.standartDetails.isSwapPlate"
                                           [disabled]="standartDetails.projectStatus === 'received' || standartDetails.projectStatus === 'approved'"
                                           type="checkbox" class="custom-control-input"
                                           id="replacement" name="check_b">
                                    <label class="custom-control-label" for="replacement">Troca de placa e/ou
                                        testeira</label>
                                </div>


                            </div>
                        </div>

                    </div>

                    <!--<div class="col-4" >
                        <a href="javascript:void(0)" class="btn btn-outline-primary btn-next-outline" (click)="step = 1">
                            <svg-icon src="assets/svg/return.svg"></svg-icon>
                        </a>
                    </div>
                    <div class="col-8">
                        <a href="javascript:void(0)" class="btn btn-primary btn-next text-white" (click)="step = 3">
                            Próximo
                        </a>
                    </div>-->
                </div>
                <div *ngIf="step == 3" class="row">
                    <div class="col-12">
                        <h1 class="page-title mb-2">Termo de autorização</h1>
                        <p>Para finalizar, adicione uma foto do termo de padronização assinado pelo responsável da
                            revenda.</p>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label class="autorization-card">
                            <img src="assets/images/addimage.png" alt="" *ngIf="isNullOrUndefined(documents[0]?.url)">
                            <a href="{{documents[0]?.url}}" target="_blank"
                               *ngIf="!isNullOrUndefined(documents[0]?.url)">
                                <img src="assets/images/addimage.png" alt="">
                            </a>
                            <span class="is-cursor"
                                  *ngIf="standartDetails.projectStatus !== 'received' && standartDetails.projectStatus !== 'approved'">
                                <span class="text-primary">Adicionar Autorização</span>
                                <input type="file" id="myfile" class="d-none" (change)="uploadTerms($event)">
                            </span>
                        </label>
                    </div>

                    <!--<div class="col-4" (click)="step = 2">
                        <div class="btn-outline-primary btn-next-outline">
                            <svg-icon src="assets/svg/return.svg"></svg-icon>
                        </div>
                    </div>
                    <div class="col-8" *ngIf="standartDetails.status !== 'received' && standartDetails.status !== 'approved'">
                        <div class="btn-primary btn-next" (click)="finish()">
                            Finalizar
                        </div>
                    </div>-->
                </div>

                <div class="comment my-5">
                    <div class="comment_header">
                        <svg-icon src="assets/svg/logo2.svg"></svg-icon>
                        <p class="comment_title">Comentários do Auditor</p>
                        <svg-icon class="comment_close d-none d-lg-block" src="assets/svg/arrow-down.svg"
                                  (click)="onCloseComment()"></svg-icon>
                    </div>
                    <ng-container *ngIf="isNullOrUndefined(this.handlerViewMessage(step))">
                        <div class="d-flex px-2 comment_default" style="gap: 23px">
                            <svg-icon src="assets/svg/comment.svg"></svg-icon>
                            <p class="comment_description">
                                Olá, aqui o auditor informará se
                                há ajustes para serem realizados em sua <span>adesão ou
                    padronização</span>, fique atento.
                            </p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isNullOrUndefined(standartMessage)">
                        <div class="comment_scroll___">
                            <ng-container *ngFor="let m of this.handlerViewMessage(step), let i = index">
                                <ng-container>
                                    <div *ngIf="i == 0" class="d-flex justify-content-between align-items-center mb-2"
                                         style="gap: 20px">
                                        <div class="d-flex align-items-center" style="gap: 12px">
                                            <svg-icon [svgStyle]="{'width': '27px'}"
                                                      src="assets/svg/logo2.svg"></svg-icon>
                                            <span class="comment_name">{{m.createdBy.name}}</span>
                                        </div>
                                    </div>
                                    <div class="comment_card mb-3">
                                        <div style="padding: 1rem 1.5rem 0.5rem;">
                                            <p class="comment_card_date mb-0 text-right">{{m.createdAt |date:'dd/MM/yyyy'}}</p>
                                            <p class="comment_card_subtitle mb-2">{{m.title}}</p>
                                            <p class="comment_card_description">{{m.message}}</p>
                                            <ng-container *ngIf="isNullOrUndefined(m.reply)">
                                                <div style="background-color: #d6d6d6;width: 100%;height: 1px;margin: 0.7rem 0;">
                                                    &nbsp;
                                                </div>
                                                <p (click)="handlerResponse(m)"
                                                   class="comment_card_response">Responder</p>
                                            </ng-container>

                                        </div>

                                        <div *ngIf="!isNullOrUndefined(m.reply)"
                                             class="bg-primary rounded-bottom px-4 py-2">
                                            <p class="response_title">Resposta</p>
                                            <p class="response_text">{{m.reply}}</p>
                                        </div>
                                    </div>
                                </ng-container>

                            </ng-container>
                        </div>
                    </ng-container>
                </div>

                <div class="row" *ngIf="false">
                    <div class="col-12"
                         *ngIf="standartDetails.projectStatus !== 'received' && standartDetails.projectStatus !== 'approved'">
                        <div class="btn-primary btn-next is-cursor" (click)="this.finish()">
                            Finalizar
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalFinish" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="row">

                <div class="col-12">
                    <div class="modal-resseler">
                        <div class="modal-resseler-top">
                            <a class="modal_close ml-auto is-cursor" aria-label="Close"
                               (click)="closeModal('modalFinish')">
                                <svg-icon [svgStyle]="{'fill':'#006F4D'}"
                                          src="assets/svg/closex.svg"></svg-icon>
                            </a>

                            <h3 class="text-white">
                                <strong>
                                    PADRONIZAÇÃO
                                    REALIZADA
                                    COM SUCESSO
                                </strong>

                            </h3>

                        </div>
                        <div class="modal-resseler-bottom">
                        <span class="text-white">
                               Todas as informaçõe foram enviadas para analise e conferencia do auditor Copa Energia.

                        </span>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<app-crop-image [cropId]="'modalCropResseler'"
                [cropAspectRatio]="4 / 4"
                [cropResizeToWidth]="1024"
                [cropResizeToHeight]="1024"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>


<div class="modal-custom modal fade" id="modalComments" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">

                <div class="col-12 col-lg-4">
                    <div class="modal_col-primary">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'white'}" class="svg-close mt-2"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logo.png" style="width: 279px;" alt="logo">
                        <h2 class="modal_title text-white mb-3">Mural Liquigás</h2>
                    </div>
                </div>

                <div class="col-12 col-lg-8">
                    <div class="p-4 pr-lg-5">
                        <div style="gap: 10px"
                             class="d-flex justify-content-between align-items-lg-center">
                            <div class="d-flex align-items-center" style="gap: 20px">
                                <svg-icon src="assets/svg/logo2.svg"></svg-icon>
                                <p class="modal_comment_title">{{modalMessage.createdBy?.name}}</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-between" style="gap: 20px">
                                <p class="modal_comment_date mb-0">{{modalMessage.createdAt|date:'dd / MM / yyyy'}}
                                    às {{modalMessage.createdAt|date:'HH:mm'}}</p>
                                <a data-dismiss="modal" class="modal_close ml-auto d-none d-lg-flex" aria-label="Close">
                                    <svg-icon class="svg-close" src="assets/svg/close-circle.svg"></svg-icon>
                                </a>
                            </div>
                        </div>
                        <p class="modal_comment_quest">{{getItemNameByType(modalMessage.type)}}</p>
                        <p class="modal_comment_descriptions">{{modalMessage.message}}</p>
                        <div class="input-material"
                             style="padding-top: 26px;">
                            <textarea id="field1" [(ngModel)]="textResponse" style="resize: none;"></textarea>
                            <label for="field1">Escreva aqui sua mensagem</label>
                        </div>
                        <div>
                            <a (click)="actionResponse()" class="btn btn-primary btn-block mb-4"
                               href="javascript:void(0);">Enviar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
