<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <div class="d-flex align-items-center">
                <svg-icon [routerLink]="['/management']" class="svgBack is-cursor"
                          src="assets/svg/backgreen.svg"></svg-icon>
                <h1 class="page-title">Revenda</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <span class="badge mr-2 mb-2 text-capitalize"
                      [ngClass]="{'badge-info' : standartDetails.frontStatus === 'pending',
                                          'badge-warning' : standartDetails.frontStatus === 'received',
                                          'badge-danger' : standartDetails.frontStatus === 'review',
                                          'badge-success' : standartDetails.frontStatus === 'approved'}">{{standartDetails.frontStatus | translate}}</span>
                <strong class="text-primary">{{standartDetails.companyName}}</strong><br/>
                <span class="text-primary">Tel: <strong>{{isNullOrUndefined(standartDetails.phone) ? 'Não informado' : standartDetails.phone}}</strong></span>
                <div class="text-primary">
                    CNPJ: <strong>{{standartDetails.cnpj}}</strong><br/>
                    <strong></strong><br>
                    <strong></strong>
                </div>
                <div class="d-flex">
                    <svg-icon class="mr-3" src="assets/svg/local.svg"></svg-icon>
                    <span>
                        <strong class="text-primary">{{standartDetails.uf}} - {{standartDetails.city}} | </strong>
                        <strong class="text-primary">{{standartDetails.cep}} | {{standartDetails.district}}</strong><br>
                        <strong class="text-primary">{{standartDetails.address}}</strong>
                    </span>
                </div>
            </div>

            <div class="col-12 d-md-none my-2">
                &nbsp;
                <hr/>
            </div>

            <div class="col-12 col-md-6 text-md-right text-primary">
                <h4 class="m-0"><strong>Projeto</strong></h4>
                <div>
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input"
                               (change)="onChangeStatusProject(standartDetails)" [checked]="standartDetails.isProject === true"
                               id="check_project" name="check_project" />
                        <!--<input type="checkbox" class="custom-control-input"
                               id="check_project" name="check_project" />-->
                        <label class="custom-control-label" for="check_project">&nbsp;</label>
                    </div>
                </div>

                <!--<div *ngIf="standartDetails.document?.length === 0">
                    Não enviado
                </div>

                <div *ngIf="standartDetails.document?.length > 0">
                    <a href="{{standartDetails.document[standartDetails.document?.length - 1]?.url}}" target="_blank"
                       class="text-primary d-inline-block mt-2">
                        <svg-icon class="mr-3 svg-primary" src="assets/svg/file-double.svg"></svg-icon>
                        <br>
                        <span>Visualizar</span>
                    </a><br/>
                    Projeto Status: <strong
                        class="text-capitalize">{{standartDetails.document[standartDetails.document?.length - 1]?.status | translate}}</strong><br/>
                    <span *ngIf="!isNullOrUndefined(standartDetails.document[standartDetails.document?.length - 1]?.approvedAt)">Data Aprovação: <strong>{{standartDetails.document[standartDetails.document?.length - 1]?.approvedAt | date: 'dd/MM/yyyy'}}</strong><br/></span>
                </div>-->
                <!--<ng-container *ngIf="isNullOrUndefined(standartDetails.standardizationJustificationVisit) && standartDetails.frontStatus !== 'approved'">
                    <a href="javascript:void('');"
                       class="my-2 text-primary d-flex align-items-center justify-content-end"
                       (click)="showModal('modalResaleProject')"
                       *ngIf="isNullOrUndefined(standartDetails.document) || (standartDetails.document[standartDetails.document?.length - 1]?.status === 'review')">
                        <svg-icon src="assets/svg/plus.svg"></svg-icon>
                        <span class="d-inline-block ml-2 pt-1">Adicionar</span>
                    </a>
                </ng-container>-->
            </div>

            <div class="col-12 d-md-none my-2">
                &nbsp;
                <hr/>
            </div>

            <!--
            <div class="col-12">
                <div class="management-card">
                    <div class="management-card-top">
                        <span class="mr-2">
                            <span class="badge mr-2 mb-2 text-capitalize"
                                  [ngClass]="{'badge-info' : standartDetails.status === 'pending',
                                          'badge-warning' : standartDetails.status === 'received',
                                          'badge-danger' : standartDetails.status === 'review',
                                          'badge-success' : standartDetails.status === 'approved'}">{{standartDetails.status | translate}}</span>
                            <strong>{{standartDetails.companyName}}</strong>
                        </span>
                        <span class="phoneSize">{{standartDetails.phone}}</span>
                    </div>
                    <div class="management-card-middle">
                        <svg-icon class="mr-3" src="assets/svg/local.svg"></svg-icon>
                        <span>
                            <span>{{standartDetails.uf}} - {{standartDetails.city}}</span> |
                            <span>{{standartDetails.cep}} | {{standartDetails.district}}</span><br>
                            <span>{{standartDetails.address}}</span>
                        </span>
                    </div>
                </div>
            </div>-->

            <div class="col-12">
                <div class="responsible-card mt-4">
                    <div class="responsible-card-top">
                        <span class="text-primary"><strong>Responsável</strong></span>
                    </div>
                    <div class="responsible-card-middle">
                        <ng-container *ngIf="isNullOrUndefined(standartDetails.collaborator)">
                            Nenhuma equipe
                        </ng-container>
                        <ng-container *ngIf="!isNullOrUndefined(standartDetails.collaborator)">
                            <div class="d-flex align-items-center">
                                <svg-icon class="mr-2" src="assets/svg/user.svg"></svg-icon>
                                <span><strong>{{standartDetails.collaborator}}</strong></span>
                            </div>
                            <div *ngIf="!isNullOrUndefined(standartDetails.dateRealization)"
                                 class="d-flex flex-column align-items-end">
                                <span class="smalltxt">Data da realização</span>
                                <span
                                        class="smalltxt"><strong>{{standartDetails.dateRealization|date:'dd/MM/yyyy'}}</strong></span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-12">
                <div class="card-end flex-column align-items-stretch">
                    <div class="d-flex justify-content-between flex-grow-1">
                        <strong class="text-primary pt-2"> Revenda recusou a visita</strong>
                        <div class="card-end-middle">
                            <h3 class="text-primary">
                                <strong>{{isNullOrUndefined(standartDetails.standardizationJustificationVisit) ? 'Não' : 'Sim'}}</strong>
                            </h3>
                        </div>
                    </div>
                    <p class="flex-grow-1"
                       *ngIf="!isNullOrUndefined(standartDetails.standardizationJustificationVisit)">
                        <strong>Justificativa:</strong><br/>
                        {{standartDetails.standardizationJustificationVisit}}</p>
                </div>
            </div>

            <div class="col-12 col-md-12" *ngIf="isNullOrUndefined(standartDetails.standardizationJustificationVisit)">
                <div class="card-end flex-column align-items-stretch">
                    <div class="d-flex justify-content-between flex-grow-1">
                        <strong class="text-primary pt-2"> Revenda recusou a padronização</strong>
                        <div class="card-end-middle">
                            <h3 class="text-primary">
                                <strong>{{isNullOrUndefined(standartDetails.standardizationJustification) ? 'Não' : 'Sim'}}</strong>
                            </h3>
                        </div>
                    </div>
                    <p class="flex-grow-1" *ngIf="!isNullOrUndefined(standartDetails.standardizationJustification)">
                        <strong>Justificativa:</strong><br/>
                        {{standartDetails.standardizationJustification}}</p>
                </div>
            </div>

            <div class="col-12 mt-4">
                <div class="page-title mb-4">Detalhamento da padronização</div>

                <div class="mb-4">
                    <h1 class="page-title mb-2">Adesão</h1>
                    <p>Fotos enviadas antes de ser realizada a padronização:</p>
                    <div class="photos">
                        <img [src]="!isNullOrUndefined(img.url)?img.url:'assets/images/img.png'"
                             *ngFor="let img of standartDetails.adhesionImages" alt="{{img.name}}" (click)="open(img)">
                    </div>
                </div>

                <hr/>

                <ng-container class="mb-3" *ngFor="let x of standartDetails.item">
                    <ng-container *ngIf="x.type === 'front-wall' || x.type === 'projects'">
                        <strong class="text-primary">{{getItemNameByType(x.type)}}</strong>
                        <div class="photos">
                            <img [src]="!isNullOrUndefined(img.url)?img.url:'assets/images/img.png'"
                                 *ngFor="let img of x.images" alt="{{img.name}}" (click)="open(img)">
                        </div>
                    </ng-container>
                </ng-container>

                <div class="comment my-5">
                    <div class="comment_header">
                        <svg-icon src="assets/svg/logo2.svg"></svg-icon>
                        <p class="comment_title">Comentários</p>
                        <svg-icon class="comment_close d-none d-lg-block" src="assets/svg/arrow-down.svg"></svg-icon>
                    </div>
                    <ng-container *ngIf="isNullOrUndefined(this.standartMessage)">
                        <div class="d-flex px-2 comment_default" style="gap: 23px">
                            <svg-icon src="assets/svg/comment.svg"></svg-icon>
                            <p class="comment_description">
                                Olá, aqui o auditor informará se
                                há ajustes para serem realizados em sua <span>adesão ou
                                padronização</span>, fique atento.
                            </p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isNullOrUndefined(standartMessage)">
                        <div class="comment_scroll">
                            <ng-container *ngFor="let m of standartMessage, let i = index">
                                <ng-container>
                                    <div class="comment_card mb-3">
                                        <div style="padding: 1rem 1.5rem 0.5rem;">
                                            <p class="comment_card_date mb-0 text-right">{{m.createdAt |date:'dd/MM/yyyy'}}</p>
                                            <p class="comment_card_subtitle mb-2">{{m.title}}</p>
                                            <p class="comment_card_description">{{m.message}}</p>
                                            <ng-container *ngIf="isNullOrUndefined(m.reply)">
                                                <div style="background-color: #d6d6d6;width: 100%;height: 1px;margin: 0.7rem 0;">
                                                </div>
                                            </ng-container>

                                        </div>
                                        <div *ngIf="!isNullOrUndefined(m.reply)"
                                             class="bg-primary rounded-bottom px-4 py-2">
                                            <p class="response_title">Resposta</p>
                                            <p class="response_text">{{m.reply}}</p>
                                        </div>
                                    </div>
                                </ng-container>

                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>

            <ng-container
                    *ngIf="isNullOrUndefined(standartDetails.standardizationJustification) && isNullOrUndefined(standartDetails.standardizationJustificationVisit)">
                <div class="col-12 col-md-6">
                    <div class="card-end" style="min-height: 100px;">
                        <strong class="text-primary"> Total em m² </strong>
                        <div class="card-end-middle" *ngIf="isNullOrUndefined(standartDetails.amountMetersPaint)">
                            <span>Não informado</span>
                        </div>
                        <div class="card-end-middle" *ngIf="!isNullOrUndefined(standartDetails.amountMetersPaint)">
                            <h3 class="text-primary"><strong>{{standartDetails.amountMetersPaint}}</strong><strong
                                    class="m2">m²</strong></h3>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card-end" style="min-height: 100px;">
                        <strong class="text-primary"> Termo de autorização </strong>
                        <div class="card-end-middle" *ngIf="isNullOrUndefined(documents)">
                            <p>Não enviado</p>
                        </div>
                        <div class="card-end-middle" *ngIf="!isNullOrUndefined(documents)">
                            <a [href]="documents[0].url" target="_blank" class="card-end">
                                <svg-icon src="assets/svg/term.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalResaleProject" data-keyboard="false" data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <a href="javascript:void('');" class="modal_close ml-auto" aria-label="Close" type="button"
                   (click)="closeModal('modalResaleProject')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small pt-1" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <h1 class="page-title mb-2">Projeto</h1>
                                    <p>Envie no campo abaixo um arquivo com a proposta para realização do projeto, que
                                        deverá ser aprovada pela revenda em até 5 dias.</p>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="autorization-card">
                                        <img src="assets/images/addimage.png" alt="">
                                        <span class="is-cursor">
                                            <span class="text-primary">Adicionar Arquivo</span>
                                            <input accept="image/vnd.sealedmedia.softseal.jpg,
                                            application/pdf,image/png,image/jpeg" type="file"
                                                   (change)="this.uploadDocument($event)" id="myfileProject"
                                                   class="d-none">
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <!--<div class="input-material">
                                <input id="nameFilter" class="form-control" type="text" required />
                                <textarea name="iptProjectDescription" id="iptProjectDescription" class="form-control" rows="2"></textarea>
                                <label for="iptProjectDescription">
                                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                                    <span>Observação</span>
                                </label>
                            </div> -->
                        </div>
                        <!--<div class="col-12 my-3">
                            <button class="btn btn-primary btn-lg text-white w-100 mt-3">
                                Salvar
                            </button>
                        </div>-->
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
