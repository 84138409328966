import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    CreateFinishStandartItemFleetInput,
    CreateStandartItemFleetGQL,
    CreateStandartItemFleetInput,
    DefaultMessage,
    DeleteImageGQL,
    DeleteStandartItemBySupplierGQL,
    FinishCreateStandartItemFleetGQL,
    GetStandartItemServiceByIdGQL,
    GetStandartSupplierServiceGQL,
    InputMaybe,
    Maybe,
    RemoveDocumentGQL,
    ReplyStandartMessageGQL,
    ReplyStandartMessageInput,
    SaveStandartItemServiceGQL,
    Scalars,
    SearchDocumentsGQL,
    SearchDocumentsQuery,
    StandartItem,
    StandartItemFleetDto,
    StandartItemImage,
    StandartItemServiceDto,
    StandartItemServiceInput,
    StandartItemServiceTypeInput,
    StandartItemsFleetGQL,
    StandartMessage,
    StandartMessageFleetDto,
    StandartMessagesFleetGQL,
    StandartMessagesFleetQuery,
    StandartMessagesFleetQueryVariables,
    StandartMessagesGQL,
    StandartMessagesQuery,
    StandartMessagesQueryVariables,
    StandartServiceDto,
    StandartStandardizationByIdUserSupplierGQL,
    StandartStandardizationByIdUserSupplierQuery,
    StandartTransmitUserSupplierInput,
    TransmitDeclineUserSupllierStandartGQL,
    TransmitUserSupllierFleetStandartGQL,
    UpdateStandartItemServiceGQL,
    UpdateSwapPlateStandardGQL,
    UserDocument,
    UserStandartItem,
    UserStandartItemImage,
    UserSupplierStandartDetails
} from '../../../../generated/graphql';
import {BaseComponent} from '../../../base/base.component';
import {UserService} from '../../../service/user.service';
import {QueryRef} from 'apollo-angular';
import {error} from 'protractor';
import moment from 'moment/moment';
import {Supplier} from '../model/supplier';

declare var $;

@Component({
    selector: 'app-resellers-detail',
    templateUrl: './resellers-detail.component.html',
    styleUrls: ['./resellers-detail.component.scss']
})
export class ResellersFleetDetailComponent extends BaseComponent implements OnInit, OnDestroy {
    step = 1;
    file: File = null;
    bannerBuffer: string | ArrayBuffer;
    paramId: string;
    standartDetails: UserSupplierStandartDetails = new UserSupplierStandartDetails();
    fileTerms: any[];
    fileService: any[];
    fileServiceExit: any[];
    documents: UserDocument[] = [];
    documentsChecklist: UserDocument[] = [];
    documentsChecklistExit: UserDocument[] = [];
    documentsCertificate: UserDocument[] = [];
    searchDocumentsQuery: QueryRef<SearchDocumentsQuery>;
    searchDocumentsCertificateQuery: QueryRef<SearchDocumentsQuery>;
    idUserSupplierQuery: QueryRef<StandartStandardizationByIdUserSupplierQuery>;
    currentUpload: UserStandartItemImage = new UserStandartItemImage();
    thumpPath: string | ArrayBuffer;

    modelChat: any = {id: '', name: ''};
    standartMessage: StandartMessageFleetDto[] = [];
    modalMessage: StandartMessage = new StandartMessage();
    textResponse: string;
    standartMessageQuery: QueryRef<StandartMessagesQuery, StandartMessagesQueryVariables>;
    standartMessageFleetQuery: QueryRef<StandartMessagesFleetQuery, StandartMessagesFleetQueryVariables>;
    statusFront: string;
    notPaint = false;
    transmitDecline = false;

    modelJustifyTemp = '';
    modelDimensionTemp: any = null;

    modalAddVehicleTab = 0;
    modalVehicleTab = 0;

    modelInfoStandardization: boolean = null;

    newVehicle: CreateStandartItemFleetInput = new CreateStandartItemFleetInput();
    newVehicleImage: any[] = [];
    newVehicleImageIndex = 0;
    itemsFleet: StandartItemFleetDto[] = [];

    transmitFleet: StandartTransmitUserSupplierInput = new StandartTransmitUserSupplierInput();
    itemService: StandartItemServiceDto = new StandartItemServiceDto();
    supplier: Supplier[] = [];

    uploadCurrentType = '';

    constructor(public router: Router,
                public activatedRoute: ActivatedRoute,
                public loading: NgxSpinnerService,
                public idUserSupplierGQL: StandartStandardizationByIdUserSupplierGQL,
                public removeDocumentGQL: RemoveDocumentGQL,
                public userService: UserService,
                public createStandartItemFleetGQL: CreateStandartItemFleetGQL,
                public finishCreateStandartItemFleetGQL: FinishCreateStandartItemFleetGQL,
                public standartItemsFleetGQL: StandartItemsFleetGQL,
                public getStandartSupplierServiceGQL: GetStandartSupplierServiceGQL,
                public getStandartItemServiceByIdGQL: GetStandartItemServiceByIdGQL,
                public saveStandartItemServiceGQL: SaveStandartItemServiceGQL,
                public updateStandartItemServiceGQL: UpdateStandartItemServiceGQL,
                private searchDocumentsGQL: SearchDocumentsGQL,
                private transmitUserSupllierFleetStandartGQL: TransmitUserSupllierFleetStandartGQL,
                private standartMessagesGQL: StandartMessagesGQL,
                private standartMessagesFleetGQL: StandartMessagesFleetGQL,
                private replyStandartMessageGQL: ReplyStandartMessageGQL,
                private updateSwapPlateStandardGQL: UpdateSwapPlateStandardGQL,
                private deleteStandartItemBySupplierGQL: DeleteStandartItemBySupplierGQL,
                private deleteImageGQL: DeleteImageGQL,
                private transmitDeclineUserSupllierStandartGQL: TransmitDeclineUserSupllierStandartGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.standartItemsFleetGQL.watch().valueChanges.subscribe(({data}) => {
            this.itemsFleet = data.standartItemsFleet as StandartItemFleetDto[];
        }, error => super.onError(error));

        this.activatedRoute.params.subscribe(url => {
            if (!this.isNullOrUndefined(url?.id)) {
                this.paramId = url.id;
                this.idUserSupplierQuery = this.idUserSupplierGQL.watch({id: this.paramId});
                this.idUserSupplierQuery.valueChanges.subscribe(({data}) => {
                    this.standartDetails = data.standartStandardizationByIdUserSupplier as UserSupplierStandartDetails;
                    this.standartDetails.document = this.standartDetails.document?.filter(x => x.type === 'project');
                    if (!this.isNullOrUndefined(this.standartDetails.standardizationJustification)) {
                        this.transmitDecline = true;
                    }
                    if (!this.isNullOrUndefined(this.standartDetails.standardizationJustification) || this.standartDetails.amountMetersPaint == 0) {
                        this.notPaint = true;
                    }
                    if (!this.isNullOrUndefined(this.modelJustifyTemp)) {
                        this.standartDetails.standardizationJustification = this.modelJustifyTemp;
                        this.modelJustifyTemp = '';
                    }
                    if (this.isNullOrUndefined(this.standartDetails.amountMetersPaint)) {
                        this.standartDetails.amountMetersPaint = 0;
                    }
                    if (!this.isNullOrUndefined(this.modelDimensionTemp)) {
                        this.standartDetails.amountMetersPaint = this.modelDimensionTemp;
                        this.modelDimensionTemp = '';
                    }
                    this.searchDocument();
                    this.statusFront = this.handlerStatusPhoto();
                    this.modelChat.id = this.standartDetails.id;
                    this.modelChat.name = 'standardization';
                    this.getMessages();
                    // this.transmitFleet.isStandardizationPerformed = true;

                    if (!this.isNullOrUndefined(this.standartDetails.isStandardizationPerformed)) {
                        this.transmitFleet.isStandardizationPerformed = this.standartDetails.isStandardizationPerformed;
                    }

                    if (!this.isNullOrUndefined(this.standartDetails.justificationFleetVisit)) {
                        this.transmitFleet.justification = this.standartDetails.justificationFleetVisit;
                    }

                    if (!this.isNullOrUndefined(this.standartDetails.fleetServiceAt)) {
                        this.transmitFleet.fleetServiceAt = this.standartDetails.fleetServiceAt;
                    }

                    if (!this.isNullOrUndefined(this.standartDetails.standardizationPerformedBy)) {
                        this.transmitFleet.standardizationPerformedBy = this.standartDetails.standardizationPerformedBy;
                    }

                }, error => super.onError(error));
            }
        });
        /*this.userService.chat.subscribe({
            next: data => {
                this.modelChat = data;
                if (!this.isNullOrUndefined(this.modelChat.id)) {
                    this.getMessages();
                }
            }, error: err => super.onError(err)
        });*/
    }

    initDate(timeOut = 0) {
        setTimeout(() => {
            if (!this.isNullOrUndefined(this.standartDetails.fleetServiceAt)) {
                $('.picker-period').val(moment(this.standartDetails.fleetServiceAt).utc().format('DD/MM/YYYY'));
            }

            if (!this.isNullOrUndefined(this.transmitFleet.fleetServiceAt)) {
                $('.picker-period').val(moment(this.transmitFleet.fleetServiceAt).utc().format('DD/MM/YYYY'));
            }

            $('.picker-period').daterangepicker({
                autoUpdateInput: false,
                startDate: this.standartDetails.fleetServiceAt ? moment(this.standartDetails.fleetServiceAt) : undefined,
                singleDatePicker: true,
                cancelButtonClasses: 'btn-danger',
                locale: this.getObjectLocaleDatePicker()
            });

            $('.picker-period').on('apply.daterangepicker', (ev, picker) => {
                $('.picker-period').val(picker.startDate.format('DD/MM/YYYY'));
                this.transmitFleet.fleetServiceAt = moment(picker.startDate).utcOffset(0, true).format();
            });

            $('.picker-period').on('cancel.daterangepicker', (ev, picker) => {
                $('.picker-period').val('');
                this.transmitFleet.fleetServiceAt = null;
            });
        }, timeOut);
    }

    ngOnDestroy(): void {
        super.destroyModal('modalComments');
        super.destroyModal('modalFinish');
    }

    setCroppedImage(o: any) {
        this.bannerBuffer = o.thumb;
        this.handleUpload(o.file);
        this.uploadImage();
    }

    handlerSwapPlate() {
        console.log(this.standartDetails.isSwapPlate);
        this.updateSwapPlateStandardGQL.mutate({
            isSwapPlate: this.standartDetails.isSwapPlate,
            standardizationId: this.standartDetails.id
        }).subscribe(({data}) => {
            this.idUserSupplierQuery.refetch({id: this.paramId}).then();
        }, error => super.onError(error));
    }

    actionTransmitDecline() {
        const frontWall = [];
        this.standartDetails.item.find((e) => e.type == 'front-wall').images.forEach((e) => {
            if (!this.isNullOrUndefined(e.url)) {
                frontWall.push(e.status);
            }
        });
        if (this.isNullOrUndefined(frontWall)) {
            return this.showMessage('Atenção!', 'Adicione pelo menos uma foto na padronização.', 'warning');
        }
        if (this.isNullOrUndefined(this.standartDetails.standardizationJustification)) {
            return this.showMessage('Atenção!', 'Justificativa obrigatória.', 'warning');
        }
        this.loading.show();
        this.transmitDeclineUserSupllierStandartGQL.mutate({
            id: this.standartDetails.id,
            justification: this.standartDetails.standardizationJustification
        }).subscribe(({data}) => {
            this.loading.hide();
            setTimeout(() => {
                this.showMessage('RECUSO DA PADRONIZAÇÃO ENVIADA COM SUCESSO!', 'Todas as informações foram enviadas para análise e conferência do auditor Copa Energia.', 'success');
            });
            this.router.navigate(['/resellers']);
        }, error => super.onError(error));
    }

    handleUpload(event) {
        const files = event;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage(this.translate.instant('profile.edit.warningAttention'),
                    this.translate.instant('profile.edit.warningUpload'), 'info');
                return;
            }
            if (files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage('Atenção', 'É permitido apenas enviar arquivos com extensões .jpg ou .png',
                    'warning');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.thumpPath = _event.target.result;
        };
        reader.readAsDataURL(event[0]);
        this.file = files[0];
        return files[0];
    }

    actionUploadImage(p: UserStandartItemImage, typeCurrent: string) {
        if (this.standartDetails.fleetStatus === 'received' || this.standartDetails.fleetStatus === 'approved' || p.status === 'approved') {
            return;
        }
        this.uploadCurrentType = typeCurrent;
        this.currentUpload = p;
        this.showModal('modalCropResseler');
    }

    uploadImage() {
        const idParam = this.uploadCurrentType === 'adhesion' ? this.standartDetails.idAdhesion : this.standartDetails.id;
        this.userService.uploadImage(this.file, idParam, this.currentUpload.id).subscribe(
            data => {
                console.log('justificativa temporario standartDetails.standardizationJustification');
                this.modelJustifyTemp = this.standartDetails.standardizationJustification;
                this.modelDimensionTemp = this.standartDetails.amountMetersPaint;
                this.uploadCurrentType = '';
                this.idUserSupplierQuery.refetch({id: this.paramId}).then();
            }, error => super.onError(error));
    }

    finish() {
        if (this.documents.length < 1) {
            return this.showMessage('Atenção!', 'Upload de documento obrigatório', 'warning');
        }
        if (this.documentsCertificate.length < 1) {
            return this.showMessage('Atenção!', 'O envio do Termo de Garantia é obrigatório', 'warning');
        }

        if (this.transmitFleet.isStandardizationPerformed === null || this.transmitFleet.isStandardizationPerformed === undefined) {
            return this.showMessage('Atenção!', 'Selecione se a padronização foi realizada na aba de "Informações"', 'warning');
        }

        if (this.transmitFleet.isStandardizationPerformed === false) {
            if (this.isNullOrUndefined(this.transmitFleet.justification)) {
                return this.showMessage('Atenção!', 'Motivo da padronização não realizada é obrigatório', 'warning');
            }
        }
        /* else {
            if (this.isNullOrUndefined(this.transmitFleet.standardizationPerformedBy)) {
                return this.showMessage('Atenção!', 'Quem realizou a Padronização é obrigatório', 'warning');
            }
        }*/

        let validStandardization = false;

        this.standartDetails.item.forEach((item) => {
            if (item.type !== 'front-wall') {
                const url = item.images.findIndex((e) => !this.isNullOrUndefined(e.url));
                if (url < 0) {
                    validStandardization = true;
                }
            }
        });

        if (validStandardization) {
            return this.showMessage('Atenção!', 'Pelo menos 1 foto da Padronização é obrigatório', 'warning');
        }

        const data: StandartTransmitUserSupplierInput = {
            fleetServiceAt: this.transmitFleet.isStandardizationPerformed ? this.transmitFleet.fleetServiceAt : undefined,
            id: this.standartDetails.id,
            isStandardizationPerformed: this.transmitFleet.isStandardizationPerformed,
            justification: this.transmitFleet.isStandardizationPerformed ? undefined : this.transmitFleet.justification,
            standardizationPerformedBy: this.transmitFleet.isStandardizationPerformed ? this.transmitFleet.standardizationPerformedBy : undefined,
            type: 'fleet',
        };

        this.loading.show();
        this.transmitUserSupllierFleetStandartGQL.mutate({data: data}).subscribe(({data}) => {
            this.loading.hide();
            // this.showModal('modalFinish');
            setTimeout(() => {
                this.showMessage('PADRONIZAÇÃO ENVIADA COM SUCESSO!', 'Todas as informações foram enviadas para análise e conferência do auditor Copa Energia.', 'success');
            });
            this.router.navigate(['/resellersFleet']);

        }, error => super.onError(error));
    }

    searchDocument() {
        this.searchDocumentsQuery = this.searchDocumentsGQL.watch({
            id: this.standartDetails.user.id,
            type: 'fleet-authorization'
        });
        this.searchDocumentsQuery.valueChanges.subscribe(({data}) => {
            this.documents = data.searchDocuments as UserDocument[];
        }, error => super.onError(error));

        this.searchDocumentsCertificateQuery = this.searchDocumentsGQL.watch({
            id: this.standartDetails.user.id,
            type: 'certificate'
        });
        this.searchDocumentsCertificateQuery.valueChanges.subscribe(({data}) => {
            this.documentsCertificate = data.searchDocuments as UserDocument[];
        }, error => super.onError(error));
    }

    uploadTerms(event: any, type: string) {
        if (this.documents.length === 3) {
            this.showMessage('Atenção!', 'É possível enviar apenas 3 documentos.', 'warning');
            return;
        }
        this.fileTerms = event.target.files;
        this.userService.uploadFile(this.fileTerms[0], 'user-document',
            `${this.standartDetails.user.id}/${type}`).subscribe(({data}) => {
            this.fileTerms = [];
            this.searchDocumentsQuery.refetch().then();
            this.searchDocumentsCertificateQuery.refetch().then();
            this.showMessage('Sucesso!', 'Termo enviado com sucesso!', 'success');
            $('#myfile').val('');
        }, error => super.onError(error));
    }

    deleteTerms(_id) {
        this.removeDocumentGQL.mutate({id: _id}).subscribe(({data}) => {
            this.searchDocumentsQuery.refetch().then();
            this.searchDocumentsCertificateQuery.refetch().then();
        }, error => super.onError(error));
    }

    handlerResponse(m: StandartMessage) {
        this.modalMessage = Object.assign({}, m);
        this.textResponse = undefined;
        super.showModal('modalComments');
    }

    onCloseComment() {
        $('.comment').toggleClass('is-closed');
    }

    getMessages() {
        this.standartMessageFleetQuery = this.standartMessagesFleetGQL.watch({idAdhesion: this.standartDetails.idAdhesion, idStandardization: this.standartDetails.id});
        this.standartMessageFleetQuery.valueChanges.subscribe(({data}) => {
            this.standartMessage = data.standartMessagesFleet as StandartMessageFleetDto[];
        });
    }

    actionResponse() {
        const arrValidateFields = [
            {value: this.textResponse, text: `Mensagem <br>`}
        ];
        const stringError = this.validateFieldZero(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente:<br/><br/> ${stringError}`);
            return;
        }
        const input: ReplyStandartMessageInput = {
            id: this.modalMessage.id,
            reply: this.textResponse,
        };
        this.loading.show();
        this.replyStandartMessageGQL.mutate({input}).subscribe(({data}) => {
            super.closeModal('modalComments');
            this.standartMessageQuery.refetch({id: this.modelChat?.id});
            this.loading.hide();
            this.showMessage('Sucesso!', 'Resposta enviada com sucesso!', 'success');
        }, error => super.onError(error));
    }

    handlerViewMessage(step: number) {
        const allowedTypes = new Set(['pickup', 'vuc', 'motorcycle', 'transfer-truck', 'truck']);
        return this.standartMessage.filter((e) => allowedTypes.has(e.type)) ?? this.standartMessage;
        /*switch (step) {
            case 1:
                return this.standartMessage.filter((e) => ((e.type === 'pickup') || (e.type === 'motocicle') || (e.type === 'motocicle')) );
            case 2:
                return this.standartMessage.filter((e) => e.type == 'dimension');
            case 3:
                return this.standartMessage.filter((e) => e.type == 'documentAuthorization');
            default:
                return this.standartMessage;
        }*/
    }

    handlerStatusPhoto() {
        const statusArray = [];
        const frontWall = this.standartDetails.item.find((e) => e.type == 'front-wall');
        frontWall.images.forEach((e) => {
            if (!this.isNullOrUndefined(e.url)) {
                statusArray.push(e.status);
            }
        });
        let status = statusArray.find((e) => e == 'review');
        if (this.isNullOrUndefined(status)) {
            status = statusArray.find((e) => e == 'approved');
        }
        return status;
    }

    handlerStep(step: number) {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.step = step;
        if (this.step == 2) {
            this.initDate(200);
        }
    }

    handlerButton() {
        let visible = true;
        if (this.standartDetails.fleetStatus === 'received' || this.standartDetails.fleetStatus === 'approved') {
            if (this.transmitDecline) {
                visible = false;
            }
        }
        return visible;
    }

    getStatusEditView(w: any) {
        return w.status === 'pending' || w.status === 'review';
    }

    getStatusVeichleExtra(w: any) {
        return w.status !== null;
    }

    actionRemovePhoto(p: UserStandartItemImage) {
        super.confirmMessage('Atenção', 'Deseja realmente remover a foto?', () => {
            this.onRemovePhoto(p.id);
        });
    }

    onRemovePhoto(id: string) {
        this.deleteImageGQL.mutate({input: id}).subscribe(
            (data) => {
                this.idUserSupplierQuery.refetch({id: this.paramId}).then();
            }, err => super.onError(err)
        );
    }

    addVehicle() {
        this.modalAddVehicleTab = 0;
        this.newVehicle = new CreateStandartItemFleetInput();
        this.newVehicleImage = [
            {id: '', url: null, file: null, name: 'Foto 1'},
            {id: '', url: null, file: null, name: 'Foto 2'},
            {id: '', url: null, file: null, name: 'Foto 3'},
            {id: '', url: null, file: null, name: 'Foto 4'}
        ];
        this.showModal('modalAddVehicle');
    }

    actionVehicleUpload(index: number) {
        this.newVehicleImageIndex = index;
        this.showModal('modalCropNewVehicle');
    }

    setCroppedImageVeihcle(o: any) {
        this.newVehicleImage[this.newVehicleImageIndex].url = o.thumb;
        this.newVehicleImage[this.newVehicleImageIndex].file = this.handleUpload(o.file);
    }

    async actionNewVehicle() {
        const index = this.newVehicleImage.findIndex((e) => e.file !== null);
        if (index == -1) {
            this.showMessage('Atenção', `Adicione pelo menos uma foto`);
            return;
        }
        this.newVehicle.resaleId = this.standartDetails.resaleId;
        const listPromise: any[] = [];

        listPromise.push(new Promise((resolve, reject) => {
            this.createStandartItemFleetGQL.mutate({data: this.newVehicle}).subscribe(({data}) => {
                resolve(data.createStandartItemFleet.id);
            }, error => super.onError(error));
        }));

        this.newVehicleImage.forEach((e, index) => {
            if (!this.isNullOrUndefined(e.file)) {
                listPromise.push(new Promise((resolve, reject) => {
                    this.userService.uploadFile(e.file, 'standart-image-fleet').subscribe((data) => {
                        this.newVehicleImage[index].id = (data as DefaultMessage).message;
                        resolve(this.newVehicleImage[index].id);
                    }, error => super.onError(error));
                }));
            }
        });
        const item = await Promise.all(listPromise);
        const data: CreateFinishStandartItemFleetInput = {
            resaleId: this.standartDetails.resaleId,
            itemId: item[0].toString(),
            image: this.handlerUpload()
        };
        this.finishCreateStandartItemFleetGQL.mutate({data}).subscribe((data) => {
            this.closeModal('modalAddVehicle');
            this.showMessage('Sucesso!', 'Veiculo adicionado com sucesso!', 'success');
            this.idUserSupplierQuery.refetch().then();
        }, error => super.onError(error));
    }

    handlerUpload() {
        let arr = [];
        this.newVehicleImage.forEach(e => {
            if (!this.isNullOrUndefined(e.id)) {
                const array = e.id.split('/');
                arr.push({name: array[array.length - 1]});
            }
        });
        return arr;
    }

    stepTab() {
        const arrValidateFields = [
            {value: this.newVehicle.type, text: 'Tipo <br>'},
            {value: this.newVehicle.plate, text: 'Placa <br>'},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Verifique os seguintes campos: <br> ${stringError}`);
            return;
        }
        this.modalAddVehicleTab = 1;
    }

    onDeleteItem(x: UserStandartItem) {
        super.confirmMessage('Atenção', 'Deseja realmente remover este veículo?', () => {
            this.deleteStandartItemBySupplierGQL.mutate({dataId: x.isDeletedAdhesionBySupplier, data: x.id}).subscribe(result => {
                this.idUserSupplierQuery.refetch({id: this.paramId});
                super.showMessage('Sucesso', 'Veículo removido com sucesso');
            });
        });
    }

    addService(model: UserStandartItem) {
        this.modalVehicleTab = 0;
        this.itemService = new StandartItemServiceDto();
        this.itemService.itemId = model.id;
        this.itemService.type = model.type;
        if (!this.isNullOrUndefined(model.service)) {
            this.getStandartItemServiceByIdGQL.watch({id: model.service.id}).valueChanges.subscribe(({data: data1}) => {
                this.itemService = data1.getStandartItemServiceById as StandartItemServiceDto;
                this.itemService.plate = this.itemService.plate ?? model.plate;
                this.documentsChecklist = this.itemService.documents?.filter(x => x.type === 'checklist');
                this.documentsChecklistExit = this.itemService.documents?.filter(x => x.type === 'checklistExit');
                this.handlerListService(model.type);
                this.showModal('modalVehicleChecklist');
            }, error => super.onError(error));
        } else {
            this.handlerListService(model.type);
            this.itemService.plate = model.plate;
            this.showModal('modalVehicleChecklist');
        }
    }

    handlerListService(typeParam: string) {
        this.itemService.type = typeParam;
        this.getStandartSupplierServiceGQL.watch({
            type: typeParam,
            resaleId: this.standartDetails.resaleId
        }).valueChanges.subscribe(({data}) => {
            this.supplier = Object.assign([], data.getStandartSupplierService as Supplier[]);
            this.supplier.map((e) => {
                const index = this.itemService?.servicesTypes?.findIndex((type) => e.id === type.id);
         
                if (index >= 0) {
                    e.seleted = true;
                }
            });
        }, error => super.onError(error));
    }

    handlerServiceTypes(isupplierSelecterd: Supplier[]) {
        const list: StandartItemServiceTypeInput[] = [];
        isupplierSelecterd.forEach((e) => {
            list.push({id: e.id});
        });
        return list;
    }

    onSaveService() {
        var arrValidateFields: any[] = [
            {value: this.itemService.type, text: 'Tipo <br />'},
            {value: this.itemService.brand, text: 'Marca <br />'},
            {value: this.itemService.model, text: 'Modelo <br />'},
            {value: this.itemService.modelYear, text: 'Ano do Modelo <br />'},
            {value: this.itemService.plate, text: 'Placa do Veículo <br />'},
            {value: this.itemService.isOldBrand, text: 'Marca Antiga <br />'},
            {value: this.itemService.isPlateTop, text: 'Placa no Topo <br />'},
        ];

        if (this.itemService.isPlateTop) {
            arrValidateFields.push(
                {value: this.itemService.plateMeasurements, text: 'Medidas das Placas <br />'},
                {value: this.itemService.numberPlates, text: 'Quantidade de Placas <br />'},
                {value: this.itemService.isFrontBack, text: 'Frente e Verso <br />'},
            );
        }

        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }


        const isupplierSelecterd = this.supplier.filter((e) => e.seleted);
        const data: StandartItemServiceInput = {
            brand: this.itemService.brand,
            id: this.itemService.id,
            isFrontBack: this.itemService.isPlateTop ? this.itemService.isFrontBack : undefined,
            isOldBrand: this.itemService.isOldBrand,
            isPlateTop: this.itemService.isPlateTop,
            itemId: this.itemService.itemId,
            model: this.itemService.model,
            modelYear: this.itemService.modelYear,
            numberPlates: this.itemService.isPlateTop ? this.itemService.numberPlates : undefined,
            observation: this.itemService.observation,
            plate: this.itemService.plate,
            amountLogo: this.itemService.amountLogo,
            plateMeasurements: this.itemService.isPlateTop ? this.itemService.plateMeasurements : undefined,
            servicesTypes: this.handlerServiceTypes(isupplierSelecterd),
            type: this.itemService.type,
        };
        // console.log(data);
        if (this.isNullOrUndefined(this.itemService.id)) {
            this.onCreateService(data);
        } else {
            this.onUpdateService(data);
        }
        // this.closeModal('modalVehicleChecklist');
    }

    onCreateService(_data: StandartItemServiceInput) {
        this.saveStandartItemServiceGQL.mutate({data: _data}).subscribe(({data}) => {
            this.closeModal('modalVehicleChecklist');
            const service = data.saveStandartItemService as StandartItemServiceDto;
            if (!this.isNullOrUndefined(this.fileService)) {
                this.uploadSalveService(service.id, 'checklist');
            }
            if (!this.isNullOrUndefined(this.fileServiceExit)) {
                this.uploadSalveService(service.id, 'checklistExit');
            }
            this.idUserSupplierQuery.refetch().then();
            setTimeout(() => {
                super.showMessage('Sucesso', 'Serviço criado com sucesso');
            }, 500);
        }, error => super.onError(error));
    }

    onUpdateService(_data: StandartItemServiceInput) {
        this.updateStandartItemServiceGQL.mutate({data: _data}).subscribe(({data}) => {
            this.closeModal('modalVehicleChecklist');
            const service = data.updateStandartItemService as StandartItemServiceDto;
            if (!this.isNullOrUndefined(this.fileService)) { /*&& !this.isNullOrUndefined(service)*/
                this.uploadSalveService(service.id, 'checklist');
                if (this.documentsChecklist.length > 0) {
                    this.deleteTerms(this.documentsChecklist[0].id);
                }
            }
            if (!this.isNullOrUndefined(this.fileServiceExit)) {
                this.uploadSalveService(service.id, 'checklistExit');
                if (this.documentsChecklistExit.length > 0) {
                    this.deleteTerms(this.documentsChecklistExit[0].id);
                }
            }
            this.idUserSupplierQuery.refetch().then();
            setTimeout(() => {
                super.showMessage('Sucesso', 'Serviço atualizado com sucesso');
            }, 500);
        }, error => super.onError(error));
    }

    uploadService(event: any) {
        this.fileService = event.target.files;
    }

    uploadServiceExit(event: any) {
        this.fileServiceExit = event.target.files;
    }

    uploadSalveService(id: string, type: string) {
        const file = type === 'checklist' ? this.fileService[0] : this.fileServiceExit[0];
        this.userService.uploadFile(file, 'user-document',
            `${id}/${type}`).subscribe(({data}) => {
            if (type === 'checklist') {
                this.fileService = [];
            } else {
                this.fileServiceExit = [];
            }
            this.searchDocumentsQuery.refetch().then();
        }, error => super.onError(error));
    }

}
