import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {DashboardService} from '../../service/dashboard.service';
import {ExcelService} from '../../service/excel.service';
import {
    ActiveUsers,
    ActiveUsersGQL, ContactCount, ContactCountGQL, KeyValue, LotDashboard, LotDashboardGQL,
    StandardCountData, StandartCountGQL, UserAdmin, UsersByGmeGQL
} from '../../../generated/graphql';
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit {

    user: UserAdmin;
    options = {
        legend: {position: 'none'},
        height: '100%',
    };

    chartWidth = 0;
    chartHeight = 0;
    chartAccess;
    activeUsers: ActiveUsers = new ActiveUsers();
    usersByGme: KeyValue[] = [];
    standardization: StandardCountData;
    adhesion: StandardCountData;
    contact: ContactCount;
    lot: LotDashboard[] = [];

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public dashboardService: DashboardService,
                private activeUsersGQL: ActiveUsersGQL,
                private usersByGmeGQL: UsersByGmeGQL,
                private standartCountGQL: StandartCountGQL,
                private contactCountGQL: ContactCountGQL,
                private lotDashboardGQL: LotDashboardGQL,
                public userService: UserService,
                public excelService: ExcelService
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as UserAdmin;
                if (this.user.profile !== 'admin') {
                    this.router.navigate(['/resales']);
                }
            }, error: err => super.onError(err)
        });

        this.resizeCharts('.js-chart-box');
        this.dashboardService.getAccess().subscribe({
            next: data => {
                this.chartAccess = data;
                console.log(this.chartAccess);
            }, error: err => super.onError(err),
        });

        this.activeUsersGQL.watch().valueChanges.subscribe(({data}) => {
            this.activeUsers = data.activeUsers as unknown as ActiveUsers;
        }, error => this.onError(error));

        this.usersByGmeGQL.watch().valueChanges.subscribe(({data}) => {
            this.usersByGme = data.usersByGme as KeyValue[];
        }, error => this.onError(error));

        this.standartCountGQL.watch({input: 'standardization'}).valueChanges.subscribe(({data}) => {
            this.standardization = data.standartCount as StandardCountData;
        }, error => this.onError(error));

        this.standartCountGQL.watch({input: 'adhesion'}).valueChanges.subscribe(({data}) => {
            this.adhesion = data.standartCount as StandardCountData;
        }, error => this.onError(error));

        this.contactCountGQL.watch().valueChanges.subscribe(({data}) => {
            this.contact = data.contactCount as ContactCount;
        }, error => this.onError(error));

        this.lotDashboardGQL.watch().valueChanges.subscribe(({data}) => {
            this.lot = data.lotDashboard as LotDashboard[];
        }, error => this.onError(error));
    }

    resizeCharts(cssCLass = '.js-chart-box') {
        let resizeTimeout;
        $(() => {
            this.chartWidth = $(cssCLass)[0].getBoundingClientRect().width;
            this.chartHeight = 300;
            $(window).on('resize', () => {
                clearTimeout(resizeTimeout);
                resizeTimeout = setTimeout(() => {
                    this.chartWidth = $(window)
                        .width() > 1024 ? $(window).width() - ($('#sidebar').width() + 150)
                        : $(cssCLass)[0].getBoundingClientRect().width;
                    this.chartHeight = 300;
                }, 100);
            });
        });
    }

    getExcel() {
        this.excelService.getExcelDashboard().subscribe({
            next: data => {
                this.downloadHandlerFile(data,'Dashboard');
            }, error: err => super.onError(err)
        });
    }

}
