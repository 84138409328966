import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
    CreateStandartMessageGQL,
    CreateStandartMessageInput, CreateStandartMessageStandartInput,
    EvaluateAdhesionGQL, EvaluateStandardizationGQL,
    EvaluateStandartInput, EvaluateStandartItemImageInput, EvaluateStandartItemInput,
    Standart, StandartGQL,
    StandartItemImage, StandartMessage, StandartMessagesGQL, StandartQuery, StandartQueryVariables,
    UserStandart
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import moment from "moment";
import {Lightbox} from "ngx-lightbox";
import {IAlbum} from "ngx-lightbox/lightbox-event.service";

declare var $: any;

@Component({
    selector: 'app-measures',
    templateUrl: './measures.component.html',
    styleUrls: ['./measures.component.scss']
})
export class MeasuresComponent extends BaseComponent implements OnInit {
    paramId: any;
    standArt: Standart = new Standart();
    standartMessages: StandartMessage[] = [];
    standArtQuery: QueryRef<StandartQuery, StandartQueryVariables>;
    _albums = [];
    file: any[];


    constructor(public router: Router,
                public route2: ActivatedRoute,
                public loading: NgxSpinnerService,
                public standArtByIdGQL: StandartGQL,
                public evaluateAdhesionGQL: EvaluateAdhesionGQL,
                public evaluateStandardizationGQL: EvaluateStandardizationGQL,
                public standartMessagesGQL: StandartMessagesGQL,
                public createStandartMessageGQL: CreateStandartMessageGQL,
                private lightbox: Lightbox,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.route2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.paramId = url.id;
                this.loading.show();
                this.standArtQuery = this.standArtByIdGQL.watch({id: this.paramId});
                this.standArtQuery.valueChanges.subscribe(({data}) => {
                    this.standArt = JSON.parse(JSON.stringify(data.standart));
                    console.log(this.standArt);
                    this.getListMessage();
                    this.loading.hide();
                }, error => super.onError(error));
            }
        });
    }

    getListMessage() {
        this.loading.show();
        this.standartMessagesGQL.watch({id: this.paramId}).valueChanges.subscribe(({data}) => {
            this.standartMessages = JSON.parse(JSON.stringify(data.standartMessages));
            this.loading.hide();
        }, error => super.onError(error));
    }

    onValidate() {
        const input: EvaluateStandartInput = {
            id: this.paramId,
            item: this.createListItem(),
        };
        this.loading.show();
        console.log('this.standArt');
        console.log(this.standArt);
        console.log(input);
        if (this.standArt.type === 'adhesion') {
            this.onTransmitAdhesion(input);
        } else {
            this.onTransmitStandardization(input);
        }
    }

    onTransmitAdhesion(o: EvaluateStandartInput): void {
        this.evaluateAdhesionGQL.mutate({input: o}).subscribe(({data}) => {
            this.createMessage();
            this.loading.hide();
            this.showMessage('Sucesso!', 'Avaliação enviada com sucesso!', 'success');
        }, error => super.onError(error));
    }

    onTransmitStandardization(o: EvaluateStandartInput): void {
        this.evaluateStandardizationGQL.mutate({input: o}).subscribe(({data}) => {
            this.createMessage();
            this.loading.hide();
            this.showMessage('Sucesso!', 'Avaliação enviada com sucesso!', 'success');
        }, error => super.onError(error));
    }

    open(p: StandartItemImage): void {
        const iAlbum: IAlbum = {
            caption: p.name,
            src: p.url,
            thumb: p.url,
        };
        this.lightbox.open([iAlbum], 0, {
            wrapAround: false,
            showImageNumberLabel: false,
            disableScrolling: true,
            showZoom: false,
            showRotate: false
        });
    }

    validateCreateMessage() {
        let count = 0;
        const el = $('.js-validate');
        if (el[0]) {
            el.each((i, e) => {
                if (super.isNullOrUndefined($(e).val())) {
                    count++;
                }
            });
        }
        return count > 0;
    }

    createMessage() {
        if (this.validateCreateMessage()) {
            this.showMessage('Atenção!', 'Preencha os campos de justificativa!', 'warning');
            return;
        }
        const standArt: CreateStandartMessageStandartInput = {
            id: this.paramId,
        };
        const input: CreateStandartMessageInput[] = [];
        /*this.standArt.walls.forEach((e) => {
            if (e.status === 'review' || e.status === 'reproved') {
                return input.push({title: 'Medidas', type: e.type, message: $('#' + e.id).val(), standart: standArt});
            }
        });
        this.standArt.complement.forEach((e) => {
            if (e.status === 'review' || e.status === 'reproved') {
                return input.push({title: 'Medidas', type: e.type, message: $('#' + e.id).val(), standart: standArt});
            }
        });*/
        this.standArt.item.forEach((e) => {
            if (this.validateJustifyPhoto(e.images)) {
                return input.push({title: 'Fotos', type: e.type, message: $('#' + e.id).val(), standart: standArt});
            }
        });
        if (this.isNullOrUndefined(input)) {
            this.standArtQuery.refetch({id: this.paramId});
            return;
        }
        this.createStandartMessageGQL.mutate({input: input}).subscribe(({data}) => {
            this.standArtQuery.refetch({id: this.paramId});
            this.getListMessage();
        }, error => super.onError(error));
    }

    handlerStatusItem(i: number, pI: number, status: string, itemFather?) {
        if (!this.isNullOrUndefined(itemFather) && (itemFather.currentStatus === 'approved' || itemFather.currentStatus === 'reproved')) {
            return;
        }
        if (this.standArt.item[i].images[pI].currentStatus === 'approved' || this.standArt.item[i].images[pI].currentStatus === 'reproved') {
            return;
        }
        if (this.standArt.status === 'review') {
            return;
        }
        this.standArt.item[i].images[pI].status = status;
    }

    validateJustifyPhoto(images: StandartItemImage[]) {
        return images.some(element => element.status === 'reproved' || element.status === 'review');
    }

    createListItem() {
        const itemInput: EvaluateStandartItemInput[] = [];
        this.standArt.item.forEach((e) => {
            itemInput.push({type: "", id: e.id, images: this.createImage(e.images), isGreen: e.isGreen});
        });
        return itemInput;
    }


    createImage(images: StandartItemImage[]) {
        const imageInput: EvaluateStandartItemImageInput[] = [];
        images.forEach((e) => {
            imageInput.push({id: e.id, status: e.status});
        });
        return imageInput;
    }

    actionGetStandart(t: string) {
        let id = null;
        if (t === this.standArt.type) {
            return;
        }
        if (!this.isNullOrUndefined(this.standArt.father)) {
            id = this.standArt.father.id;
        } else if (!this.isNullOrUndefined(this.standArt.sons)) {
            id = this.standArt.sons.id;
        }
        if (this.isNullOrUndefined(id)) {
            return;
        }
        window.location.href = '/measures/' + id;
    }

    actionChangeIsGreen(c: any, el) {
        el.isGreen = c.srcElement.checked;
    }

    uploadProject(event: any) {
        this.file = event.target.files;
    }
}
