import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User, UserAdmin, UserSupplier} from '../../generated/graphql';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {
    isAuthenticated = false;

    private chatDataSource = new BehaviorSubject<any>({id: '', name: ''});
    chat = this.chatDataSource.asObservable();

    private userDataSource = new BehaviorSubject<UserSupplier>(new UserSupplier());
    user = this.userDataSource.asObservable();

    private notificationDataSource = new BehaviorSubject<Notification[]>([]);
    notification = this.notificationDataSource.asObservable();


    constructor(http: HttpClient) {
        super(http);
    }

    getAuthenticate(model) {
        const url = `${this.BASE_URL}/user/authenticate`;
        return this.http.post(url, model);
    }

    getStatusAuthenticated() {
        const token = localStorage.getItem('token') as string;
        this.isAuthenticated = token != null;
        return this.isAuthenticated;
    }

    search(page, filter) {
        const url = `${this.BASE_URL}/user/search/${page}`;
        return this.http.post(url, filter);
    }

    getMe() {
        const url = `${this.BASE_URL}/user/me`;
        return this.http.get(url).subscribe({
            next: data => {
                this.userDataSource.next(data as UserSupplier);
                this.getUserLocal();
            }
        });
    }

    create(model) {
        const url = `${this.BASE_URL}/user`;
        return this.http.post(url, model);
    }

    update(model) {
        const url = `${this.BASE_URL}/user`;
        return this.http.put(url, model);
    }

    updateUser(user: UserSupplier) {
        this.userDataSource.next(user);
    }

    getUserLocal(): UserSupplier {
        return this.userDataSource.value;
    }

    delete(id) {
        const url = `${this.BASE_URL}/user/${id}`;
        return this.http.delete(url);
    }

    getNotification() {
        const url = `${this.BASE_URL}/user/notification`;
        this.http.get(url).subscribe({
            next: data => {
                this.notificationDataSource.next(data as Notification[]);
            }, error: err => {
            }
        });
    }

    updateNotification(id) {
        const url = `${this.BASE_URL}/user/notification/${id}`;
        return this.http.put(url, {});
    }

    getExcelResale(model) {
        const url = `${this.BASE_URL}/users/search-user/excel`;
        return this.http.post(url, model);
    }

    getExcelCoupon(model) {
        const url = `${this.BASE_URL}/users/search-user-coupom/excel`;
        return this.http.post(url, model);
    }

    getExcelWinners(model) {
        const url = `${this.BASE_URL}/winners/search-winner/excel`;
        return this.http.post(url, model);
    }

    getExcelStandart(model) {
        const url = `${this.BASE_URL}/standart/search-standart/excel`;
        return this.http.post(url, model);
    }

    uploadFile(file, urlId, id?) {
        let url = `${this.BASE_URL}/upload/${urlId}/`;
        if (id !== undefined) {
            url = url + id;
        }
        return new Observable((observer) => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            if (file != null) {
                formData.append('file', file, file.name);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }

    uploadImage(file, id, imageId) {
        const url = `${this.BASE_URL}/upload/${id}/standart-image/${imageId}`;
        return new Observable((observer) => {
            //    observer.next();
            //  observer.complete();
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            if (file != null) {
                formData.append('file', file, file.name);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }

    uploadImageExtra(file, id, imageId) {
        const url = `${this.BASE_URL}/upload/${id}/standart-image-extra/${imageId}`;
        return new Observable((observer) => {
            //    observer.next();
            //  observer.complete();
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            if (file != null) {
                formData.append('file', file, file.name);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }
}
