import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
// import {ResetPasswordGQL, ResetPasswordInput} from "../../../generated/graphql";

@Component({
    selector: 'app-recovery',
    templateUrl: './recovery.component.html',
    styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent extends BaseComponent implements OnInit {
    resetPasswordInput: any = {}; // ResetPasswordInput = new ResetPasswordInput();

    // ResetPassword

    constructor(public router: Router,
                public route2: ActivatedRoute,
                public loading: NgxSpinnerService,
                // public resetPasswordGQL: ResetPasswordGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.route2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.resetPasswordInput.code = url.id;
            }
        });
    }

    reset(confirmPassword: string) {
        const arrValidateFields = [
            {value: this.resetPasswordInput.newPassword, text: `Senha <br>`},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha o campo  ${stringError} corretamente.`);
            return;
        }
        if (this.resetPasswordInput.newPassword.length < 4) {
            this.showMessage('Atenção', `Senha deve possuir ao menos 4 carácter`);
            return;
        }
        if (this.resetPasswordInput.newPassword != confirmPassword) {
            this.showMessage('Atenção', `Senhas não conferem`);
            return;
        }
        this.loading.show();
        console.log('descomentar linha abaixo para enviar')
        /*this.resetPasswordGQL.mutate({input: this.resetPasswordInput}).subscribe(({data}) => {
            this.router.navigate(['/login']);
            this.loading.hide();
            this.showMessage('Sucesso!', data.resetPassword.message, 'success');
        }, error => {
            super.onError(error);
            this.router.navigate(['/login']);
        })*/
    }
}
