import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {HomeComponent} from './views/home/home.component';
import {UserComponent} from './views/user/user.component';
import {AuthGuard} from './service/auth-guard.service';
import {LoginComponent} from './views/login/login.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {ParticipantsComponent} from './views/participants/participants.component';
import {MeasuresComponent} from './views/measures/measures.component';
import {PhotoComponent} from './views/photo/photo.component';
import {ContactComponent} from './views/contact/contact.component';
import {ResalesComponent} from './views/resales/resales.component';
import {RafflesComponent} from './views/raffles/raffles.component';
import {CouponsComponent} from './views/coupons/coupons.component';
import {BannersComponent} from './views/banners/banners.component';
import {WinnersComponent} from './views/winners/winners.component';
import {DocumentationComponent} from './views/documentation/documentation.component';
import {BannersDetailComponent} from './views/banners/banners-detail/banners-detail.component';
import {CropImageComponent} from './views/crop-image/crop-image.component';
import {ResellersComponent} from './views/resellers/resellers.component';
import {ResellersDetailComponent} from './views/resellers/detail/resellers-detail.component';
import {ManagementComponent} from './views/management/management.component';
import {ManagementDetailComponent} from './views/management/detail/management-detail.component';
import {RecoveryComponent} from './views/recovery/recovery.component';
import {ResellersFleetComponent} from './views/resellers-fleet/resellers.component';
import {ResellersFleetDetailComponent} from './views/resellers-fleet/detail/resellers-detail.component';
import {ManagementFleetComponent} from './views/management-fleet/management.component';
import {ManagementFleetDetailComponent} from './views/management-fleet/detail/management-detail.component';
import {ResellersFleetExtraComponent} from './views/resellers-fleet/extra/resellers-extra.component';
import {ResellersProjectComponent} from "./views/resellers-project/resellers-project.component";
import {
    ResellersProjectDetailComponent
} from "./views/resellers-project/resellers-project-detail/resellers-project-detail.component";
import {ManagementProjectComponent} from "./views/management-project/management-project.component";
import {
    ManagementProjectDetailComponent
} from "./views/management-project/management-project-detail/management-project-detail.component";

const routes: Routes = [
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'recuperacao/:id', component: RecoveryComponent
    },
    {
        path: '', component: MainComponent,
        children: [
            {path: 'home', component: HomeComponent},
            {path: 'dashboard', component: DashboardComponent},
            {path: 'contact', component: ContactComponent},
            {path: 'participants', component: ParticipantsComponent},
            {path: 'measures/:id', component: MeasuresComponent},
            {path: 'photos', component: PhotoComponent},
            {path: 'resales', component: ResalesComponent},
            {path: 'raffles', component: RafflesComponent},
            {path: 'coupons', component: CouponsComponent},
            {path: 'banners', component: BannersComponent},
            {path: 'banners/new', component: BannersDetailComponent},
            {path: 'banners/:id', component: BannersDetailComponent},
            {path: 'winners', component: WinnersComponent},
            {path: 'crop', component: CropImageComponent},
            {path: 'documentation', component: DocumentationComponent},
            {path: 'user', component: UserComponent},
            {path: 'resellers', component: ResellersComponent},
            {path: 'resellers/detail/:id', component: ResellersDetailComponent},
            {path: 'resellersFleet', component: ResellersFleetComponent},
            {path: 'resellersFleet/detail/:id', component: ResellersFleetDetailComponent},
            {path: 'resellersFleet/extra/:id', component: ResellersFleetExtraComponent},
            {path: 'management', component: ManagementComponent},
            {path: 'management/detail/:id', component: ManagementDetailComponent},
            {path: 'managementFleet', component: ManagementFleetComponent},
            {path: 'managementFleet/detail/:id', component: ManagementFleetDetailComponent},
            {path: 'managementProject', component: ManagementProjectComponent},
            {path: 'managementProject/detail/:id', component: ManagementProjectDetailComponent},
            {path: 'resellersProject', component: ResellersProjectComponent},
            {path: 'resellersProject/detail/:id', component: ResellersProjectDetailComponent},
            {path: '', component: ResellersComponent}
        ],
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
