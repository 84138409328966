<div class="page">


    <div class="page-padding">
        <div class="row">
            <div class="col-12">
                <div class="page-title mb-2">Revendas</div>
            </div>
            <div class="col-12 text-right">
                <a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
                <a href="javascript:void(0)" (click)="getExcel()" class="btn btn-sm btn-info text-white">
                    <span>Exportar Excel</span>
                </a>
            </div>
            <div class="col-12 text-right mt-2">
                <a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
                <a href="javascript:void(0)" (click)="getServiceExcel()" class="btn btn-sm btn-info text-white">
                    <span>Exportar Serviço Excel</span>
                </a>
            </div>
            <div class="col-6 col-md-3">
                <div class="project-card">
                    <h3 class="text-primary"><strong>{{standartDashboard.pending}}</strong></h3>
                    <span class="mt-2">Pendente</span>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="project-card">
                    <h3 class="text-primary"><strong>{{standartDashboard.retrieved}}</strong></h3>
                    <span class="mt-2">Devolvido</span>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="project-card">
                    <h3 class="text-primary"><strong>{{standartDashboard.received}}</strong></h3>
                    <span class="mt-2">Em Análise</span>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="project-card">
                    <h3 class="text-primary"><strong>{{standartDashboard.finished}}</strong></h3>
                    <span class="mt-2">Finalizado</span>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="page-title mb-2">Lista de Revendas</div>
            </div>
            <div class="col-12 col-lg-3">
                <div class="input-material input-management">
                    <input id="resselermeasure" [(ngModel)]="this.filter.keyword" (keyup.enter)="pageChanged(1)" placeholder="CNPJ, Razão Social ou Endereço" class="form-control" type="text"
                           required/>
                    <label for="resselermeasure" class="disabled">Buscar</label>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2">
                <div class="input-material input-management">
                    <select name="resselerMeasureSelect" id="resselerMeasureSelect" class="form-control" [(ngModel)]="filter.status">
                        <option [value]="''">Selecione</option>
                        <option value="pending">Pendente</option>
                        <option value="review">Revisar</option>
                        <option value="received">Em Análise</option>
                        <option value="approved">Aprovado</option>
                    </select>
                    <label for="resselerMeasureSelect" class="disabled">Status</label>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2">
                <div class="input-material input-management">
                    <select name="resselerUf" id="resselerUf" class="form-control" [(ngModel)]="filter.uf"
                    (ngModelChange)="getCities()">
                        <option [value]="''">Selecione</option>
                        <ng-container *ngFor="let x of arrUf; let i = index;">
                            <option [value]="x.value">{{x.value}}</option>
                        </ng-container>
                    </select>
                    <label for="resselerUf" class="disabled">Estado</label>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <div class="input-material input-management">
                    <select name="resselerCity" id="resselerCity" class="form-control" [(ngModel)]="filter.city"
                        [disabled]="arrCity.length === 0">
                        <option [value]="''">Selecione</option>
                        <ng-container *ngFor="let x of arrCity; let i = index;">
                            <option [value]="x.value">{{x.value}}</option>
                        </ng-container>
                    </select>
                    <label for="resselerCity" class="disabled">Cidade</label>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2">
                <div class="input-material input-management">
                    <select name="resselerTeam" id="resselerTeam" class="form-control" [(ngModel)]="filter.team">
                        <option [value]="''">Selecione</option>
                        <ng-container *ngFor="let x of searchTeamUserSupplier; let i = index;">
                            <option [value]="x.name">{{x.name}}</option>
                        </ng-container>
                    </select>
                    <label for="resselerTeam" class="disabled">Equipe</label>
                </div>
            </div>
            <div class="col-12 text-right">
                <div class="d-flex justify-content-end">
                    <a href="javascript:void(0)" (click)="pageChanged(1)" class="btn btn-primary btn-next-outline px-5">
                        <span>Buscar</span>
                    </a>
                    <a href="javascript:void(0)" (click)="clearFilter()"
                       *ngIf="!isNullOrUndefined(filter.keyword) || !isNullOrUndefined(filter.status)
                                || !isNullOrUndefined(filter.uf) || !isNullOrUndefined(filter.city)"
                       class="btn btn-danger btn-next-outline px-4 ml-3">
                        <span>
                            <svg-icon svgClass="icon-edit" [svgStyle]="{ 'width.px': 20, 'fill': '#ffffff' }" src="assets/svg/trash.svg"></svg-icon>
                        </span>
                    </a>
                </div>
            </div>
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table table-striped table_regular">
                        <thead>
                        <tr>
                            <th>Equipe</th>
                            <th>UF</th>
                            <th>Cidade</th>
                            <th>Endereço</th>
                            <th>Status</th>
                            <th>CNPJ</th>
                            <th>Revenda</th>
                            <!--<th class="text-center">Projeto</th>-->
                            <th>Recusa de Visita</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngIf="isNullOrUndefined(resalePage.resales)">
                            <td colspan="3">
                                <p class="text-center py-5">Nenhum registro encontrado</p>
                            </td>
                        </tr>
                        <tr class="is-cursor" *ngFor="let x of resalePage.resales|
                        paginate: {itemsPerPage: resalePage.PageSize,
                        currentPage: resalePage.currentPage,
                        totalItems: resalePage.totalCount }">
                            <td>
                                <div class="d-flex align-items-center" style="gap: 10px">
                                    <a href="javascript:void('');" *ngIf="this.isNullOrUndefined(x.standardizationJustificationVisit) && x.status !== 'approved'"
                                       (click)="openResponse(x.standartId, x.collaboratorId)">
                                        <svg-icon svgClass="icon-edit" [svgStyle]="{ 'fill': '#006F4D' }" src="assets/svg/edit.svg"></svg-icon>
                                    </a>
                                    <span>{{x.collaborator}}</span>
                                </div>
                            </td>
                            <td>
                                {{x.uf}}
                            </td>
                            <td>
                                {{x.city}}
                            </td>
                            <td>
                                {{x.address}}
                            </td>
                            <td class="text-capitalize">
                                {{x.fleetStatus | translate}}
                            </td>
                            <td>
                                {{x.cnpj}}
                            </td>
                            <td>
                                {{x.resalse}}
                            </td>
                            <!--<td class="text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <ng-container *ngIf="this.isNullOrUndefined(x.standardizationJustificationVisit) &&  x.status !== 'approved'">
                                        <a href="javascript:void('');" class="mx-2" (click)="this.searchDocument(x)"
                                        *ngIf="isNullOrUndefined(x.documents) || (x.documents[x.documents?.length - 1]?.status === 'review')">
                                            <svg-icon src="assets/svg/plus.svg"></svg-icon>
                                        </a>
                                    </ng-container>
                                    <a href="{{x.documents[x.documents?.length - 1]?.url}}" class="d-inline-block text-center mx-2" target="_blank"
                                       *ngIf="!isNullOrUndefined(x.documents[x.documents?.length - 1]?.url)">
                                        <svg-icon src="assets/svg/file-double.svg" [svgStyle]="{ 'fill': '#006F4D' }"></svg-icon><br/>
                                        <span class="text-muted text-capitalize">{{x.documents[x.documents?.length - 1]?.status | translate}}</span>
                                    </a>
                                </div>
                            </td>-->
                            <td class="text-center">
                                <a href="javascript:void('');" (click)="openModalVisit(x)" *ngIf="x.status !== 'approved'">
                                    <svg-icon class="svg svg-fill-danger" src="assets/svg/{{isNullOrUndefined(x.standardizationJustificationVisit) ? 'task-empty' : 'task-full'}}.svg" [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"></svg-icon>
                                </a>
                            </td>
                            <td>
                                <a href="javascript:void('');" [routerLink]="['detail/' + x.standartId]">
                                    <svg-icon src="assets/svg/more.svg"></svg-icon>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-right">
                    <pagination-controls [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel"
                                         [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                                         [screenReaderPageLabel]="labels.screenReaderPageLabel"
                                         [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                                         (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
            </div>
        </div>

    </div>
</div>


<div class="modal-custom modal fade" id="modalResponsible" data-keyboard="false" data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>Responsável</b>
                </h3>
                <a href="javascript:void('');" class="modal_close ml-auto" aria-label="Close" type="button"
                   (click)="closeModal('modalResponsible')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="select_row mt-3 text-primary" style="max-height: 30vh; overflow: auto;">
                                <b></b>
                                <div class="form-check text-primary d-flex align-items-center">
                                    <label class="form-check-label" for="teamEmpty">
                                        Não Distribuído
                                    </label>
                                    <input [(ngModel)]="standardizationInput.teamId"
                                           class="form-check-input select_radio" type="radio" name="flexRadioDefault"
                                           [value]="''" id="teamEmpty">
                                </div>
                                <div class="form-check text-primary d-flex align-items-center"
                                     *ngFor="let x of searchTeamUserSupplier; let i = index;">
                                    <label class="form-check-label" for="{{x.id}}">
                                        {{x.name}}
                                    </label>
                                    <input [(ngModel)]="standardizationInput.teamId"
                                           class="form-check-input select_radio" type="radio" name="flexRadioDefault"
                                           [value]="x.id" id="{{x.id}}">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button (click)="actionSelectTeam()" class="btn btn-primary btn-lg text-white w-100 mt-3">
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

