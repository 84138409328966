import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
    CreateStandartMessageGQL, CreateStandartMessageInput, CreateStandartMessageSupplierGQL,
    DeleteImageGQL,
    RemoveDocumentGQL,
    ReplyStandartMessageGQL,
    ReplyStandartMessageInput,
    SearchDocumentsGQL,
    SearchDocumentsQuery,
    StandartMessage,
    StandartMessagesGQL,
    StandartMessagesQuery,
    StandartMessagesQueryVariables,
    StandartStandardizationByIdUserSupplierGQL,
    StandartStandardizationByIdUserSupplierQuery,
    TransmitDeclineUserSupllierStandartGQL,
    TransmitUserSupllierProjectStandartGQL,
    TransmitUserSupllierStandartGQL,
    UpdateResaleHasTotemGQL,
    UpdateResaleTotemLuminousGQL,
    UpdateSwapPlateStandardGQL,
    UserDocument,
    UserStandartItemImage,
    UserSupplierStandartDetails
} from "../../../../generated/graphql";
import {BaseComponent} from "../../../base/base.component";
import {UserService} from "../../../service/user.service";
import {QueryRef} from "apollo-angular";

declare var $;

@Component({
    selector: 'app-resellers-detail',
    templateUrl: './resellers-detail.component.html',
    styleUrls: ['./resellers-detail.component.scss']
})
export class ResellersDetailComponent extends BaseComponent implements OnInit, OnDestroy {
    step = 1
    file: File = null;
    bannerBuffer: string | ArrayBuffer;
    paramId: string;
    standartDetails: UserSupplierStandartDetails = new UserSupplierStandartDetails();
    fileTerms: any[];
    documents: UserDocument[] = [];
    searchDocumentsQuery: QueryRef<SearchDocumentsQuery>;
    idUserSupplierQuery: QueryRef<StandartStandardizationByIdUserSupplierQuery>;
    currentUpload: UserStandartItemImage = new UserStandartItemImage();
    thumpPath: string | ArrayBuffer;

    modelChat: any = {id: '', name: ''};
    standartMessage: StandartMessage[] = [];
    modalMessage: StandartMessage = new StandartMessage();
    textResponse: string;
    standartMessageQuery: QueryRef<StandartMessagesQuery, StandartMessagesQueryVariables>;
    statusFront: string;
    notPaint: boolean = false;
    transmitDecline: boolean = false;
    transmitDeclineFlag: boolean = false;
    modelJustifyTemp = '';
    modelDimensionTemp: any = null;

    userComment = '';

    constructor(public router: Router,
                public activatedRoute: ActivatedRoute,
                public loading: NgxSpinnerService,
                public idUserSupplierGQL: StandartStandardizationByIdUserSupplierGQL,
                public removeDocumentGQL: RemoveDocumentGQL,
                public userService: UserService,
                private searchDocumentsGQL: SearchDocumentsGQL,
                private transmitUserSupllierStandartGQL: TransmitUserSupllierStandartGQL,
                private standartMessagesGQL: StandartMessagesGQL,
                private replyStandartMessageGQL: ReplyStandartMessageGQL,
                private updateSwapPlateStandardGQL: UpdateSwapPlateStandardGQL,
                private updateResaleHasTotemGQL: UpdateResaleHasTotemGQL,
                private updateResaleTotemLuminousGQL: UpdateResaleTotemLuminousGQL,
                private deleteImageGQL: DeleteImageGQL,
                private transmitDeclineUserSupllierStandartGQL: TransmitDeclineUserSupllierStandartGQL,
                private transmitUserSupllierProjectStandartGQL: TransmitUserSupllierProjectStandartGQL,
                public createStandartMessageSupplierGQL: CreateStandartMessageSupplierGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(url => {
            if (!this.isNullOrUndefined(url?.id)) {
                this.paramId = url.id;
                this.idUserSupplierQuery = this.idUserSupplierGQL.watch({id: this.paramId});
                this.idUserSupplierQuery.valueChanges.subscribe(({data}) => {
                    this.standartDetails = data.standartStandardizationByIdUserSupplier as UserSupplierStandartDetails;
                    this.standartDetails.document = this.standartDetails.document?.filter(x => x.type === 'project');
                    if (!this.isNullOrUndefined(this.standartDetails.standardizationJustification)) {
                        this.transmitDecline = true;
                        this.transmitDeclineFlag = true;
                    }
                    if (!this.isNullOrUndefined(this.standartDetails.standardizationJustification) || (this.standartDetails.amountMetersPaint === 0 && (this.standartDetails.frontStatus !== 'pending' && this.standartDetails.frontStatus !== 'review'))) {
                        this.notPaint = true
                    }
                    if (!this.isNullOrUndefined(this.modelJustifyTemp)) {
                        this.standartDetails.standardizationJustification = this.modelJustifyTemp;
                        this.modelJustifyTemp = '';
                    }
                    if (this.isNullOrUndefined(this.standartDetails.amountMetersPaint)) {
                        this.standartDetails.amountMetersPaint = 0;
                    }
                    if (!this.isNullOrUndefined(this.modelDimensionTemp)) {
                        this.standartDetails.amountMetersPaint = this.modelDimensionTemp;
                        this.modelDimensionTemp = '';
                    }
                    this.searchDocument();
                    this.statusFront = this.handlerStatusPhoto();
                    this.modelChat.id = this.standartDetails.id;
                    this.modelChat.name = 'standardization';
                    this.getMessages();
                }, error => super.onError(error));
            }
        });
        /*this.userService.chat.subscribe({
            next: data => {
                this.modelChat = data;
                if (!this.isNullOrUndefined(this.modelChat.id)) {
                    this.getMessages();
                }
            }, error: err => super.onError(err)
        });*/
    }

    ngOnDestroy(): void {
        super.destroyModal('modalComments');
        super.destroyModal('modalFinish');
    }

    setCroppedImage(o: any) {
        this.bannerBuffer = o.thumb;
        this.handleUpload(o.file);
    }

    handlerSwapPlate() {
        console.log(this.standartDetails.isSwapPlate);
        this.updateSwapPlateStandardGQL.mutate({
            isSwapPlate: this.standartDetails.isSwapPlate,
            standardizationId: this.standartDetails.id
        }).subscribe(({data}) => {
            // this.idUserSupplierQuery.refetch({id: this.paramId}).then();
        }, error => super.onError(error))
    }

    actionTransmitDecline() {
        const frontWall = [];
        this.standartDetails.item.find((e) => e.type == 'front-wall').images.forEach((e) => {
            if (!this.isNullOrUndefined(e.url)) frontWall.push(e.status);
        });
        if (this.isNullOrUndefined(frontWall)) {
            return this.showMessage('Atenção!', 'Adicione pelo menos uma foto na padronização.', 'warning');
        }
        if (this.isNullOrUndefined(this.standartDetails.standardizationJustification)) {
            return this.showMessage('Atenção!', 'Justificativa obrigatória.', 'warning');
        }
        this.loading.show();
        this.transmitDeclineUserSupllierStandartGQL.mutate({
            id: this.standartDetails.id,
            justification: this.standartDetails.standardizationJustification
        }).subscribe(({data}) => {
            this.loading.hide();
            this.createMessageSupplier('RECUSO DA PADRONIZAÇÃO ENVIADA COM SUCESSO!', 'Todas as informações foram enviadas para análise e conferência do auditor Copa Energia.');
        }, error => super.onError(error));
    }

    handleUpload(event) {
        const files = event;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage(this.translate.instant('profile.edit.warningAttention'),
                    this.translate.instant('profile.edit.warningUpload'), 'info');
                return;
            }
            if (files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage('Atenção', 'É permitido apenas enviar arquivos com extensões .jpg ou .png',
                    'warning');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.thumpPath = _event.target.result;
        };
        reader.readAsDataURL(event[0]);
        this.file = files[0];
        this.uploadImage();
    }

    actionUploadImage(p: UserStandartItemImage) {
        if (this.standartDetails.frontStatus === 'received' || this.standartDetails.frontStatus === 'approved' || p.status === 'approved') {
            return;
        }
        this.currentUpload = p;
        this.showModal('modalCropResseler');
    }

    uploadImage() {
        this.userService.uploadImage(this.file, this.standartDetails.id, this.currentUpload.id).subscribe(
            data => {
                console.log('justificativa temporario standartDetails.standardizationJustification');
                this.modelJustifyTemp = this.standartDetails.standardizationJustification;
                this.modelDimensionTemp = this.standartDetails.amountMetersPaint;
                this.idUserSupplierQuery.refetch({id: this.paramId}).then();
            }, error => super.onError(error));
    }

    finish() {
        // if ((this.isNullOrUndefined(this.standartDetails.amountMetersPaint) || this.standartDetails.amountMetersPaint == 0) && !this.notPaint) {
        //     return this.showMessage('Atenção!', 'Medidas obrigatório', 'warning');
        // }
        if (!this.standartDetails.isSwapPlate && this.isNullOrUndefined(this.standartDetails.amountMetersPaint)) {
            return this.showMessage('Atenção!', 'Informe a quantidade em metros quadrados pintados no local.', 'warning');
        }
        if (this.documents.length < 1) {
            return this.showMessage('Atenção!', 'Upload de documento obrigatório', 'warning');
        }
        this.loading.show();

        if ((this.standartDetails.frontStatus === 'received' || this.standartDetails.frontStatus === 'approved')) {
            this.finishProject();
            return;
        }

        this.transmitUserSupllierStandartGQL.mutate({
            id: this.standartDetails.id,
            type: 'front',
            amountMetersPainted: this.standartDetails.amountMetersPaint
        }).subscribe(({data}) => {
            this.loading.hide();
            // this.showModal('modalFinish');
            if (this.standartDetails.isProject && (this.standartDetails.projectStatus !== 'received' && this.standartDetails.projectStatus !== 'approved')) {
                this.finishProject();
            } else {
                this.onMessageFinish();
            }
        }, error => super.onError(error));
    }

    finishProject() {
        this.transmitUserSupllierProjectStandartGQL.mutate({
            input: this.standartDetails.id,
        }).subscribe(({data}) => {
            this.loading.hide();
            this.onMessageFinish();
        }, error => super.onError(error));
    }

    createMessageSupplier(title: string, msg: string) {
        const elFiltered = this.standartDetails.item.filter( x => x.type === 'front-wall')[0];
        const modelCreateMessage: CreateStandartMessageInput = new CreateStandartMessageInput();
        modelCreateMessage.standart = {id: ''};
        modelCreateMessage.message = this.userComment;
        modelCreateMessage.type = elFiltered.type;
        modelCreateMessage.title = this.getItemNameByType(elFiltered.type) + ' #' + elFiltered.id.substring(0, 4);
        modelCreateMessage.standart.id = this.standartDetails.id;
        this.createStandartMessageSupplierGQL.mutate({input: modelCreateMessage}).subscribe(({data}) => {
            this.onFinish(title, msg);
        }, error => super.onError(error));
    }

    onMessageFinish() {
        if (!this.isNullOrUndefined(this.userComment)) {
            this.createMessageSupplier('PADRONIZAÇÃO ENVIADA COM SUCESSO!', 'Todas as informações foram enviadas para análise e conferência do auditor Copa Energia.');
        } else {
            this.onFinish();
        }
    }

    onFinish(title?: string, msg?: string) {
        setTimeout(() => {
            this.showMessage(title ?? 'PADRONIZAÇÃO ENVIADA COM SUCESSO!', msg ?? 'Todas as informações foram enviadas para análise e conferência do auditor Copa Energia.', 'success');
        });
        this.router.navigate(['/resellers']);
    }

    searchDocument() {
        this.searchDocumentsQuery = this.searchDocumentsGQL.watch({
            id: this.standartDetails.user.id,
            type: 'authorization'
        });
        this.searchDocumentsQuery.valueChanges.subscribe(({data}) => {
            this.documents = data.searchDocuments as UserDocument[];
        }, error => super.onError(error));
    }

    uploadTerms(event: any) {
        this.fileTerms = event.target.files;
        if (this.documents.length >= 1) {
            this.documents.forEach((e) => {
                this.removeDocumentGQL.mutate({id: e.id}).subscribe();
            });
        }
        this.userService.uploadFile(this.fileTerms[0], 'user-document', `${this.standartDetails.user.id}/authorization`).subscribe(({data}) => {
            this.fileTerms = [];
            this.searchDocumentsQuery.refetch({
                id: this.standartDetails.user.id,
                type: 'authorization'
            }).then();
            this.showMessage('Sucesso!', 'Termo enviada com sucesso!', 'success');
        }, error => super.onError(error))
    }

    handlerResponse(m: StandartMessage) {
        this.modalMessage = Object.assign({}, m);
        this.textResponse = undefined;
        super.showModal('modalComments');
    }

    onCloseComment() {
        $('.comment').toggleClass('is-closed');
    }

    getMessages() {
        this.standartMessageQuery = this.standartMessagesGQL.watch({id: this.modelChat.id});
        this.standartMessageQuery.valueChanges.subscribe(({data}) => {
            this.standartMessage = data.standartMessages as StandartMessage[];
        });
    }

    actionResponse() {
        const arrValidateFields = [
            {value: this.textResponse, text: `Mensagem <br>`}
        ];
        const stringError = this.validateFieldZero(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente:<br/><br/> ${stringError}`);
            return;
        }
        const input: ReplyStandartMessageInput = {
            id: this.modalMessage.id,
            reply: this.textResponse,
        }
        this.loading.show();
        this.replyStandartMessageGQL.mutate({input: input}).subscribe(({data}) => {
            super.closeModal('modalComments');
            this.standartMessageQuery.refetch({id: this.modelChat?.id});
            this.loading.hide();
            this.showMessage('Sucesso!', 'Resposta enviada com sucesso!', 'success');
        }, error => super.onError(error));
    }

    handlerViewMessage(step: number) {
        switch (step) {
            case 1:
                return this.standartMessage.filter((e) => e.type == 'front-wall');
            case 2:
                return this.standartMessage.filter((e) => e.type == 'dimension');
            case 3:
                return this.standartMessage.filter((e) => e.type == 'documentAuthorization');
            default:
                return this.standartMessage;
        }
    }

    handlerStatusPhoto() {
        let statusArray = [];
        const frontWall = this.standartDetails.item.find((e) => e.type == 'front-wall');
        frontWall.images.forEach((e) => {
            if (!this.isNullOrUndefined(e.url)) {
                statusArray.push(e.status);
            }
        });
        let status = statusArray.find((e) => e == 'review');
        if (this.isNullOrUndefined(status)) {
            status = statusArray.find((e) => e == 'approved')
        }
        return status;
    }

    handlerStep(step: number) {
        window.scrollTo({top: 0, behavior: 'smooth'})
        this.step = step
    }

    handlerButton() {
        let visible = true;
        if (this.standartDetails.frontStatus === 'received' || this.standartDetails.frontStatus === 'approved') {
            if (this.transmitDecline) {
                visible = false
            }
        }
        return visible
    }

    getStatusEditView(w: any) {
        return w.status === 'pending' || w.status === 'review';
    }

    actionRemovePhoto(p: UserStandartItemImage) {
        super.confirmMessage('Atenção', 'Deseja realmente remover a foto?', () => {
            this.onRemovePhoto(p.id);
        });
    }

    onRemovePhoto(id: string) {
        this.deleteImageGQL.mutate({input: id}).subscribe(
            (data) => {
                this.idUserSupplierQuery.refetch({id: this.paramId}).then();
            }, err => super.onError(err)
        );
    }

    getStatusUserStandardFront() {
        return this.standartDetails.frontStatus;
    }

    setTotem(value: boolean) {
        this.updateResaleHasTotemGQL.mutate({input: this.standartDetails.isHasTotem, data: this.standartDetails.id}).subscribe(
            (data) => {
                console.log('alterado com sucesso!');
            }, err => super.onError(err)
        );
    }

    setTotemLuminous(value: boolean) {
        console.log('setTotemLuminous');
        console.log(value);
        console.log(this.standartDetails.isTotemLuminous);
        this.updateResaleTotemLuminousGQL.mutate({input: this.standartDetails.isTotemLuminous, data: this.standartDetails.id}).subscribe(
            (data) => {
                console.log('alterado com sucesso!');
            }, err => super.onError(err)
        );
    }
}
