import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {
  FindAllUSerSupplierCityGQL,
  FindAllUSerSupplierCityQuery,
  FindAllUSerSupplierUfGQL,
  FindAllUSerSupplierUfQuery,
  RemoveDocumentGQL,
  ResaleUserSupplier,
  ResaleUserSupplierPage,
  SearchDocumentsGQL,
  SearchDocumentsQuery,
  SearchResaleUserSupplierGQL,
  SearchResaleUserSupplierInput, SearchResaleUserSupplierProjectGQL, SearchResaleUserSupplierProjectQuery,
  SearchResaleUserSupplierQuery,
  SearchTeamUserSupplier,
  SearchTeamUserSupplierGQL,
  StandartDashboard,
  StandartDashboardUserSupplierGQL,
  StandartDashboardUserSupplierProjectGQL,
  UpdateRecuseVisitStandardGQL,
  UpdateTeamStandardizationInput,
  UpdateTeamStandardizationUserSupplierGQL, UpdateTeamStandardizationUserSupplierProjectGQL,
  UserDocument,
  UserSupplier
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {ExcelService} from '../../service/excel.service';

@Component({
  selector: 'app-management-project',
  templateUrl: './management-project.component.html',
  styleUrls: ['./management-project.component.scss']
})
export class ManagementProjectComponent extends BaseComponent implements OnInit, OnDestroy {

  user: UserSupplier = new UserSupplier();
  resalePage: ResaleUserSupplierPage = new ResaleUserSupplierPage();
  filter: SearchResaleUserSupplierInput = new SearchResaleUserSupplierInput();
  searchResaleUserSupplierQuery: QueryRef<SearchResaleUserSupplierProjectQuery>;
  standartDashboard: StandartDashboard = new StandartDashboard();
  searchTeamUserSupplier: SearchTeamUserSupplier[] = [];
  standardizationInput: UpdateTeamStandardizationInput = new UpdateTeamStandardizationInput();
  searchDocumentsQuery: QueryRef<SearchDocumentsQuery>;

  arrUf = [];
  arrCity = [];
  listUfQuery: QueryRef<FindAllUSerSupplierUfQuery>;
  listCityQuery: QueryRef<FindAllUSerSupplierCityQuery>;

  fileTerms: any[];
  documents: UserDocument[] = [];
  resaleUserSupplier: ResaleUserSupplier = new ResaleUserSupplier();

  justifyVisitElement: ResaleUserSupplier = {};
  justifyVisit = '';

  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public translate: TranslateService,
              public userService: UserService,
              private searchDocumentsGQL: SearchDocumentsGQL,
              public removeDocumentGQL: RemoveDocumentGQL,
              public searchResaleUserSupplierProjectGQL: SearchResaleUserSupplierProjectGQL,
              public findAllUSerSupplierUfGQL: FindAllUSerSupplierUfGQL,
              public findAllUSerSupplierCityGQL: FindAllUSerSupplierCityGQL,
              public searchTeamUserSupplierGQL: SearchTeamUserSupplierGQL,
              public updateTeamStandardizationUserSupplierProjectGQL: UpdateTeamStandardizationUserSupplierProjectGQL,
              public standartDashboardUserSupplierProjectGQL: StandartDashboardUserSupplierProjectGQL,
              public updateRecuseVisitStandardGQL: UpdateRecuseVisitStandardGQL,
              public excelService: ExcelService
  ) {
    super(router, loading, translate);
  }

  ngOnInit(): void {

    this.userService.user.subscribe({
      next: dataUser => {
        if (!this.isNullOrUndefined(dataUser.id)) {
          if (dataUser.profile !== 'admin' || (dataUser.type !== 'projects' && dataUser.type !== 'front-wall')) {
            this.router.navigate(['/login']).then();
          }
          console.log('aqui');
          console.log(dataUser.type);
          /*if (this.user.type === 'fleet') {
            this.router.navigate(['/managementFleet']);
          }*/
          this.user = dataUser as UserSupplier;

          this.filter.keyword = '';
          this.filter.status = '';
          this.filter.uf = '';
          this.filter.city = '';
          this.filter.team = '';
          this.filter.type = this.userService.getUserLocal().type;

          this.findAllUSerSupplierUfGQL.watch().valueChanges.subscribe(({data}) => {
            this.arrUf = data.findAllUSerSupplierUf;
          }, error => super.onError(error));

          this.listCityQuery = this.findAllUSerSupplierCityGQL.watch({uf: this.filter.uf});
          this.listCityQuery.valueChanges.subscribe(({data}) => {
            this.arrCity = data.findAllUSerSupplierCity.filter(x => !this.isNullOrUndefined(x.value));
          }, error => super.onError(error));

          this.standartDashboardUserSupplierProjectGQL.watch().valueChanges.subscribe(({data}) => {
            this.standartDashboard = data.standartDashboardUserSupplierProject as StandartDashboard;
          }, error => super.onError(error));

          this.searchResaleUserSupplierQuery = this.searchResaleUserSupplierProjectGQL.watch({input: this.filter});
          this.searchResaleUserSupplierQuery.valueChanges.subscribe(({data}) => {
            this.resalePage = data.searchResaleUserSupplierProject as ResaleUserSupplierPage;
          }, error => super.onError(error));

          this.searchTeamUserSupplierGQL.watch({input: this.userService.getUserLocal().type}).valueChanges.subscribe(({data}) => {
            this.searchTeamUserSupplier = data.searchTeamUserSupplier as SearchTeamUserSupplier[];
          }, error => super.onError(error));
        }
      }
    });

  }

  ngOnDestroy(): void {
    super.destroyModal('modalResponsible');
    super.destroyModal('modalResaleProject');
    super.destroyModal('modalResaleJustifyVisit');
  }

  pageChanged(page: number) {
    this.filter.page = page;
    this.searchResaleUserSupplierQuery.refetch({input: this.filter}).then();
  }

  actionSelectTeam() {
    this.updateTeamStandardizationUserSupplierProjectGQL.mutate({input: this.standardizationInput}).subscribe(({data}) => {
      this.closeModal('modalResponsible');
      super.showMessage('Sucesso', 'Equipe atribuída com sucesso');
      this.searchResaleUserSupplierQuery.refetch({input: this.filter}).then();
    }, error => super.onError(error));
  }

  openResponse(id: string, teamId: string) {
    this.standardizationInput = new UpdateTeamStandardizationInput();
    this.standardizationInput.teamId = teamId;
    this.standardizationInput.standartizationId = id;
    this.showModal('modalResponsible');
  }


  uploadDocument(event: any) {
    this.fileTerms = event.target.files;
    if (this.documents.length >= 1) {
      this.documents.forEach((e) => {
        this.removeDocumentGQL.mutate({id: e.id}).subscribe();
      });
    }
    this.userService.uploadFile(
        this.fileTerms[0],
        'user-supplier-document',
        `${this.resaleUserSupplier.resaleId}/project/${this.resaleUserSupplier.collaboratorId}`)
        .subscribe(({data}) => {
          this.fileTerms = [];
          this.closeModal('modalResaleProject');
          this.showMessage('Sucesso!', 'Projeto enviado com sucesso!', 'success');
        }, error => super.onError(error));
  }

  searchDocument(resale: ResaleUserSupplier) {
    this.resaleUserSupplier = resale;
    this.searchDocumentsQuery = this.searchDocumentsGQL.watch({id: resale.resaleId, type: 'project'});
    this.searchDocumentsQuery.valueChanges.subscribe(({data}) => {
      this.documents = data.searchDocuments as UserDocument[];
      this.showModal('modalResaleProject');
    }, error => super.onError(error));
  }

  getCities() {
    this.listCityQuery.refetch({uf: this.filter.uf}).then();
  }

  getExcel() {
    this.excelService.getExcelSupplierProject().subscribe({
      next: data => {
        this.downloadHandlerFile(data, 'Revendas');
      }, error: err => super.onError(err)
    });
  }

  clearFilter() {
    this.filter.keyword = '';
    this.filter.status = '';
    this.filter.uf = '';
    this.filter.city = '';
    this.filter.team = '';
    this.filter.page = 1;
    this.searchResaleUserSupplierQuery.refetch({input: this.filter}).then();

  }

  onSaveJustifyVisit() {
    if (this.isNullOrUndefined(this.justifyVisit)) {
      this.showMessage('Atenção', `Preencha a justificava para prosseguir!`, 'warning');
      return;
    }
    this.confirmMessage('Atenção', 'Tem certeza que deseja prosseguir com a rescusa da visita?', () => {
      console.log('vamos enviar a justificativa');
      this.closeModal('modalResaleJustifyVisit');
      this.setSaveJustifyVisit();
    });
  }

  setSaveJustifyVisit() {
    console.log('setSaveJustifyVisit');
    this.updateRecuseVisitStandardGQL.mutate({input: this.justifyVisitElement.standartId, data: this.justifyVisit}).subscribe(
        ({data}) => {
          console.log('onLogin');
          this.searchResaleUserSupplierQuery.refetch({input: this.filter}).then();
          this.showMessage('Sucesso!', `A recusa da visita foi realizada.`, 'success');
        }, (err) => super.onError(err)
    );
  }

  openModalVisit(x: ResaleUserSupplier) {
    this.justifyVisitElement = x;
    this.justifyVisit = !this.isNullOrUndefined(x.standardizationJustificationVisit) ? x.standardizationJustificationVisit : '';
    this.showModal('modalResaleJustifyVisit');
  }
}
