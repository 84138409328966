import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {User, UserAdmin, UserSupplier} from "../../../generated/graphql";

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit, AfterViewInit {
    user: UserSupplier;
    navBarShort = false;
    btnNew = false;

    constructor(public router: Router,
                public translate: TranslateService,
                public loading: NgxSpinnerService,
                public userService: UserService) {

        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.handlerCollapse();
        this.userService.user.subscribe({
            next: data => {
                this.user = data as UserSupplier;
            }, error: err => super.onError(err)
        });
    }

    ngAfterViewInit(): void {
        this.navActive();
    }

    handlerCollapse() {
        $('.navbar-nav .nav-item').click(function () {
            $('.navbar-nav .nav-item').removeClass('active');
            $(this).addClass('active');
        });
    }

    actionCloseCollapse() {
        this.actionSideBar();
        $('#idea').collapse('hide');
        $('#clipboard').collapse('hide');
        $('#setting').collapse('hide');
        $('body').removeClass('nav-open');
        $('.hamburger').removeClass('open');
    }

    navActive() {
        if (this.router.url === '/calibration' || this.router.url === '/engines') {
            return this.handlerNavActive('.report-ts');
        }
        if (this.router.url === '/training' || this.router.url === '/faq') {
            return this.handlerNavActive('.help-ts');
        }
    }

    handlerNavActive(el) {
        $('.navbar-nav .nav-item').removeClass('active');
        $('.navbar-nav .nav-link').removeClass('text-white');
        $(el).addClass('active');
        $(el + ' .nav-link').addClass('text-white');

    }

    actionSideBar() {
        this.navBarShort = !this.navBarShort;
    }

    handlerSideBarNav() {
        return this.user.profile === 'admin';
    }
}
