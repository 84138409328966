import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {
    AmountStandardzationtUserSupplier, FindAllUSerSupplierCityGQL,
    FindAllUSerSupplierCityQuery, FindAllUSerSupplierUfGQL,
    FindAllUSerSupplierUfQuery,
    ResaleUserSupplier, SearchAmountStandardzationGQL,
    SearchResaleUserSupplierInput,
    SearchTeamUserSupplier, SearchTeamUserSupplierGQL,
    StandartStandardizationUserSupplierGQL,
    StandartStandardizationUserSupplierQuery,
    UserSupplier,
    UserSupplierStandart,
    UserSupplierStandartPage
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";

@Component({
    selector: 'app-resellers-project',
    templateUrl: './resellers-project.component.html',
    styleUrls: ['./resellers-project.component.scss']
})
export class ResellersProjectComponent extends BaseComponent implements OnInit {

    user: UserSupplier = new UserSupplier();
    userSupplier: UserSupplierStandart[] = [];
    userSupplierPage: UserSupplierStandartPage = new UserSupplierStandartPage();
    paramId: string;
    filter: SearchResaleUserSupplierInput = new SearchResaleUserSupplierInput();
    searchUserSupplierQuery: QueryRef<StandartStandardizationUserSupplierQuery>;
    amountStandartzation: AmountStandardzationtUserSupplier = new AmountStandardzationtUserSupplier();

    arrUf = [];
    arrCity = [];
    listUfQuery: QueryRef<FindAllUSerSupplierUfQuery>;
    listCityQuery: QueryRef<FindAllUSerSupplierCityQuery>;
    searchTeamUserSupplier: SearchTeamUserSupplier[] = [];
    resaleUserSupplier: ResaleUserSupplier = new ResaleUserSupplier();

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userSupplierGQL: StandartStandardizationUserSupplierGQL,
                public userService: UserService,
                public searchAmountStandartzation: SearchAmountStandardzationGQL,
                public findAllUSerSupplierUfGQL: FindAllUSerSupplierUfGQL,
                public findAllUSerSupplierCityGQL: FindAllUSerSupplierCityGQL,
                public searchTeamUserSupplierGQL: SearchTeamUserSupplierGQL
    ) {
        super(router, loading, translate)
    }

    ngOnInit(): void {
        this.userService.user.subscribe((dataUser) => {
            if (!this.isNullOrUndefined(dataUser.id)) {
                this.user = dataUser as UserSupplier;
                if (dataUser.profile !== 'user' || dataUser.type !== 'projects') {
                    this.router.navigate(['/login']).then();
                }
                this.paramId = dataUser.id;
                this.filter.keyword = '';
                this.filter.status = '';
                this.filter.uf = '';
                this.filter.city = '';
                this.filter.team = this.user.name;
                this.filter.type = this.user.type;
                this.filter.page = 1;

                this.findAllUSerSupplierUfGQL.watch().valueChanges.subscribe(({data}) => {
                    this.arrUf = data.findAllUSerSupplierUf;
                }, error => super.onError(error));

                this.searchUserSupplierQuery = this.userSupplierGQL.watch({id: this.paramId, filter: this.filter});
                this.searchUserSupplierQuery.valueChanges.subscribe(({data}) => {
                    console.log(data);
                    this.userSupplierPage = data.standartStandardizationUserSupplier as UserSupplierStandartPage;
                    this.userSupplier = this.userSupplierPage.resales as UserSupplierStandart[];
                }, error => super.onError(error));

                this.searchAmountStandartzation.watch().valueChanges.subscribe(({data}) => {
                    this.amountStandartzation = data.searchAmountStandardzation as AmountStandardzationtUserSupplier;
                }, error => super.onError(error));

            }
        }, error => super.onError(error));
    }

    pageChanged(page: number) {
        this.filter.page = page;
        this.searchUserSupplierQuery.refetch({id: this.paramId, filter: this.filter}).then();
    }

    getCities() {
        this.listCityQuery = this.findAllUSerSupplierCityGQL.watch({uf: this.filter.uf});
        this.listCityQuery.valueChanges.subscribe(({data}) => {
            this.arrCity = data.findAllUSerSupplierCity.filter(x => !this.isNullOrUndefined(x.value));
        }, error => super.onError(error));
    }

    clearFilter() {
        this.filter.keyword = '';
        this.filter.status = '';
        this.filter.uf = '';
        this.filter.city = '';
        this.filter.team = this.user.name;
        this.filter.page = 1;
        this.searchUserSupplierQuery.refetch({id: this.paramId, filter: this.filter}).then();
    }

}
