<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <div class="d-flex align-items-center">
                <svg-icon [routerLink]="['/managementFleet']" class="svgBack is-cursor"
                          src="assets/svg/backgreen.svg"></svg-icon>
                <h1 class="page-title">Revenda</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <span class="badge mr-2 mb-2 text-capitalize"
                      [ngClass]="{'badge-info' : standartDetails.fleetStatus === 'pending',
                                          'badge-warning' : standartDetails.fleetStatus === 'received',
                                          'badge-danger' : standartDetails.fleetStatus === 'review',
                                          'badge-success' : standartDetails.fleetStatus === 'approved'}">{{standartDetails.fleetStatus | translate}}</span>
                <strong class="text-primary">{{standartDetails.companyName}}</strong><br/>
                <span class="text-primary">Tel: <strong>{{isNullOrUndefined(standartDetails.phone) ? 'Não informado' : standartDetails.phone}}</strong></span>
                <div class="text-primary">
                    CNPJ: <strong>{{standartDetails.cnpj}}</strong><br/>
                    <strong></strong><br>
                    <strong></strong>
                </div>
            </div>

            <div class="col-12 d-md-none mt-2">
                <hr/>
            </div>

            <div class="col-12 col-md-6 text-md-right text-primary">
                <div class="d-flex justify-content-md-end">
                    <svg-icon class="mr-3" src="assets/svg/local.svg"></svg-icon>
                    <span>
                        <strong class="text-primary">{{standartDetails.uf}} - {{standartDetails.city}} | </strong>
                        <strong class="text-primary">{{standartDetails.cep}} | {{standartDetails.district}}</strong><br>
                        <strong class="text-primary">{{standartDetails.address}}</strong>
                    </span>
                </div>
            </div>

            <!--
            <div class="col-12">
                <div class="management-card">
                    <div class="management-card-top">
                        <span class="mr-2">
                            <span class="badge mr-2 mb-2 text-capitalize"
                                  [ngClass]="{'badge-info' : standartDetails.status === 'pending',
                                          'badge-warning' : standartDetails.status === 'received',
                                          'badge-danger' : standartDetails.status === 'review',
                                          'badge-success' : standartDetails.status === 'approved'}">{{standartDetails.status | translate}}</span>
                            <strong>{{standartDetails.companyName}}</strong>
                        </span>
                        <span class="phoneSize">{{standartDetails.phone}}</span>
                    </div>
                    <div class="management-card-middle">
                        <svg-icon class="mr-3" src="assets/svg/local.svg"></svg-icon>
                        <span>
                            <span>{{standartDetails.uf}} - {{standartDetails.city}}</span> |
                            <span>{{standartDetails.cep}} | {{standartDetails.district}}</span><br>
                            <span>{{standartDetails.address}}</span>
                        </span>
                    </div>
                </div>
            </div>-->

            <div class="col-12">
                <div class="responsible-card mt-4">
                    <div class="responsible-card-top">
                        <span class="text-primary"><strong>Responsável</strong></span>
                    </div>
                    <div class="responsible-card-middle">
                        <ng-container *ngIf="isNullOrUndefined(standartDetails.collaborator)">
                            Nenhuma equipe
                        </ng-container>
                        <ng-container *ngIf="!isNullOrUndefined(standartDetails.collaborator)">
                            <div class="d-flex align-items-center">
                                <svg-icon class="mr-2" src="assets/svg/user.svg"></svg-icon>
                                <span><strong>{{standartDetails.collaborator}}</strong></span>
                            </div>
                            <div *ngIf="!isNullOrUndefined(standartDetails.dateRealization)"
                                 class="d-flex flex-column align-items-end">
                                <span class="smalltxt">Data da realização</span>
                                <span
                                        class="smalltxt"><strong>{{standartDetails.dateRealization|date:'dd/MM/yyyy'}}</strong></span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-12">
                <div class="card-end flex-column align-items-stretch">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex justify-content-between flex-grow-1">
                                <strong class="text-primary pt-2"> Padronização Realizada</strong>
                                <div class="card-end-middle">
                                    <h3 class="text-primary">
                                        <strong>{{isNullOrUndefined(standartDetails.isStandardizationPerformed) ? 'Não' : 'Sim'}}</strong>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <hr/>
                        </div>

                        <!--<div class="col-12 col-md-6" *ngIf="standartDetails.isStandardizationPerformed">
                            <div class="d-flex justify-content-between flex-grow-1">
                                <span class="text-primary pt-2">
                                    <strong>Padronização Realizada Por:</strong> <br /> {{standartDetails.standardizationPerformedBy}}
                                </span>
                            </div>
                        </div>-->
                        <div class="col-12 col-md-12" *ngIf="standartDetails.isStandardizationPerformed">
                            <div class="d-flex justify-content-between flex-grow-1">
                                <span class="text-primary pt-2">
                                    <strong>Data do Serviço:</strong> <br /> {{standartDetails.fleetServiceAt | date: 'dd/MM/yyyy'}}
                                </span>
                            </div>
                        </div>

                        <div class="col-12" *ngIf="!isNullOrUndefined(standartDetails.justificationFleetVisit)">
                            <div class="d-flex justify-content-between flex-grow-1">
                                <span class="text-primary pt-2">
                                    <strong>Motivo:</strong> <br /> {{standartDetails.justificationFleetVisit}}
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <ng-container
                    *ngIf="isNullOrUndefined(standartDetails.standardizationJustification) && isNullOrUndefined(standartDetails.standardizationJustificationVisit)">
                <!--<div class="col-12 col-md-6">
                    <div class="card-end" style="min-height: 100px;">
                        <strong class="text-primary"> Total em m² </strong>
                        <div class="card-end-middle" *ngIf="isNullOrUndefined(standartDetails.amountMetersPaint)">
                            <span>Não informado</span>
                        </div>
                        <div class="card-end-middle" *ngIf="!isNullOrUndefined(standartDetails.amountMetersPaint)">
                            <h3 class="text-primary"><strong>{{standartDetails.amountMetersPaint}}</strong><strong
                                    class="m2">m²</strong></h3>
                        </div>
                    </div>
                </div>-->
                <div class="col-12 col-md-6">
                    <div class="card-end" style="min-height: 100px;">
                        <strong class="text-primary"> Termo de Garantia </strong>
                        <div class="card-end-middle" *ngIf="isNullOrUndefined(documentsWarranty)">
                            <p>Não enviado</p>
                        </div>
                        <ng-container *ngIf="!isNullOrUndefined(documentsWarranty)">
                            <div class="d-flex justify-content-end">
                                <div class="card-end-middle mx-2" *ngFor="let x of documentsWarranty">
                                    <a [href]="x.url" target="_blank" class="card-end">
                                        <svg-icon src="assets/svg/term.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card-end" style="min-height: 100px;">
                        <strong class="text-primary"> Termo de Resposabilidade </strong>
                        <div class="card-end-middle" *ngIf="isNullOrUndefined(documents)">
                            <p>Não enviado</p>
                        </div>
                        <ng-container *ngIf="!isNullOrUndefined(documents)">
                            <div class="d-flex justify-content-end">
                                <div class="card-end-middle mx-2" *ngFor="let x of documents">
                                    <a [href]="x.url" target="_blank" class="card-end">
                                        <svg-icon src="assets/svg/term.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <div class="col-12 mt-4">

                <div class="page-title mt-4">Adesão</div>
                <p class="text-center py-5" *ngIf="standartDetails.itemAdhesion.length < 2">Nenhum item registrado</p>
                <div *ngFor="let x of standartDetails.itemAdhesion; let idx = index; let od = odd;" class="mb-4 p-3"
                     [ngClass]="{ odd: od}">
                    <ng-container *ngIf="x.type !== 'front-wall'">
                        <div class="d-flex justify-content-between align-items-center">
                            <h2 class="page-title mb-2">
                                {{getItemNameByType(x.type)}}  <small>#{{x?.id.substring(0, 4)}}</small>
                                <!--<br/><small>Placa XXXXXX</small>-->
                            </h2>
                            <a href="javascript:void('');" (click)="viewServices(x)" class="btn btn-primary">Serviços</a>
                        </div>

                        <div class="row">
                            <div class="col-6 col-md-3 col-lg-6 col-xxl-3" *ngFor="let img of x?.images">
                                <div class="position-relative">
                                    <div *ngIf="isNullOrUndefined(img.url)" class="addphoto">
                                        <svg-icon src="assets/svg/addimage.svg"></svg-icon>
                                        <strong class="text-primary">{{img.name}}</strong>
                                    </div>
                                    <img *ngIf="!isNullOrUndefined(img.url)"
                                         class="addphoto" src="{{img.url}}" alt="{{img.name}}">
                                    <span *ngIf="!isNullOrUndefined(img.url)" style="position:absolute; right: 0; top: -24px;">
                                            <svg-icon src="assets/svg/close.svg" class="svg-danger"
                                                      *ngIf="img.status === 'review'"></svg-icon>
                                            <svg-icon src="assets/svg/check.svg" class="svg-primary"
                                                      *ngIf="img.status === 'approved'"></svg-icon>
                                        </span>
                                    <div class="photo-item-thumb_name mb-0">
                                        <p class="mb-0 photo-item-thumb_name-text">{{img?.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="my-5">
                    <hr>
                </div>

                <div class="page-title mt-4">Padronização</div>
                <p class="text-center py-5" *ngIf="standartDetails.item.length < 2">Nenhum item registrado</p>
                <div *ngFor="let x of standartDetails.item; let idx = index; let od = odd;" class="mb-4 p-3"
                     [ngClass]="{ odd: od}">
                    <ng-container *ngIf="x.type !== 'front-wall'">
                        <div class="d-flex justify-content-between align-items-center">
                            <h2 class="page-title mb-2">
                                {{getItemNameByType(x.type)}}  <small>#{{x?.id.substring(0, 4)}}</small>
                                <!--<br/><small>Placa XXXXXX</small>-->
                            </h2>
                            <a href="javascript:void('');" (click)="viewServices(x)" class="btn btn-primary">Serviços</a>
                        </div>

                        <div class="row">
                            <div class="col-6 col-md-3 col-lg-6 col-xxl-3" *ngFor="let img of x?.images">
                                <div class="position-relative">
                                    <div *ngIf="isNullOrUndefined(img.url)" class="addphoto is-cursor">
                                        <svg-icon src="assets/svg/addimage.svg"></svg-icon>
                                        <strong class="text-primary">{{img.name}}</strong>
                                    </div>
                                    <img *ngIf="!isNullOrUndefined(img.url)"
                                         class="addphoto is-cursor" src="{{img.url}}" alt="{{img.name}}">
                                    <span *ngIf="!isNullOrUndefined(img.url)" style="position:absolute; right: 0; top: -24px;">
                                        <svg-icon src="assets/svg/close.svg" class="svg-danger"
                                                  *ngIf="img.status === 'review'"></svg-icon>
                                        <svg-icon src="assets/svg/check.svg" class="svg-primary"
                                                  *ngIf="img.status === 'approved'"></svg-icon>
                                    </span>
                                    <div class="photo-item-thumb_name mb-0">
                                        <p class="mb-0 photo-item-thumb_name-text">{{img?.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="comment my-5">
                    <div class="comment_header">
                        <svg-icon src="assets/svg/logo2.svg"></svg-icon>
                        <p class="comment_title">Comentários</p>
                        <svg-icon class="comment_close d-none d-lg-block" src="assets/svg/arrow-down.svg"></svg-icon>
                    </div>
                    <ng-container *ngIf="isNullOrUndefined(this.standartMessage)">
                        <div class="d-flex px-2 comment_default" style="gap: 23px">
                            <svg-icon src="assets/svg/comment.svg"></svg-icon>
                            <p class="comment_description">
                                Olá, aqui o auditor informará se
                                há ajustes para serem realizados em sua <span>adesão ou
                                padronização</span>, fique atento.
                            </p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isNullOrUndefined(standartMessage)">
                        <div class="comment_scroll">
                            <ng-container *ngFor="let m of standartMessage, let i = index">
                                <ng-container>
                                    <div class="comment_card mb-3">
                                        <div style="padding: 1rem 1.5rem 0.5rem;">
                                            <p class="comment_card_date mb-0 text-right">{{m.createdAt |date:'dd/MM/yyyy'}}</p>
                                            <p class="comment_card_subtitle mb-2">{{m.title}}</p>
                                            <p class="comment_card_description">{{m.message}}</p>
                                            <ng-container *ngIf="isNullOrUndefined(m.reply)">
                                                <div style="background-color: #d6d6d6;width: 100%;height: 1px;margin: 0.7rem 0;">
                                                </div>
                                            </ng-container>

                                        </div>
                                        <div *ngIf="!isNullOrUndefined(m.reply)"
                                             class="bg-primary rounded-bottom px-4 py-2">
                                            <p class="response_title">Resposta</p>
                                            <p class="response_text">{{m.reply}}</p>
                                        </div>
                                    </div>
                                </ng-container>

                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalResaleProject" data-keyboard="false" data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <a href="javascript:void('');" class="modal_close ml-auto" aria-label="Close" type="button"
                   (click)="closeModal('modalResaleProject')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small pt-1" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <h1 class="page-title mb-2">Projeto</h1>
                                    <p>Envie no campo abaixo um arquivo com a proposta para realização do projeto, que
                                        deverá ser aprovada pela revenda em até 5 dias.</p>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="autorization-card">
                                        <img src="assets/images/addimage.png" alt="">
                                        <span class="is-cursor">
                                            <span class="text-primary">Adicionar Arquivo</span>
                                            <input accept="image/vnd.sealedmedia.softseal.jpg,
                                            application/pdf,image/png,image/jpeg" type="file"
                                                   (change)="this.uploadDocument($event)" id="myfileProject"
                                                   class="d-none">
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <!--<div class="input-material">
                                <input id="nameFilter" class="form-control" type="text" required />
                                <textarea name="iptProjectDescription" id="iptProjectDescription" class="form-control" rows="2"></textarea>
                                <label for="iptProjectDescription">
                                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                                    <span>Observação</span>
                                </label>
                            </div> -->
                        </div>
                        <!--<div class="col-12 my-3">
                            <button class="btn btn-primary btn-lg text-white w-100 mt-3">
                                Salvar
                            </button>
                        </div>-->
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalViewerVehicleChecklist" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="row">

                <div class="col-12">
                    <div class="p-4">
                        <div style="gap: 10px"
                             class="d-flex justify-content-between align-items-lg-center">
                            <span></span>
                            <div class="d-flex align-items-center justify-content-between" style="gap: 20px">
                                <a data-dismiss="modal" class="modal_close ml-auto" aria-label="Close">
                                    <svg-icon class="svg-close" src="assets/svg/close-circle.svg"></svg-icon>
                                </a>
                            </div>
                        </div>

                        <div class="tab-custom-primary mt-4">
                            <a href="javascript:void('');" class="tab-custom-primary-item"
                               [ngClass]="{'active' : modalVehicleTab === 0}"
                               (click)="modalVehicleTab = 0">Informações do Veículo</a>
                            <a href="javascript:void('');" class="tab-custom-primary-item"
                               [ngClass]="{'active' : modalVehicleTab === 1}"
                               (click)="modalVehicleTab = 1">Serviços</a>
                        </div>

                        <div *ngIf="modalVehicleTab === 0">
                            <p class="mt-4 mb-2"><strong>Informações do Veículo</strong></p>
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <select [ngModel]="itemService.type"
                                                name="modalVehicleType"
                                                [disabled]="true"
                                                id="modalVehicleType" class="form-control">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option *ngFor="let x of itemsFleet" [value]="x.type">{{x.name}}</option>
                                        </select>
                                        <label for="modalVehicleType" class="disabled">Tipo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input [(ngModel)]="itemService.brand" name="modalVehicleBrand"
                                               [disabled]="true"
                                               id="modalVehicleBrand" class="form-control"
                                               type="text" required maxlength="150"/>
                                        <label class="disabled"
                                               for="modalVehicleBrand">Marca</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input [(ngModel)]="itemService.model" name="modalVehicleModel"
                                               [disabled]="true"
                                               id="modalVehicleModel" class="form-control"
                                               type="text" required maxlength="150"/>
                                        <label class="disabled"
                                               for="modalVehicleModel">Modelo</label>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input name="modalVehicleModelYear" id="modalVehicleModelYear"
                                               [disabled]="true"
                                               class="form-control" [(ngModel)]="itemService.modelYear"
                                               type="text" required maxlength="4"/>
                                        <label class="disabled"
                                               for="modalVehicleModelYear">Ano do Modelo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input name="modalVehiclePlate" id="modalVehiclePlate" class="form-control"
                                               [disabled]="true"
                                               type="text" required maxlength="150" [(ngModel)]="itemService.plate"/>
                                        <label class="disabled"
                                               for="modalVehiclePlate">Placa do Veículo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <select name="modalVehicleBrandOld" id="modalVehicleBrandOld"
                                                [disabled]="true"
                                                [(ngModel)]="itemService.isOldBrand" class="form-control">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option [ngValue]="true">Sim</option>
                                            <option [ngValue]="false">Não</option>
                                        </select>
                                        <label class="disabled"
                                               for="modalVehicleBrandOld">Marca Antiga</label>
                                    </div>
                                </div>

                                <div class="col-12 mb-4">
                                    <hr/>
                                </div>

                                <div class="col-12">
                                    <div class="input-material">
                                        <select name="modalVehicleBrandTop" id="modalVehicleBrandTop"
                                                [disabled]="true"
                                                class="form-control" [(ngModel)]="itemService.isPlateTop">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option [ngValue]="false">Não</option>
                                            <option [ngValue]="true">Sim</option>
                                        </select>
                                        <label class="disabled"
                                               for="modalVehicleBrandTop">Placa no Topo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4" *ngIf="itemService.isPlateTop">
                                    <div class="input-material">
                                        <input name="modalVehicleBrandTopDimension" id="modalVehicleBrandTopDimension"
                                               [disabled]="true"
                                               class="form-control" inputmode="numeric"
                                               [(ngModel)]="itemService.plateMeasurements"
                                               type="number" required maxlength="150"/>
                                        <label class="disabled"
                                               for="modalVehicleBrandTopDimension">Medidas das Placas</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4" *ngIf="itemService.isPlateTop">
                                    <div class="input-material">
                                        <input name="modalVehicleBrandTopQuantity" id="modalVehicleBrandTopQuantity"
                                               [disabled]="true"
                                               class="form-control" inputmode="numeric"
                                               [(ngModel)]="itemService.numberPlates"
                                               type="number" required maxlength="150"/>
                                        <label class="disabled"
                                               for="modalVehicleBrandTopQuantity">Quantidade de Placas</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4" *ngIf="itemService.isPlateTop">
                                    <div class="input-material">
                                        <select name="modalVehicleBrandTopFrontBack" id="modalVehicleBrandTopFrontBack"
                                                [disabled]="true"
                                                [(ngModel)]="itemService.isFrontBack" class="form-control">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option [ngValue]="false">Não</option>
                                            <option [ngValue]="true">Sim</option>
                                        </select>
                                        <label class="disabled"
                                               for="modalVehicleBrandTopFrontBack">Frente e Verso</label>
                                    </div>
                                </div>
                            </div>

                            <div class="my-4">
                                <a (click)="modalVehicleTab = 1" class="btn btn-primary btn-block"
                                   href="javascript:void(0);">Próximo</a>
                            </div>
                        </div>

                        <div *ngIf="modalVehicleTab === 1">

                            <p class="mt-4 mb-2"><strong>Serviços</strong></p>

                            <div class="row">
                                <div class="col-12" *ngFor="let s of supplier">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" [(ngModel)]="s.seleted"
                                               [disabled]="true"
                                               id="{{s.id}}" name="check_list_1">
                                        <label class="disabled"
                                               class="custom-control-label" for="{{s.id}}">{{s.description}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="my-2">
                                <hr />
                            </div>

                            <p class="mt-4 mb-2"><strong>Checklist</strong></p>

                            <a href="{{itemService.documents[0].url}}" *ngIf="!isNullOrUndefined(itemService.documents)"
                               target="_blank" class="is-cursor d-block mt-3 mx-auto text-center">
                                <svg-icon class="svg-primary" src="assets/svg/file-double.svg"></svg-icon>

                                <p class="mt-2" style="line-height: 1">
                                    Baixar Checklist</p>

                            </a>


                            <div class="mt-4">
                                <div class="input-material">
                                    <textarea [(ngModel)]="itemService.observation" id="check_list_obs"
                                              [disabled]="true"
                                              style="resize: none;"></textarea>
                                    <label class="disabled" for="check_list_obs">Observações:</label>
                                </div>
                            </div>

                            <div class="row my-4">
                                <div class="col-6">
                                    <a (click)="modalVehicleTab = 0" class="btn btn-outline-primary btn-block"
                                       href="javascript:void(0);">Voltar</a>
                                </div>
                                <div class="col-6">
                                    <a (click)="closeModal('modalViewerVehicleChecklist')" class="btn btn-primary btn-block"
                                       href="javascript:void(0);">Fechar</a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
