<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Usuários</h1>
            <a class="btn btn-gray-fill" (click)="showModal('modalUser')">
                <svg-icon class="svg-primary right mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                Adicionar Usuário
            </a>
        </div>
        <form autocomplete="off" class="page-filter">
            <div class="input-material">
                <input [(ngModel)]="filter.name" id="name" class="form-control" type="text" name="name"
                       required/>
                <label for="name">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>Nome</span>
                </label>
            </div>
            <div class="input-material">
                <select name="profile" id="profileFilter"  [(ngModel)]="filter.profile">
                    <option [ngValue]="undefined">Todos</option>
                    <option [ngValue]="'admin'">Administrador</option>
                    <option [ngValue]="'user'">Usuário</option>
                </select>
                <label for="profileFilter" class="mb-0 mr-3">Perfil</label>
            </div>
            <div class="input-material">
                <input [(ngModel)]="filter.email" id="filterMail" class="form-control" type="text" name="e-mail"
                       required/>
                <label for="filterMail">
                    <span>E-mail</span>
                </label>
            </div>
            <a (click)="actionSearch()" class="btn btn-primary btn-lg text-white">Buscar</a>
        </form>

        <div class="table-responsive">
            <table class="table table-striped table_regular">
                <thead>
                <tr>
                    <th>Perfil</th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Criado em</th>
                    <th class="w-10"></th>
                </tr>
                </thead>
                <tbody>

                <tr *ngIf="userAdminPaginator?.userSupplier?.length === 0">
                    <td colspan="5" class="py-5">
                        <p class="py-5 mt-5 text-center">Nenhum registro encontrado.</p>
                    </td>
                </tr>
                <tr class="is-cursor" *ngFor="let u of userAdminPaginator.userSupplier |
                    paginate: {itemsPerPage: userAdminPaginator.PageSize,
                    currentPage: userAdminPaginator.currentPage,
                    totalItems: userAdminPaginator.totalCount }">
                    <td>{{getTranslate(u.profile)}}</td>
                    <td>{{u.name}}</td>
                    <td>{{u.email}}</td>
                    <td>{{u.createdAt | date: 'dd/MM/yyyy'}}</td>
                    <td class="no-wrap">
                        <a href="javascript:void('');" class="px-1" (click)="onUpdate(u)">
                            <svg-icon class="mr-2 svg-primary" src="assets/svg/edit.svg"></svg-icon>
                        </a>
                        <a href="javascript:void('');" (click)="actionRemoveUser(u.id)" class="px-1">
                            <svg-icon class="svg-danger" src="assets/svg/trash.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalUser" data-keyboard="false"
      data-backdrop="static" role="dialog"
      aria-labelledby="modalNoteResumeLabel"
      aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content ">
            <div class="modal_header d-flex justify-content-between align-items-center p-3">
                <h3 class="header-title">
                    <b>
                        Usuário
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" (click)="closeModal('modalUser')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <form autocomplete="off" class="row my-3">
                        <!--NAME-->
                        <div class="col-12 col-sm-12">
                            <div class="input-material">
                                <input [(ngModel)]="model.name" name="name" id="nameUser" class="form-control"
                                       type="text"
                                       required maxlength="150"/>
                                <label for="nameUser">Nome Completo</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="input-material flex-grow-1">
                                <input id="emailUser" [(ngModel)]="model.email" name="email" class="form-control"
                                       type="text"
                                       required maxlength="150"/>
                                <label for="emailUser" class="mb-0 mr-3">E-mail</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="input-material flex-grow-1">
                                <!--<input id="profileUser" [(ngModel)]="model.profile" name="profile" class="form-control"
                                       type="text"
                                       required maxlength="150"/>-->
                                <select name="profile" id="profileUser" [(ngModel)]="model.profile">
                                    <option [ngValue]="'admin'">Administrador</option>
                                    <option [ngValue]="'user'">Usuário</option>
                                </select>
                                <label for="profileUser" class="mb-0 mr-3">Perfil</label>
                            </div>
                        </div>

                        <div *ngIf="isNullOrUndefined(model.id)" class="col-12 col-sm-6">
                            <div class="input-material">
                                <input [(ngModel)]="password" name="password" id="passwordUser" class="form-control"
                                       type="password"
                                       required maxlength="150"/>
                                <label for="passwordUser">Senha</label>
                            </div>
                        </div>
                        <div *ngIf="isNullOrUndefined(model.id)" class="col-12 col-sm-6">
                            <div class="input-material flex-grow-1">
                                <input [(ngModel)]="confirmPassword" name="confirmPassword" id="confirmPasswordUser"
                                       class="form-control" type="password" required maxlength="150"/>
                                <label for="confirmPasswordUser" class="mb-0 mr-3">Confirmar Senha</label>
                            </div>
                        </div>

                        <div class="col-12 my-3">
                            <button (click)="actionSave()" class="btn btn-primary btn-lg text-white w-100">
                                <span class="text-white m-0">Confirmar</span></button>
                        </div>
                    </form>
                </div>

            </div>

        </div>
    </div>
</div>
