import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../base/base.component';
import {Banner, GetBannersGQL, GetBannersQuery, RemoveBannerGQL} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';

@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.scss']
})
export class BannersComponent extends BaseComponent implements AfterViewInit {

    banners: Array<Banner>;
    bannerQuery: QueryRef<GetBannersQuery>;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public removeBannerGQL: RemoveBannerGQL,
                public getBannersGQL: GetBannersGQL) {
        super(router, loading, translate);
    }

    ngAfterViewInit(): void {
        this.bannerQuery = this.getBannersGQL.watch();
        this.bannerQuery.valueChanges.subscribe(({data}) => {
            this.banners = data.banners as Array<Banner>;
        });
    }

    actionRemoveBanner(id: string) {
        super.confirmMessage('Atenção', 'Deseja realmente remover este banner?', () => {
            this.removeBannerGQL.mutate({input: id}).subscribe(result => {
                this.bannerQuery.refetch();
                super.showMessage('Sucesso', 'Banner removido com sucesso');
            });
        });
    }

}
