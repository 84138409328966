import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import {
    AuthInputAdmin,
    AuthInputUserSupplier,
    AuthType,
    AuthTypeAdmin, AuthTypeUserSupplier, DefaultMessage, ForgotPasswordUserSupplierGQL,
    LoginUserSupplierGQL
} from "../../../generated/graphql";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
    model: AuthInputUserSupplier = new AuthInputUserSupplier();

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public service: UserService,
                private loginUserSupplierGQL: LoginUserSupplierGQL,
                private forgotPasswordUserSupplier: ForgotPasswordUserSupplierGQL,
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        localStorage.clear();
    }

    actionLogin(): void {
        const arrValidateFields = [
            {value: this.model.email, text: 'E-mail <br />'},
            {value: this.model.password, text: 'Senha <br />'}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }
        if (!this.isEMailValid(this.model.email)) {
            return;
        }
        this.loginUserSupplierGQL.mutate({input: this.model}).subscribe(
            ({data}) => {
                console.log('onLogin');
                this.saveLocalUser(data.loginUserSupplier as AuthTypeUserSupplier);
            }, (err) => super.onError(err)
        );
    }

    actionForgot(): void {
        const arrValidateFields = [
            {value: this.model.email, text: 'E-mail <br />'},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `E-mail obrigatório`, 'warning');
            return;
        }
        if (!this.isEMailValid(this.model.email)) {
            return;
        }
        this.forgotPasswordUserSupplier.mutate({email: this.model.email}).subscribe(
            ({data}) => {
                const d = data.forgotPasswordUserSupplier as DefaultMessage;
                this.closeModal('modalForgot');
                this.showMessage('Sucesso', `E-mail de recuperação enviado para ${d.message}`, 'success');
            }, (err) => super.onError(err)
        );
    }

    saveLocalUser(_data: AuthTypeUserSupplier) {
        const data = _data as AuthTypeUserSupplier;
        this.setToken(data.token);
        this.service.updateUser(data.user);
        this.service.getStatusAuthenticated();
        let paramUrl = '/';
        if (data.user.type === 'projects') {
            paramUrl = data.user.profile === 'admin' ? '/managementProject' : '/resellersProject';
        } else if (data.user.type === 'fleet') {
            paramUrl = data.user.profile === 'admin' ? '/managementFleet' : '/resellersFleet';
        } else {
            paramUrl = data.user.profile === 'admin' ? '/management' : '/resellers';
        }
        this.router.navigate([paramUrl]).then();
    }
}
