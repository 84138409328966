import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    CreateUserAdminInput, CreateUserSupplierGQL, CreateUserSupplierInput, RemoveUserSupplierGQL,
    SearchUserSupplierGQL, SearchUserSupplierInput,
    SearchUserSupplierQuery,
    UpdateUserAdminInput, UpdateUserSupplierGQL, UpdateUserSupplierInput,
    UserAdmin, UserSupplierPage,
} from '../../../generated/graphql';

import {QueryRef} from 'apollo-angular';
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    user: UserAdmin;

    filter: SearchUserSupplierInput = new SearchUserSupplierInput();

    model: UserAdmin = new UserAdmin();

    password: string;
    confirmPassword: string;

    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    userAdminQuery: QueryRef<SearchUserSupplierQuery>;
    userAdminPaginator: UserSupplierPage = new UserSupplierPage();

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService,
                public searchUserSupplierGQL: SearchUserSupplierGQL,
                public createUserSupplierGQL: CreateUserSupplierGQL,
                public updateUserSupplierGQL: UpdateUserSupplierGQL,
                public removeUserSupplierGQL: RemoveUserSupplierGQL) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as UserAdmin;
                if (this.user.profile !== 'admin') {
                    this.router.navigate(['/resales']);
                }
            }, error: err => super.onError(err)
        });
    }

    ngAfterViewInit(): void {
        this.userAdminQuery = this.searchUserSupplierGQL.watch({input: this.filter});
        this.userAdminQuery.valueChanges.subscribe(({data}) => {
            this.userAdminPaginator = data.searchUserSupplier as UserSupplierPage;
        });
    }

    actionSearch() {
        this.userAdminQuery.refetch();
    }

    pageChanged(event) {
        this.filter.page = event;
        this.actionSearch();
    }

    ngOnDestroy(): void {
        const elModalUser = $('#modalUser');
        if (elModalUser[0]) {
            elModalUser.remove();
        }
    }

    actionRemoveUser(id: string) {
        super.confirmMessage('Atenção', 'Deseja realmente remover este usuário?', () => {
            this.removeUserSupplierGQL.mutate({input: id}).subscribe(result => {
                this.userAdminQuery.refetch();
                super.showMessage('Sucesso', 'usuário removido com sucesso');
            });
        });
    }

    actionSave() {
        let arrValidateFields = [];
        if (this.isNullOrUndefined(this.model.id)) {
            if (this.password !== this.confirmPassword) {
                super.showMessage('Atenção', 'Senhas não conferem');
                return;
            }
            arrValidateFields = [
                {value: this.model.name, text: 'Informe o nome do usuário <br>'},
                {value: this.model.email, text: 'Informe o e-mail do usuário <br>'},
                {value: this.model.name, text: 'Informe o perfil <br>'},
                {value: this.password, text: 'Informe a senha <br>'},
            ];
        } else {
            arrValidateFields = [
                {value: this.model.name, text: 'Informe o nome do usuário <br>'},
                {value: this.model.email, text: 'Informe o e-mail do usuário <br>'},
                {value: this.model.name, text: 'Informe o perfil <br>'},
            ];
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Verifique os seguintes campos: <br> ${stringError}`);
            return;
        }

        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        const data: CreateUserSupplierInput = {
            name: this.model.name,
            email: this.model.email,
            password: this.password,
            profile: this.model.profile
        };

        this.createUserSupplierGQL.mutate({input: data}).subscribe((result) => {
            super.closeModal('modalUser');
            super.showMessage('Sucesso', 'Usuário cadastrado com sucesso');
            this.model = new UserAdmin();
            this.userAdminQuery.refetch({input: this.filter});
        });
    }

    actionUpdate() {
        const data: UpdateUserSupplierInput = {
            id: this.model.id,
            name: this.model.name,
            email: this.model.email,
            profile: this.model.profile
        };

        this.updateUserSupplierGQL.mutate({input: data}).subscribe((result) => {
            super.closeModal('modalUser');
            super.showMessage('Sucesso', 'Usuário alterado com sucesso');
            this.model = new UserAdmin();
            this.userAdminQuery.refetch({input: this.filter});
        });
    }

    onUpdate(u) {
        Object.assign(this.model, u);
        this.showModal('modalUser');
    }

}
