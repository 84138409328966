<div class="page">
    <div class="page-header-custom-bg pb-5">
        <div class="d-flex align-items-center mb-3">
            <svg-icon [routerLink]="['/resellersFleet/detail/' + this.paramId]" class="svgBack is-cursor" src="assets/svg/back.svg"></svg-icon>
            <h1 class="page-title text-white m-0 p-0">Revenda</h1>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <strong class="text-white">{{standartDetails.companyName}}</strong><br/>
                <span class="text-white">Tel: <strong>{{isNullOrUndefined(standartDetails.phone) ? 'Não informado' : standartDetails.phone}}</strong></span>
                <div>
                    CNPJ: <strong>{{standartDetails.cnpj}}</strong><br/>
                    <strong></strong><br>
                    <strong></strong>
                </div>
            </div>
            <div class="col-12 col-md-6 text-md-right">
                <div class="d-flex justify-content-md-end">
                    <svg-icon class="mr-3" src="assets/svg/local.svg"></svg-icon>
                    <span>
                        <strong class="text-primary">{{standartDetails.uf}} - {{standartDetails.city}} | </strong>
                        <strong class="text-primary">{{standartDetails.cep}} | {{standartDetails.district}}</strong><br>
                        <strong class="text-primary">{{standartDetails.address}}</strong>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="page-padding">

                <div *ngIf="step == 1">
                    <div>
                        <a href="javascript:void(0);" (click)="addVehicle()" class="btn btn-primary">
                            Adicionar Veículo Extra
                        </a>
                    </div>

                    <div class="mb-2 mt-4">
                        <hr>
                    </div>

                    <div class="custom-control custom-switch mb-3" *ngIf="false">
                        <input [(ngModel)]="transmitDecline"
                               [disabled]="standartDetails.fleetStatus === 'received' || standartDetails.fleetStatus === 'approved'"
                               type="checkbox" class="custom-control-input" id="refusal" name="check_b">
                        <label class="custom-control-label" for="refusal">Revenda recusou a padronização.</label>
                    </div>

                    <div *ngIf="transmitDecline" class="input-material" style="padding-top: 26px;">
                        <textarea
                                [disabled]="standartDetails.fleetStatus === 'received' || standartDetails.fleetStatus === 'approved'"
                                id="justifycate" [(ngModel)]="standartDetails.standardizationJustification"
                                style="resize: none;"></textarea>
                        <label for="justifycate">Justificativa</label>
                    </div>

                    <div *ngFor="let x of standartDetails.item; let idx = index; let od = odd;" class="mb-4 p-3"
                         [ngClass]="{ odd: od}">
                        <ng-container *ngIf="x.type !== 'front-wall'">
                            <div class="d-flex justify-content-between align-items-center">
                                <h2 class="page-title mb-2">
                                    {{getItemNameByType(x.type)}} <small>#{{x?.id.substring(0, 4)}}</small>
                                    <!--<br/><small>Placa {{x.plate}}</small>-->
                                </h2>
                                <div>
                                    <a href="javascript:void('');" (click)="onDeleteItem(x)" class="btn btn-danger mr-3">Remover</a>
                                    <!-- <a href="javascript:void('');" (click)="addService(x)"
                                       class="btn btn-primary">Serviços</a> -->
                                </div>
                            </div>

                            <!-- <p class="mb-1"><strong>Adesão</strong></p> -->
                            <!--<p *ngIf="x.type !== 'front-wall'">Adicione as fotos seguindo as
                                referencias fornecidas pela equipe Copa Energia</p>-->
                            <!-- <div class="row">
                                <div class="col-6 col-md-3 col-lg-6 col-xxl-3"
                                     *ngFor="let img of standartDetails.itemAdhesion[idx]?.images">
                                    <div class="position-relative">
                                        <div *ngIf="isNullOrUndefined(img.url)" class="addphoto is-cursor"
                                             (click)="actionUploadImage(img, 'adhesion')">
                                            <svg-icon src="assets/svg/addimage.svg"></svg-icon>
                                            <strong class="text-primary">{{img.name}}</strong>
                                        </div>
                                        <img (click)="actionUploadImage(img, 'adhesion')" *ngIf="!isNullOrUndefined(img.url)"
                                             class="addphoto is-cursor" src="{{img.url}}" alt="{{img.name}}">
                                        <span *ngIf="!isNullOrUndefined(img.url)"
                                              style="position:absolute; right: 0; top: -24px;">
                                            <svg-icon src="assets/svg/close.svg" class="svg-danger"
                                                      *ngIf="img.status === 'review'"></svg-icon>
                                            <svg-icon src="assets/svg/check.svg" class="svg-primary"
                                                      *ngIf="img.status === 'approved'"></svg-icon>
                                        </span>
                                        <div class="photo-item-thumb_name mb-0">
                                            <p class="mb-0 photo-item-thumb_name-text">{{img?.name}}</p>
                                            <span class="d-flex align-items-center" *ngIf="isNullOrUndefined(img?.url)">
                                                <a class="photo-item-thumb_edit"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.statusAdhesion})"
                                                   (click)="actionUploadImage(img, 'adhesion')">
                                                    <svg-icon src="assets/svg/plus.svg" class="svg-white"></svg-icon>
                                                </a>
                                            </span>
                                            <span class="d-flex align-items-center"
                                                  *ngIf="!isNullOrUndefined(img?.url)">
                                                <a class="photo-item-thumb_edit"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.statusAdhesion})"
                                                   (click)="actionUploadImage(img, 'adhesion')">
                                                    <svg-icon src="assets/svg/edit.svg"></svg-icon>
                                                </a>
                                                <a class="photo-item-thumb_edit ml-3"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.statusAdhesion})"
                                                   (click)="actionRemovePhoto(img)">
                                                    <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                                </a>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div> -->

                            <div class="mb-2 mt-4"></div>

                            <p class="mb-1"><strong>Padronização</strong></p>
                            <div class="row">
                                <div class="col-6 col-md-3 col-lg-6 col-xxl-3" *ngFor="let img of x.images">
                                    <div class="position-relative">
                                        <div *ngIf="isNullOrUndefined(img.url)" class="addphoto is-cursor"
                                             (click)="actionUploadImage(img, 'standardization')">
                                            <svg-icon src="assets/svg/addimage.svg"></svg-icon>
                                            <strong class="text-primary">{{img.name}}</strong>
                                        </div>
                                        <img (click)="actionUploadImage(img, 'standardization')" *ngIf="!isNullOrUndefined(img.url)"
                                             class="addphoto is-cursor" src="{{img.url}}" alt="{{img.name}}">
                                        <!-- <span *ngIf="!isNullOrUndefined(img.url)"
                                              style="position:absolute; right: 0; top: -24px;">
                                            <svg-icon src="assets/svg/close.svg" class="svg-danger"
                                                      *ngIf="img.status === 'review'"></svg-icon>
                                            <svg-icon src="assets/svg/check.svg" class="svg-primary"
                                                      *ngIf="img.status === 'approved'"></svg-icon>
                                        </span> -->
                                        <div class="photo-item-thumb_name mb-0">
                                            <p class="mb-0 photo-item-thumb_name-text">{{img?.name}}</p>
                                            <span class="d-flex align-items-center" *ngIf="isNullOrUndefined(img?.url)">
                                                <!-- <a class="photo-item-thumb_edit"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.fleetStatus})"
                                                   (click)="actionUploadImage(img, 'standardization')"
                                                   >
                                                    <svg-icon src="assets/svg/plus.svg" class="svg-white"></svg-icon>
                                                </a> -->
                                                <a class="photo-item-thumb_edit"
                                                href="javascript:void(0)"
                                                (click)="actionUploadImage(img, 'standardization')"
                                                >
                                                 <svg-icon src="assets/svg/plus.svg" class="svg-white"></svg-icon>
                                             </a>
                                            </span>
                                            <span class="d-flex align-items-center"
                                                  *ngIf="!isNullOrUndefined(img?.url)">
                                                <!-- <a class="photo-item-thumb_edit"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.fleetStatus})"
                                                   (click)="actionUploadImage(img, 'standardization')">
                                                    <svg-icon src="assets/svg/edit.svg"></svg-icon>
                                                </a> -->
                                                <a class="photo-item-thumb_edit"
                                                   href="javascript:void(0)"
                                                   (click)="actionUploadImage(img, 'standardization')">
                                                    <svg-icon src="assets/svg/edit.svg"></svg-icon>
                                                </a>
                                                <!-- <a class="photo-item-thumb_edit ml-3"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.fleetStatus})"
                                                   (click)="actionRemovePhoto(img)">
                                                    <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                                </a> -->
                                                <a class="photo-item-thumb_edit ml-3"
                                                   href="javascript:void(0)"
                                                   (click)="actionRemovePhoto(img)">
                                                    <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <!--<a href="javascript:void('');" (click)="step = 2" class="btn btn-primary btn-next text-white">Próximo</a>-->
                </div>

                <div class="row">
                    <div class="col-12 col-md-4">
                        <a href="javascript:void(0)" [routerLink]="['/resellersFleet/detail/' + this.paramId]"
                           class="btn btn-outline-primary btn-next-outline">
                            <svg-icon src="assets/svg/return.svg"></svg-icon>
                        </a>
                    </div>
                    <!-- <div class="col-12 col-md-8">
                        <div class="btn-primary btn-next is-cursor" (click)="this.finish()">
                            Finalizar
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalFinish" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="row">

                <div class="col-12">
                    <div class="modal-resseler">
                        <div class="modal-resseler-top">
                            <a class="modal_close ml-auto is-cursor" aria-label="Close"
                               (click)="closeModal('modalFinish')">
                                <svg-icon [svgStyle]="{'fill':'#006F4D'}"
                                          src="assets/svg/closex.svg"></svg-icon>
                            </a>

                            <h3 class="text-white">
                                <strong>
                                    PADRONIZAÇÃO
                                    REALIZADA
                                    COM SUCESSO
                                </strong>

                            </h3>

                        </div>
                        <div class="modal-resseler-bottom">
                        <span class="text-white">
                               Todas as informaçõe foram enviadas para analise e conferencia do auditor Copa Energia.

                        </span>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<app-crop-image [cropId]="'modalCropResseler'"
                [cropAspectRatio]="4 / 4"
                [cropResizeToWidth]="1024"
                [cropResizeToHeight]="1024"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>

<app-crop-image [cropId]="'modalCropNewVehicle'"
                [cropAspectRatio]="4 / 4"
                [cropResizeToWidth]="1024"
                [cropResizeToHeight]="1024"
                (croppedImageResult)="setCroppedImageVeihcle($event)"></app-crop-image>


<div class="modal-custom modal fade" id="modalComments" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">

                <div class="col-12 col-lg-4">
                    <div class="modal_col-primary">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'white'}" class="svg-close mt-2"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logo.png" style="width: 279px;" alt="logo">
                        <h2 class="modal_title text-white mb-3">Mural Liquigás</h2>
                    </div>
                </div>

                <div class="col-12 col-lg-8">
                    <div class="p-4 pr-lg-5">
                        <div style="gap: 10px"
                             class="d-flex justify-content-between align-items-lg-center">
                            <div class="d-flex align-items-center" style="gap: 20px">
                                <svg-icon src="assets/svg/logo2.svg"></svg-icon>
                                <p class="modal_comment_title">{{modalMessage.createdBy?.name}}</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-between" style="gap: 20px">
                                <p class="modal_comment_date mb-0">{{modalMessage.createdAt|date:'dd / MM / yyyy'}}
                                    às {{modalMessage.createdAt|date:'HH:mm'}}</p>
                                <a data-dismiss="modal" class="modal_close ml-auto d-none d-lg-flex" aria-label="Close">
                                    <svg-icon class="svg-close" src="assets/svg/close-circle.svg"></svg-icon>
                                </a>
                            </div>
                        </div>
                        <p class="modal_comment_quest">{{getItemNameByType(modalMessage.type)}}</p>
                        <p class="modal_comment_descriptions">{{modalMessage.message}}</p>
                        <div class="input-material"
                             style="padding-top: 26px;">
                            <textarea id="field1" [(ngModel)]="textResponse" style="resize: none;"></textarea>
                            <label for="field1">Escreva aqui sua mensagem</label>
                        </div>
                        <div>
                            <a (click)="actionResponse()" class="btn btn-primary btn-block mb-4"
                               href="javascript:void(0);">Enviar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalAddVehicle" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <div class="modal_col-primary">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'white'}" class="svg-close mt-2"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logo.png" style="width: 160px;" alt="logo">
                        <h2 class="modal_title text-white mb-3">Cadastro de Veículo</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="p-4 pr-lg-5">
                        <div style="gap: 10px"
                             class="d-flex justify-content-between align-items-lg-center">
                            <div class="d-flex align-items-center" style="gap: 20px">
                                <svg-icon src="assets/svg/logo2.svg"></svg-icon>
                            </div>
                            <div class="d-flex align-items-center justify-content-between" style="gap: 20px">
                                <a data-dismiss="modal" class="modal_close ml-auto d-none d-lg-flex" aria-label="Close">
                                    <svg-icon class="svg-close" src="assets/svg/close-circle.svg"></svg-icon>
                                </a>
                            </div>
                        </div>
                        <!--<p class="modal_comment_descriptions">Informe os dados do veículo abaixo</p>-->

                        <div class="tab-custom-primary mt-4">
                            <a class="tab-custom-primary-item"
                               [ngClass]="{'active' : modalAddVehicleTab === 0}">Novo Veículo</a>
                            <a class="tab-custom-primary-item"
                               [ngClass]="{'active' : modalAddVehicleTab === 1}">Fotos</a>
                        </div>

                        <div *ngIf="modalAddVehicleTab === 0">
                            <div class="mt-4">
                                <div class="input-material">
                                    <select [(ngModel)]="newVehicle.type" name="addVehicleType" id="addVehicleType">
                                        <option [ngValue]="undefined">Selecione</option>
                                        <option *ngFor="let x of itemsFleet" [value]="x.type">{{x.name}}</option>
                                    </select>
                                    <label for="addVehicleType">Tipo do Veículo</label>
                                </div>
                            </div>
                            <div>
                                <div class="input-material">
                                    <input [(ngModel)]="newVehicle.plate" type="text" id="addVehiclePlate"
                                           maxlength="8" class="form-control"/>
                                    <label for="addVehiclePlate">Placa do Veículo</label>
                                </div>
                            </div>
                            <div>
                                <a (click)="this.stepTab()" class="btn btn-primary btn-block mb-4"
                                   href="javascript:void(0);">Próximo</a>
                            </div>
                        </div>

                        <div *ngIf="modalAddVehicleTab === 1">
                            <p class="my-2"><strong>Fotos</strong></p>
                            <div class="row mb-4">
                                <div class="col-6" *ngFor="let img of newVehicleImage;let i = index">
                                    <div class="position-relative">

                                        <div *ngIf="isNullOrUndefined(img.url)" class="addphoto is-cursor"
                                             (click)="actionVehicleUpload(i)">
                                            <svg-icon src="assets/svg/addimage.svg"></svg-icon>
                                            <strong class="text-primary">{{img.name}}</strong>
                                        </div>

                                        <img (click)="actionUploadImage(img, 'standardization')" *ngIf="!isNullOrUndefined(img.url)"
                                             class="addphoto is-cursor" src="{{img.url}}" alt="{{img.name}}">

                                        <div class="photo-item-thumb_name mb-0">
                                            <p class="mb-0 photo-item-thumb_name-text">{{img?.name}}</p>

                                            <span class="d-flex align-items-center" *ngIf="isNullOrUndefined(img?.url)">
                                                <a class="photo-item-thumb_edit"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.fleetStatus})">
                                                    <svg-icon src="assets/svg/plus.svg" class="svg-white"></svg-icon>
                                                </a>
                                            </span>

                                            <span class="d-flex align-items-center"
                                                  *ngIf="!isNullOrUndefined(img?.url)">

                                                <a class="photo-item-thumb_edit"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.fleetStatus})">
                                                    <svg-icon src="assets/svg/edit.svg"></svg-icon>
                                                </a>

                                                <a class="photo-item-thumb_edit ml-3"
                                                   href="javascript:void(0)"
                                                   *ngIf="getStatusEditView(img) && getStatusEditView({status: standartDetails.fleetStatus})">
                                                    <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                                </a>

                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <a (click)="modalAddVehicleTab = 0" class="btn btn-outline-primary btn-block mb-4"
                                       href="javascript:void(0);">Voltar</a>
                                </div>
                                <div class="col-6">
                                    <a (click)="actionNewVehicle()" class="btn btn-primary btn-block mb-4"
                                       href="javascript:void(0);">Salvar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalVehicleChecklist" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="row">

                <div class="col-12">
                    <div class="p-4">
                        <div style="gap: 10px"
                             class="d-flex justify-content-between align-items-lg-center">
                            <span></span>
                            <div class="d-flex align-items-center justify-content-between" style="gap: 20px">
                                <a data-dismiss="modal" class="modal_close ml-auto" aria-label="Close">
                                    <svg-icon class="svg-close" src="assets/svg/close-circle.svg"></svg-icon>
                                </a>
                            </div>
                        </div>

                        <div class="tab-custom-primary mt-4">
                            <a href="javascript:void('');" class="tab-custom-primary-item"
                               [ngClass]="{'active' : modalVehicleTab === 0}"
                               (click)="modalVehicleTab = 0">Informações do Veículo</a>
                            <a href="javascript:void('');" class="tab-custom-primary-item"
                               [ngClass]="{'active' : modalVehicleTab === 1}"
                               (click)="modalVehicleTab = 1">Serviços</a>
                        </div>

                        <div *ngIf="modalVehicleTab === 0">
                            <p class="mt-4 mb-2"><strong>Informações do Veículo</strong></p>
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <select [ngModel]="itemService.type"
                                                (ngModelChange)="this.handlerListService($event)"
                                                name="modalVehicleType"
                                                [disabled]="true"
                                                id="modalVehicleType" class="form-control">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option *ngFor="let x of itemsFleet" [value]="x.type">{{x.name}}</option>
                                        </select>
                                        <label for="modalVehicleType" class="disabled">Tipo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input [(ngModel)]="itemService.brand" name="modalVehicleBrand"
                                               id="modalVehicleBrand" class="form-control"
                                               type="text" required maxlength="150"/>
                                        <label [ngClass]="{'disabled' : (!getStatusEditView({status: standartDetails.fleetStatus})) }"
                                               for="modalVehicleBrand">Marca</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input [(ngModel)]="itemService.model" name="modalVehicleModel"
                                               id="modalVehicleModel" class="form-control"
                                               type="text" required maxlength="150"/>
                                        <label [ngClass]="{'disabled' : (!getStatusEditView({status: standartDetails.fleetStatus})) }"
                                               for="modalVehicleModel">Modelo</label>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input name="modalVehicleModelYear" id="modalVehicleModelYear"
                                               class="form-control" [(ngModel)]="itemService.modelYear"
                                               type="text" required maxlength="4"/>
                                        <label [ngClass]="{'disabled' : (!getStatusEditView({status: standartDetails.fleetStatus})) }"
                                               for="modalVehicleModelYear">Ano do Modelo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <input name="modalVehiclePlate" id="modalVehiclePlate" class="form-control"
                                               type="text" required maxlength="8" [(ngModel)]="itemService.plate"/>
                                        <label [ngClass]="{'disabled' : (!getStatusEditView({status: standartDetails.fleetStatus})) }"
                                               for="modalVehiclePlate">Placa do Veículo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-material">
                                        <select name="modalVehicleBrandOld" id="modalVehicleBrandOld"
                                                [(ngModel)]="itemService.isOldBrand" class="form-control">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option [ngValue]="true">Sim</option>
                                            <option [ngValue]="false">Não</option>
                                        </select>
                                        <label [ngClass]="{'disabled' : (!getStatusEditView({status: standartDetails.fleetStatus})) }"
                                               for="modalVehicleBrandOld">Marca Antiga</label>
                                    </div>
                                </div>

                                <div class="col-12 mb-4">
                                    <hr/>
                                </div>

                                <div class="col-12">
                                    <div class="input-material">
                                        <select name="modalVehicleBrandTop" id="modalVehicleBrandTop"
                                                class="form-control" [(ngModel)]="itemService.isPlateTop">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option [ngValue]="false">Não</option>
                                            <option [ngValue]="true">Sim</option>
                                        </select>
                                        <label [ngClass]="{'disabled' : (!getStatusEditView({status: standartDetails.fleetStatus})) }"
                                               for="modalVehicleBrandTop">Placa no Topo</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-5" *ngIf="itemService.isPlateTop">
                                    <div class="input-material">
                                        <input name="modalVehicleBrandTopDimension" id="modalVehicleBrandTopDimension"
                                               class="form-control" inputmode="numeric"
                                               [(ngModel)]="itemService.plateMeasurements"
                                               type="number" required maxlength="150"/>
                                        <label [ngClass]="{'disabled' : (!getStatusEditView({status: standartDetails.fleetStatus})) }"
                                               for="modalVehicleBrandTopDimension">Medidas das Placas <small>(em metros)</small></label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4" *ngIf="itemService.isPlateTop">
                                    <div class="input-material">
                                        <input name="modalVehicleBrandTopQuantity" id="modalVehicleBrandTopQuantity"
                                               class="form-control" inputmode="numeric"
                                               [(ngModel)]="itemService.numberPlates"
                                               type="number" required maxlength="150"/>
                                        <label [ngClass]="{'disabled' : (!getStatusEditView({status: standartDetails.fleetStatus})) }"
                                               for="modalVehicleBrandTopQuantity">Quantidade de Placas</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-3" *ngIf="itemService.isPlateTop">
                                    <div class="input-material">
                                        <select name="modalVehicleBrandTopFrontBack" id="modalVehicleBrandTopFrontBack"
                                                [(ngModel)]="itemService.isFrontBack" class="form-control">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option [ngValue]="false">Não</option>
                                            <option [ngValue]="true">Sim</option>
                                        </select>
                                        <label [ngClass]="{'disabled' : (!getStatusEditView({status: standartDetails.fleetStatus})) }"
                                               for="modalVehicleBrandTopFrontBack">Frente e Verso</label>
                                    </div>
                                </div>
                            </div>

                            <div class="my-4">
                                <a (click)="modalVehicleTab = 1" class="btn btn-primary btn-block"
                                   href="javascript:void(0);">Próximo</a>
                            </div>
                        </div>

                        <div *ngIf="modalVehicleTab === 1">

                            <p class="mt-4 mb-2"><strong>Serviços</strong></p>

                            <div class="row">
                                <div class="col-12" *ngFor="let s of supplier">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" [(ngModel)]="s.seleted"
                                               id="{{s.id}}" name="check_list_1">
                                        <label [ngClass]="{'disabled' : (!getStatusEditView({status: standartDetails.fleetStatus})) }"
                                               class="custom-control-label" for="{{s.id}}">{{s.description}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="my-2">
                                <hr />
                            </div>


                            <div class="row">
                                <div class="col-12">
                                    <div class="input-material">
                                        <input type="number" class="form-control" [(ngModel)]="itemService.amountLogo"
                                               inputmode="numeric" maxlength="3" id="check_list_amount_logo" />
                                        <label for="check_list_obs">Quantidade de aplicações de logo</label>
                                    </div>
                                </div>
                            </div>

                            <div class="my-2">
                                <hr />
                            </div>

                            <p class="mt-4 mb-2"><strong>Checklist</strong></p>

                            <div class="row">
                                <div class="col-12 col-md-6 text-center">
                                    <label
                                           class="is-cursor d-block mt-3 mx-auto text-center">
                                        <svg-icon class="svg-primary" src="assets/svg/file-double.svg"></svg-icon>
                                        <p class="mt-2" style="line-height: 1">
                                            {{!fileService ? 'Adicionar' : 'Trocar'}} Checklist Entrada</p>
                                        <input class="d-none" type="file" id="fileService" (change)="uploadService($event)">
                                    </label>
                                    <a href="{{documentsChecklist[0].url}}" *ngIf="!isNullOrUndefined(documentsChecklist)"
                                       target="_blank" class="btn btn-sm btn-outline-primary mt-3 mx-auto text-center">
                                        Download
                                    </a>
                                </div>
                                <div class="col-12 col-md-6 text-center">
                                    <label
                                           class="is-cursor d-block mt-3 mx-auto text-center">
                                        <svg-icon class="svg-primary" src="assets/svg/file-double.svg"></svg-icon>
                                        <p class="mt-2" style="line-height: 1">
                                            {{!fileServiceExit ? 'Adicionar' : 'Trocar'}} Checklist Saída</p>
                                        <input class="d-none" type="file" id="fileServiceExit" (change)="uploadServiceExit($event)">
                                    </label>
                                    <a href="{{documentsChecklistExit[0].url}}" *ngIf="!isNullOrUndefined(documentsChecklistExit)"
                                       target="_blank" class="btn btn-sm btn-outline-primary mt-3 mx-auto text-center">
                                        Download
                                    </a>
                                </div>
                            </div>

                            <div class="mt-4">
                                <div class="input-material">
                                    <textarea [(ngModel)]="itemService.observation" id="check_list_obs"
                                              style="resize: none;"></textarea>
                                    <label for="check_list_obs">Observações:</label>
                                </div>
                            </div>

                            <div class="row my-4">
                                <div class="col-6">
                                    <a (click)="modalVehicleTab = 0" class="btn btn-outline-primary btn-block"
                                       href="javascript:void(0);">Voltar</a>
                                </div>
                                <div class="col-6">
                                    <a (click)="onSaveService()" class="btn btn-primary btn-block"
                                       href="javascript:void(0);">Salvar</a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
