import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ExcelService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getExcelContact(model) {
        const url = `${this.BASE_URL}/contact/excel`;
        return this.http.post(url, model);
    }

    getExcelDashboard() {
        const url = `${this.BASE_URL}/dashboard/user-access/excel`;
        return this.http.get(url);
    }

    getExcelSupplier() {
        const url = `${this.BASE_URL}/standart/search-standart-supplier/excel`;
        return this.http.post(url, {});
    }
    getExcelService(userId: string) {
        const url = `${this.BASE_URL}/standart/standart-supplier-service/excel`;
        return this.http.post(url, {userId: userId});
    }

    getExcelSupplierFleet(userId: string) {
        const url = `${this.BASE_URL}/standart/search-standart-supplier-fleet/excel`;
        return this.http.post(url, {userId: userId});
    }

    getExcelSupplierProject() {
        const url = `${this.BASE_URL}/standart/search-standart-supplier-project/excel`;
        return this.http.post(url, {});
    }
}



