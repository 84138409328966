import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
    Banner, GetStandartItemServiceByIdGQL, GetStandartSupplierServiceGQL,
    RemoveDocumentGQL,
    ResaleUserSupplier,
    SearchDocumentsGQL, SearchDocumentsQuery, StandartItemFleetDto,
    StandartItemImage, StandartItemServiceDto, StandartItemServiceTypeInput, StandartItemsFleetGQL,
    StandartMessage,
    StandartMessagesGQL, StandartMessagesQuery, StandartMessagesQueryVariables,
    StandartStandardizationByIdUserSupplierGQL,
    User,
    UserDocument, UserStandartItem, UserSupplier,
    UserSupplierStandartDetails
} from "../../../../generated/graphql";
import {IAlbum, Lightbox} from "ngx-lightbox";
import {UserService} from "../../../service/user.service";
import {QueryRef} from "apollo-angular";
import {Supplier} from "../../resellers-fleet/model/supplier";

@Component({
    selector: 'app-management-detail',
    templateUrl: './management-detail.component.html',
    styleUrls: ['./management-detail.component.scss']
})
export class ManagementFleetDetailComponent extends BaseComponent implements OnInit, OnDestroy {
    standartDetails: UserSupplierStandartDetails = new UserSupplierStandartDetails();
    paramId: string;
    documents: UserDocument[] = [];
    documentsCheckList: UserDocument[] = [];
    documentsWarranty: UserDocument[] = [];

    searchDocumentsQuery: QueryRef<SearchDocumentsQuery>;
    searchDocumentsChecklistQuery: QueryRef<SearchDocumentsQuery>;
    searchDocumentsWarrantyQuery: QueryRef<SearchDocumentsQuery>;

    standartMessageQuery: QueryRef<StandartMessagesQuery, StandartMessagesQueryVariables>;
    standartMessage: StandartMessage[] = [];
    fileTerms: any[];
    modelUser: UserSupplier = new UserSupplier();

    itemsFleet: StandartItemFleetDto[] = [];
    modalAddVehicleTab = 0;
    modalVehicleTab = 0;

    itemService: StandartItemServiceDto = new StandartItemServiceDto();
    supplier: Supplier[] = [];

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public translate: TranslateService,
                public activatedRoute: ActivatedRoute,
                private standartMessagesGQL: StandartMessagesGQL,
                private searchDocumentsGQL: SearchDocumentsGQL,
                public removeDocumentGQL: RemoveDocumentGQL,
                public idUserSupplierGQL: StandartStandardizationByIdUserSupplierGQL,
                public standartItemsFleetGQL: StandartItemsFleetGQL,
                public getStandartItemServiceByIdGQL: GetStandartItemServiceByIdGQL,
                public getStandartSupplierServiceGQL: GetStandartSupplierServiceGQL,
                private lightbox: Lightbox,
    ) {
        super(router, loading, translate)
    }

    ngOnInit(): void {
        this.standartItemsFleetGQL.watch().valueChanges.subscribe(({data}) => {
            this.itemsFleet = data.standartItemsFleet as StandartItemFleetDto[];
        }, error => super.onError(error));

        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    if (data.profile !== 'admin' || data.type !== 'fleet') {
                        this.router.navigate(['/login']).then();
                    }
                    this.modelUser = data as UserSupplier;
                    if (data.profile !== 'admin') {
                        this.router.navigate(['/']).then();
                    }
                }
            }
        });
        this.activatedRoute.params.subscribe(url => {
            if (!this.isNullOrUndefined(url?.id)) {
                this.paramId = url.id;
                this.getStandart()
            }
        });
    }

    ngOnDestroy(): void {
        super.destroyModal('modalResaleProject');
    }

    private getStandart() {
        this.idUserSupplierGQL.watch({id: this.paramId}).valueChanges.subscribe(({data}) => {
            this.standartDetails = data.standartStandardizationByIdUserSupplier as UserSupplierStandartDetails;
            // this.documents = this.standartDetails.document?.filter(x => x.type === 'authorization');
            this.standartDetails.document = this.standartDetails.document?.filter(x => x.type === 'project');
            this.getMessages();
            this.searchDocument();
            this.searchDocumentChecklist();
        }, error => super.onError(error));
    }

    getMessages() {
        this.standartMessageQuery = this.standartMessagesGQL.watch({id: this.paramId});
        this.standartMessageQuery.valueChanges.subscribe(({data}) => {
            this.standartMessage = data.standartMessages as StandartMessage[];
            this.standartMessage = this.standartMessage.filter( x => x.type !== 'front-wall');
        });
    }

    open(p: StandartItemImage): void {
        const iAlbum: IAlbum = {
            caption: p.name,
            src: p.url,
            thumb: p.url,
        };
        this.lightbox.open([iAlbum], 0, {
            wrapAround: false,
            showImageNumberLabel: false,
            disableScrolling: true,
            showZoom: false,
            showRotate: false
        });
    }

    searchDocumentChecklist() {
        this.searchDocumentsChecklistQuery = this.searchDocumentsGQL.watch({
            id: this.standartDetails.user.id,
            type: 'checklist'
        });
        this.searchDocumentsChecklistQuery.valueChanges.subscribe(({data}) => {
            this.documentsCheckList = data.searchDocuments as UserDocument[];
        }, error => super.onError(error));
    }

    searchDocument() {
        this.searchDocumentsQuery = this.searchDocumentsGQL.watch({
            id: this.standartDetails.user.id,
            type: 'fleet-authorization'
        });
        this.searchDocumentsQuery.valueChanges.subscribe(({data}) => {
            this.documents = data.searchDocuments as UserDocument[];
        }, error => super.onError(error));

        this.searchDocumentsWarrantyQuery = this.searchDocumentsGQL.watch({
            id: this.standartDetails.user.id,
            type: 'certificate'
        });
        this.searchDocumentsWarrantyQuery.valueChanges.subscribe(({data}) => {
            this.documentsWarranty = data.searchDocuments as UserDocument[];
        }, error => super.onError(error));
    }

    uploadDocument(event: any) {
        this.fileTerms = event.target.files;
        if (this.documents.length >= 1) {
            this.documents.forEach((e) => {
                this.removeDocumentGQL.mutate({id: e.id}).subscribe();
            });
        }
        this.userService.uploadFile(
            this.fileTerms[0],
            'user-supplier-document',
            `${this.standartDetails.resaleId}/project/${this.modelUser.id}`)
            .subscribe(({data}) => {
                this.fileTerms = [];
                this.closeModal('modalResaleProject');
                this.showMessage('Sucesso!', 'Projeto enviado com sucesso!', 'success');
                this.getStandart();
            }, error => super.onError(error))
    }

    viewServices(model: UserStandartItem) {
        this.itemService = Object.assign({}, new StandartItemServiceDto());
        this.itemService.itemId = model.id;
        this.itemService.type = model.type;

        this.modalVehicleTab = 0;
        if (!this.isNullOrUndefined(model.service)) {
            this.getStandartItemServiceByIdGQL.watch({id: model.service.id}).valueChanges.subscribe(({data: data1}) => {
                this.itemService = data1.getStandartItemServiceById as StandartItemServiceDto;
                this.handlerListService(model.type);

                this.showModal('modalViewerVehicleChecklist');
            }, error => super.onError(error));
        } else {

            this.handlerListService(model.type);
            this.showModal('modalViewerVehicleChecklist');
        }

        
    }

    handlerListService(typeParam: string) {
        this.itemService.type = typeParam;
        this.supplier = [];
        this.getStandartSupplierServiceGQL.watch({
            type: typeParam,
            resaleId: this.standartDetails.resaleId
        }).valueChanges.subscribe(({data}) => {
            this.supplier = Object.assign([], data.getStandartSupplierService as Supplier[]);
            this.supplier.map((e) => {
                const index = this.itemService?.servicesTypes?.findIndex((type) => e.id === type.id);
                console.log(index);
                if (index >= 0) {
                    e.seleted = true;
                }
            });


        }, error => super.onError(error));
    }
}
