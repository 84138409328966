<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Gestão <span>| Revendas</span></h1>
            <a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
            <a href="javascript:void('');" class="btn btn-info text-white" (click)="getExcel()">
                <svg-icon class="svg-white right mr-2" src="assets/svg/excel.svg"></svg-icon>
                Exportar Excel
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="nameFilter" class="form-control" type="text" [(ngModel)]="filter.name"
                       required/>
                <label for="nameFilter">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>Nome</span>
                </label>
            </div>
            <div class="input-material">
                <input id="razaoFilter" class="form-control" type="text" required [(ngModel)]="filter.companyName" />
                <label for="razaoFilter">Razão Social</label>
            </div>
            <div class="input-material">
                <input id="cpfFilter" class="form-control" type="text" required [(ngModel)]="filter.cpf_cnpj" />
                <label for="cpfFilter">CPF/CNPJ</label>
            </div>
            <div class="input-material select">
                <select id="faixaFilter" class="form-control" required [(ngModel)]="filter.range">
                    <option [ngValue]="undefined" selected>Todos</option>
                    <option [ngValue]="'Faixa 1'">Faixa 1</option>
                    <option [ngValue]="'Faixa 2'">Faixa 2</option>
                    <option [ngValue]="'Faixa 3'">Faixa 3</option>
                    <option [ngValue]="'Faixa 4'">Faixa 4</option>
                    <option [ngValue]="'Faixa 5'">Faixa 5</option>
                </select>
                <label for="faixaFilter">Faixa</label>
            </div>
            <div class="input-material select">
                <select id="ativoFilter" class="form-control" required [(ngModel)]="filter.type">
                    <option [ngValue]="undefined" selected>Todos</option>
                    <option [ngValue]="'glp'">GLP</option>
                    <option [ngValue]="'gas'">GÁS</option>
                </select>
                <label for="ativoFilter">Tipo</label>
            </div>
            <div class="input-material">
                <input id="gveFilter" class="form-control" type="text" required [(ngModel)]="filter.gve"
                       [readOnly]="user.profile !== 'admin'"
                />
                <label [ngClass]="{'disabled': (user.profile !== 'admin')}" for="gveFilter">GVE</label>
            </div>
<!--            <div class="input-material">-->
<!--                <input id="codPaiFilter" class="form-control" type="text" required [(ngModel)]="filter.fatherCode"/>-->
<!--                <label for="codPaiFilter">Código Pai</label>-->
<!--            </div>-->
            <a class="btn btn-primary btn-lg text-white" (click)="filter.page=0;actionSearch()">Buscar</a>
        </div>

        <div class="table-responsive">
            <table class="table table-striped table_regular">
                <thead>
                <tr>
                    <th class="w-5">Resetar Senha</th>
                    <th>Tipo</th>
<!--                    <th>Código Pai</th>-->
                    <th>GME</th>
                    <th>GVE</th>
                    <th>Faixa</th>
                    <th>CNPJ</th>
                    <th>Razão Social</th>
                    <th>Nome</th>
                    <th>CPF</th>
                    <!--<th>Data Nascimento</th>-->
                    <th>E-mail</th>
                    <th>Telefone</th>
                    <th>Data da Alteração</th>
                    <th>Hora da Alteração</th>
                    <th>Ativo</th>
                    <th>Cadastro</th>
                    <th>Adessão</th>
                    <th>Padronização</th>
                    <th>Coupons</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="model?.users?.length === 0">
                    <td colspan="18" class="py-5">
                        <p class="py-5 mt-5 text-center">Nenhum registro encontrado.</p>
                    </td>
                </tr>
                <tr class="is-cursor" *ngFor="let a of model?.users |
                paginate: {itemsPerPage: model.PageSize,
                currentPage: model.currentPage,
                totalItems: model.totalCount }">
                    <td>
                        <a href="javascript:void('');" (click)="actionResetPassword(a?.user)">
                            <svg-icon class="mr-2 svg-primary" src="assets/svg/settings.svg"></svg-icon>
                        </a>
                    </td>
                    <td class="text-uppercase">{{a?.user?.type}}</td>
<!--                    <td>{{a?.user?.fatherCode}}</td>-->
                    <td>{{a?.user?.gme}}</td>
                    <td>{{a?.user?.gve}}</td>
                    <td>{{a?.user?.range}}</td>
                    <td>{{a?.user?.cnpj}}</td>
                    <td>{{a?.user?.companyName}}</td>
                    <td>{{a?.user?.name}}</td>
                    <td>{{a?.user?.cpf}}</td>
                    <!--<td>Data Nascimento</td>-->
                    <td>{{a?.user?.email}}</td>
                    <td>{{a?.user?.phone}}</td>
                    <td>{{a?.user?.updatedAt | date: 'dd/MM/yyyy'}}</td>
                    <td>{{a?.user?.updatedAt | date: 'HH:mm'}}</td>
                    <td>{{a?.user?.isActivated ? 'Sim' : 'Não'}}</td>
                    <td>{{a?.user?.isActivated ? 'Sim' : 'Não'}}</td>
                    <td class="text-capitalize">{{getTranslate(a?.adhesion)}}</td>
                    <td class="text-capitalize">{{getTranslate(a?.standardization)}}</td>
                    <td>{{a?.coupoms.toString()}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>

